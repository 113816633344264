import React, {Fragment} from 'react'
import RingierBannerSectionLogos from './logos'
import {RingierBannerLink, MoreSoon} from './RingierBannerStyles'
import {ELEMENTS_IDS} from 'const'
import theme from 'theme'
import {isMobile} from 'utils/browserUtils'
import {getAboutLink} from 'utils/languageUtils'

const RingierBannerLogosSection = ({t}) => (
  <Fragment>
    <RingierBannerSectionLogos logos={theme.otherBrandsLogos} />
    <MoreSoon>{t('ringier-banner.more-soon')}</MoreSoon>
    <RingierBannerLink
      className={ELEMENTS_IDS.logisterPage.moreInfo}
      target={isMobile() ? '_self' : '_blank'}
      to={getAboutLink()}
      external
      secondary
      inheritColor>
      {t('ringier-banner.more-info')}
    </RingierBannerLink>
  </Fragment>
)

export default RingierBannerLogosSection
