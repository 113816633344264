import React from 'react'

import {translate} from 'react-i18next'
import PageMessage from 'components/PageMessage'
import View from 'components/View'
import Title from 'components/Title'
import Link from 'components/Link'
import ErrorLinksTitle from './ErrorLinksTitle'
import ErrorText from './ErrorText'
import {getAgbLink, getDsbLink, getAboutLink, getImpressumLink, getSupportLink} from 'utils/languageUtils'

const Error = ({error, description, t}) => {
  return (
    <View>
      <Title>{t('errors.title')}</Title>
      <PageMessage error>
        <ErrorText error={error} description={description} />
      </PageMessage>
      <ErrorLinksTitle light>{t('errors.links-title')}</ErrorLinksTitle>
      <Link to={getSupportLink()} info external block>
        {t('errors.links.support')}
      </Link>
      <Link to={getAboutLink()} info external block>
        {t('errors.links.about')}
      </Link>
      <Link to={getImpressumLink()} info external block>
        {t('errors.links.impressum')}
      </Link>
      <Link to={getAgbLink()} info external block>
        {t('errors.links.tos')}
      </Link>
      <Link to={getDsbLink()} info external block>
        {t('errors.links.pp')}
      </Link>
    </View>
  )
}

export {Error as PureError}

export default translate()(Error)
