import {BIOMETRIC_TYPES} from 'const'
import detect from 'detect.js'
const IPHONE_11 = 'iphone11'
const IPHONE_11_PRO = 'iphone11pro'
const IPHONE_11_PRO_MAX = 'iphone11promax'
const IPHONE_X = 'iphonex'
const IPHONE_XR = 'iphonexr'
const IPHONE_SE_2 = 'iphonese2'
const IPHONE_6_7_8_PLUS = 'iphone678plus'
const IPHONE_6_7_8 = 'iphone678'
const IPHONE_SE_1_IPOD_7 = 'iphonese1ipod7'
const IPAD_11_1_2 = 'ipad11_1_2'
const IPAD_9_7 = 'ipad97'
const IPAD_AIR = 'ipadair'
const MACBOOK = 'MacBook'
const IPHONE = 'iPhone'
const IPAD = 'iPad'
const WINDOWS = 'Windows'

const [FACE_ID, TOUCH_ID, ANY] = BIOMETRIC_TYPES

// source: Xcode simulators
const SCREEN_SIZE_BY_DEVICE = {
  // FaceID
  [IPHONE_11]: {width: 414, height: 896},
  [IPHONE_11_PRO]: {width: 375, height: 812},
  [IPHONE_11_PRO_MAX]: {width: 414, height: 896},
  [IPHONE_X]: {width: 375, height: 812},
  [IPHONE_XR]: {width: 414, height: 896},
  [IPAD_11_1_2]: {width: 834, height: 1194},
  // TouchID
  [IPHONE_SE_2]: {width: 375, height: 667},
  [IPHONE_6_7_8_PLUS]: {width: 414, height: 736},
  [IPHONE_6_7_8]: {width: 375, height: 667},
  [IPHONE_SE_1_IPOD_7]: {width: 320, height: 568},
  [IPAD_9_7]: {width: 768, height: 1024},
  [IPAD_AIR]: {width: 834, height: 1112},
}

const FACE_ID_MODELS = [IPHONE_11, IPHONE_11_PRO, IPHONE_11_PRO_MAX, IPHONE_X, IPHONE_XR, IPAD_11_1_2]
const TOUCH_ID_MODELS = [IPHONE_SE_2, IPHONE_6_7_8, IPHONE_6_7_8_PLUS, IPHONE_SE_1_IPOD_7, IPAD_9_7, IPAD_AIR]

// eslint-disable-next-line no-restricted-globals
const {width, height} = screen
const targetDevice = Object.keys(SCREEN_SIZE_BY_DEVICE).find(
  (key) => SCREEN_SIZE_BY_DEVICE[key].width === width && SCREEN_SIZE_BY_DEVICE[key].height === height
)
const ua = detect.parse(navigator.userAgent)

export const isIphone = () => /iPhone/i.test(navigator.userAgent)
export const isIpad = () =>
  /iPad/i.test(navigator.userAgent) || (/Mac/.test(navigator.platform) && navigator.maxTouchPoints !== 0)
export const isMac = () => /Mac/.test(navigator.platform) && navigator.maxTouchPoints === 0
export const isWin = () => /Win/.test(navigator.platform)
export const isChrome = () => /Chrome/i.test(navigator.userAgent)
export const isSafari = () => /Safari/i.test(navigator.userAgent) && !isChrome()

export const isDeviceTypeAllowed = () => {
  const isProd = process.env.REACT_APP_ENV === 'prod'
  if (!isProd) return true // Allow everything on pre-prod environments for development purposes

  // Mac with Safari or Chrome
  if (isMac() && (isSafari() || isChrome())) {
    return true
  }
  // iPhone with Safari
  if (isIphone() && (isSafari() || (isChrome() && !isProd))) {
    return true
  }
  // iPad with Safari
  if (isIpad() && (isSafari() || (isChrome() && !isProd))) {
    return true
  }
  // isWin and any browser
  // TODO: uncomment before going to PROD
  /* if (isWin()) {
    return true
  } */

  // All other devices/browsers
  return false
}

const hasFaceId = () => (isIphone() || isIpad()) && FACE_ID_MODELS.includes(targetDevice)
const hasTouchId = () => (isIphone() || isIpad()) && TOUCH_ID_MODELS.includes(targetDevice)

export const getBiometricHardwareForAppleDevice = () => {
  if (isMac()) return TOUCH_ID
  if (hasFaceId()) return FACE_ID
  if (hasTouchId()) return TOUCH_ID
  return ANY
}

export const getDeviceName = () => {
  if (isMac()) return MACBOOK
  if (isIphone()) return IPHONE
  if (isIpad()) return IPAD
  if (isWin()) return WINDOWS
  return ua.os.name
}

export const getBrowserName = () => {
  // Bug in detect.js
  if (navigator.userAgent.indexOf('Edg') >= 0) {
    return 'Edge'
  }
  return ua.browser.family
}
