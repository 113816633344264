import React from 'react'
// TODO rewrite with react-sticky-el
import {StickyContainer, Sticky} from 'react-sticky'

import NotificationBar from './NotificationBar'

const showNotification = ({type, message}) => ({
  type,
  message,
  show: true,
})

const hideNotification = () => ({
  show: false,
})

const withNotificationBarState = (props) => (WrappedComponent) => {
  return class extends React.Component {
    state = {}

    handleOutsideClick = (e) => {
      // ignore clicks on the component itself
      const notificationBar = document.getElementById('notification-bar')
      if (notificationBar && notificationBar.contains(e.target)) {
        return
      }

      this.hideNotification()
    }

    showNotification = (type, message) => {
      document.addEventListener('click', this.handleOutsideClick, false)
      this.setState(() =>
        showNotification({
          type,
          message,
        })
      )
    }

    hideNotification = () => {
      document.removeEventListener('click', this.handleOutsideClick, false)
      this.setState(() => hideNotification())
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    render() {
      return (
        <StickyContainer>
          <Sticky topOffset={1}>
            {({style}) => (
              <NotificationBar
                {...props}
                style={style}
                id={'notification-bar'}
                notification={this.state}
                hideNotification={this.hideNotification}
              />
            )}
          </Sticky>
          <WrappedComponent showNotification={this.showNotification} {...this.props}>
            {this.props.children}
          </WrappedComponent>
        </StickyContainer>
      )
    }
  }
}

export {showNotification, hideNotification}

export default withNotificationBarState
