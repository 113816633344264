import React from 'react'
import {ThemeProvider, withTheme} from 'styled-components'

import {CLIENT_THEME} from 'theme/brands/brandUtils'
import {BRANDS} from 'const'
import Header from './header'
import Footer from './footer'
import StaticFooter from './footer/StaticFooter'
import StaticHeader from './header/StaticHeader/StaticHeader'
import Content from './Content'
import {PromoHeader} from './header/PromoHeader'
import ShellView from './ShellView'
import DisplaySize from '../DisplaySize'
import {isMobile} from 'utils/browserUtils'

const Shell = ({children, brand, theme, logout, email, footerOpts, staticPageOptions}) => {
  return (
    <ShellView>
      <ThemeProvider theme={CLIENT_THEME[brand] || theme}>
        <DisplaySize>
          {!!staticPageOptions ? (
            <StaticHeader logout={logout} email={email} staticPageOptions={staticPageOptions} />
          ) : (
            <>
              <Header logout={logout} email={email} mobile={isMobile()} />
              {theme.brand.name === BRANDS.sportal && <PromoHeader />}
            </>
          )}
        </DisplaySize>
      </ThemeProvider>
      <Content>{children}</Content>
      {!!staticPageOptions ? (
        <StaticFooter footerOpts={footerOpts} staticPageOptions={staticPageOptions} />
      ) : (
        <Footer footerOpts={footerOpts} />
      )}
    </ShellView>
  )
}

export default withTheme(Shell)
