import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const HeaderRingierView = styled.div`
  font-size: ${(props) => props.theme.shell.header.font.mobileSize || props.theme.shell.header.font.size};
  line-height: ${(props) => props.theme.shell.header.font.mobileHeight || props.theme.shell.header.font.height};
  font-family: ${(props) => props.theme.shell.header.font.family};
  margin-left: ${(props) => (props.withLanguageSwitcher ? '20px' : 'auto')};
  color: ${(props) => props.theme.shell.header.font.color};
  flex-shrink: 0;
  text-align: right;
  display: ${(props) => (props.theme.shell.header.hideRingierOnMobile ? 'none' : 'block')};
  ${breakpoint('portrait')`
    font-size: ${(props) => props.theme.shell.header.font.size};
    line-height: ${(props) => props.theme.shell.header.font.height};
    margin-left: ${(props) => (props.withLanguageSwitcher ? '40px' : 'auto')};
    display: block;
  `};
`

export default HeaderRingierView
