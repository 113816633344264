export const CLUBS_PT = [
  {label: 'Ac. Viseu', value: '2693'},
  {label: 'Académica', value: '464'},
  {label: 'Alverca', value: '452'},
  {label: 'Arouca', value: '465'},
  {label: 'Beira-Mar', value: '4760'},
  {label: 'Belenenses', value: '24794'},
  {label: 'Benfica', value: '450'},
  {label: 'Boavista', value: '445'},
  {label: 'Cova Piedade', value: '4780'},
  {label: 'Desp. Aves', value: '459'},
  {label: 'Desp. Chaves', value: '469'},
  {label: 'E. Amadora', value: '21677'},
  {label: 'Estoril', value: '461'},
  {label: 'F.C. Porto', value: '451'},
  {label: 'Famalicão', value: '2614'},
  {label: 'Farense', value: '441'},
  {label: 'Feirense', value: '468'},
  {label: 'Felgueiras', value: '4777'},
  {label: 'Gil Vicente', value: '447'},
  {label: 'Leixões', value: '1121'},
  {label: 'Mafra', value: '3039'},
  {label: 'Maia', value: '9343'},
  {label: 'Marco', value: '9342'},
  {label: 'Marítimo', value: '5744'},
  {label: 'Moreirense', value: '462'},
  {label: 'Nacional', value: '463'},
  {label: 'Naval', value: '5664'},
  {label: 'Oliveirense', value: '2692'},
  {label: 'Ovarense', value: '9341'},
  {label: 'P. Ferreira', value: '458'},
  {label: 'Penafiel', value: '460'},
  {label: 'Portimonense', value: '3466'},
  {label: 'Rio Ave', value: '442'},
  {label: 'Salgueiros', value: '444'},
  {label: 'Santa Clara', value: '440'},
  {label: 'Sp. Braga', value: '457'},
  {label: 'Sp. Covilhã', value: '2765'},
  {label: 'Sporting', value: '448'},
  {label: 'Tondela', value: '467'},
  {label: 'U. Leiria', value: '449'},
  {label: 'União', value: '466'},
  {label: 'V. Guimarães', value: '2943'},
  {label: 'V. Setúbal', value: '456'},
  {label: 'Varzim', value: '2676'},
]

export const SPORTS_PT = [
  {label: 'Futebol', value: '2023072616551709116'},
  {label: 'Andebol', value: '2023072616552394636'},
  {label: 'Hóquei em Patins', value: '2023072616552052491'},
  {label: 'Voleibol', value: '2023072616552729115'},
  {label: 'Ténis', value: '2023072616551988294'},
  {label: 'Atletismo', value: '2023072616552465895'},
  {label: 'Ciclismo', value: '2023072616551682444'},
  {label: 'Boxe', value: '2023072616555173854'},
  {label: 'Vela', value: '9'},
  {label: 'Automobilismo', value: '2023072616553105625'},
  {label: 'Outro', value: '2023072616555977405'},
  {label: 'Basquetebol', value: '2023072616552538940'},
  {label: 'Surf', value: '2023072616552840348'},
  {label: 'Futsal', value: '2023072616552094919'},
  {label: 'Judo', value: '2023072616553738151'},
  {label: 'Escolha', value: '16'},
]
