import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import get from 'lodash/get'
import Collapse from 'react-css-collapse'
import types from './notificationBarTypes'

const chooseBackgroundColor = (type, theme) => {
  switch (type) {
    case types.success:
      return theme.notification.success
    case types.info:
      return theme.notification.info
    case types.warning:
      return theme.notification.warning
    case types.error:
    default:
      return theme.notification.pageError
  }
}

const chooseColor = (type, theme) => {
  if (typeof get(theme, 'notification.pageMessage.colorFunc') === 'function') {
    return theme.notification.pageMessage.colorFunc(type)
  }

  return theme.font.color.light
}

const NotificationBarView = styled(Collapse)`
  font-family: ${(props) => props.theme.notification.family};
  z-index: 1;
  background-color: ${(props) => chooseBackgroundColor(props.type, props.theme)};
  color: ${(props) => chooseColor(props.type, props.theme)};
  transition-property: height;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  overflow-y: hidden;

  ${breakpoint('landscape')`
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  `};
`

export default NotificationBarView
