import {submitFormData, fetchRest} from 'utils/asyncUtils'
import config from 'config'
import {parseJWT} from 'utils/stringUtils'

class UploadService {
  static uploadAvatar = (file, brand, jwt) => {
    const {sub} = parseJWT(jwt)

    const data = new FormData()
    data.append('brand', brand)
    data.append('field', `${brand}Avatar`)
    // TODO: actual file extension
    data.append('file', file, 'avatar.jpeg')
    return submitFormData(`${config.backendUrl}/v2/users/${encodeURIComponent(sub)}/data-blob`, 'POST', data, {
      Authorization: `Bearer ${jwt}`,
    })
  }

  static deleteAvatar = (brand, jwt) => {
    const {sub} = parseJWT(jwt)

    return fetchRest(
      `${config.backendUrl}/users/${encodeURIComponent(sub)}/fields?keys=${brand}Avatar,${brand}AvatarPublicUrl`,
      'DELETE',
      {brand},
      {
        Authorization: `Bearer ${jwt}`,
      }
    )
  }
}

export default UploadService
