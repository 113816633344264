import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import get from 'lodash/get'

import config from 'config'

const chooseImage = (type, theme) => {
  if (typeof get(theme, 'notification.icon.closeImageFunc') === 'function') {
    return theme.notification.icon.closeImageFunc(type)
  }

  return theme.notification.icon.closeImage
}

const NotificationBarCloseIcon = styled.div`
  align-self: flex-start;
  min-width: ${(props) => props.theme.notification.icon.width};
  height: ${(props) => props.theme.notification.icon.height};
  background-size: 100% 100%;
  background-image: ${(props) => `url(${config.url}/assets/${chooseImage(props.type, props.theme)})`};
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${breakpoint('mobile')`
    min-width: ${(props) => props.theme.notification.icon.mobile.width};
    height: ${(props) => props.theme.notification.icon.mobile.height};
  `};
`

export default NotificationBarCloseIcon
