import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const NotificationBarMessage = styled.div`
  flex-grow: 1;
  & a {
    font-size: ${(props) => props.theme.font.size};
    line-height: ${(props) => props.theme.font.height};
  }

  & a:hover {
    color: inherit;
  }

  ${breakpoint('mobile')`
    font-size: ${(props) => props.theme.font.portrait.size};
    line-height: ${(props) => props.theme.font.portrait.height};

    & a {
      font-size: ${(props) => props.theme.font.portrait.size};
      line-height: ${(props) => props.theme.font.portrait.height};
    }
  `};
`
export default NotificationBarMessage
