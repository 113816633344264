import React from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'

import View from 'components/View'
import Title from 'components/Title'
import Note from 'components/Note'
import SmallNote from '../../commonComponents/SmallNote'
import theme from 'theme'

// Button cannot be imported statically because of cyclic module references
let Button
import('components/button').then((module) => (Button = module.default))

const EmailVerifiedSubtitle = styled(Note)`
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 140%;
`

const ButtonMargin = styled.div`
  margin-top: 24px;
`

const EmailVerifiedMessage = ({t}) => {
  return (
    <View>
      <Title>{t('email-verified.title')}</Title>
      <EmailVerifiedSubtitle>{t('email-verified.subtitle')}</EmailVerifiedSubtitle>
      <SmallNote light>{t('email-verified.login')}</SmallNote>
      <ButtonMargin>
        <Button
          {...theme.buttons.primary}
          onClick={() => {
            window.location.href = theme.brand.url
          }}>
          {t('email-verified.homepagelink')}
        </Button>
      </ButtonMargin>
    </View>
  )
}

export default translate()(EmailVerifiedMessage)
