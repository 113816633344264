import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import detector from 'i18next-browser-languagedetector'
import {getTheme} from 'theme/brands/brandUtils'

import * as envUtils from 'utils/envUtils'

let ns

switch (true) {
  case envUtils.isLoginApp():
    ns = 'hosted_app'
    break
  case envUtils.isProfileApp():
    ns = 'profile_app'
    break
  case envUtils.isConsentApp():
    ns = 'consent_app'
    break
  default:
    ns = 'profile_app'
}

// let brand = getClient(envUtils.isBrowser() ? window : null)
let brand = getTheme(window).brand.name
let brandNS = `${brand}/${ns}`
let brandCommon = `${brand}/common`

const fallbackLng = process.env.REACT_APP_BASE_LANG

const backendOptions = {
  queryStringParams: {v: process.env.REACT_APP_TRANSLATION_VERSION},
}

i18n
  .use(XHR)
  .use(detector)
  .init({
    fallbackLng,
    // lng: 'de',
    load: 'languageOnly',
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    ns: [ns, 'common', brandNS, brandCommon],
    defaultNS: brandNS,
    fallbackNS: [brandCommon, 'common', ns],

    // react i18next special options (optional)
    react: {
      wait: !envUtils.isTest(),
      nsMode: 'default',
    },

    detection: {
      order: ['querystring'],
      lookupQuerystring: 'lang',
    },

    backend: envUtils.isHosted()
      ? {
          ...backendOptions,
          crossDomain: true,
          loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
        }
      : backendOptions,
  })

export default i18n
