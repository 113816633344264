import get from 'lodash/get'
import {fetchRest} from 'utils/asyncUtils'
import {isBrowser} from 'utils/envUtils'
import {getLoginCase, getLang} from 'utils/stringUtils'
import {getClient} from 'theme/brands/brandUtils'
import config from 'config'

class GameService {
  constructor() {
    this.brand = getClient(isBrowser() ? window : null)
    this.loginCase = getLoginCase(window.location.search)
    this.lang = getLang()
  }

  fetchGameConfig = async () => {
    const response = await fetchRest(
      `${config.backendUrl}/v2/logincasegames/${this.brand}/${this.loginCase}`,
      undefined,
      undefined,
      {},
      [404]
    )

    const configByLang = get(response, `config[${this.lang}]`)
    return configByLang
  }
}

export default GameService
