import styled from 'styled-components'

const getMarginBottom = (props) => {
  let marginBottom = props.margin || props.theme.field.label.bottomMargin

  if (props.radio) {
    marginBottom = props.theme.radio.labelMarginBottom || marginBottom
  }

  return marginBottom
}

const getFontFamily = (props) => {
  if (props.fontWeight === 'bold' && props.theme.field.label.boldFamily) {
    return props.theme.field.label.boldFamily
  }

  return props.theme.field.label.family
}

const FieldLabel = styled.label`
  font-family: ${getFontFamily};
  font-weight: ${(props) => props.fontWeight || props.theme.field.label.fontWeight};
  display: ${(props) => props.display || 'inline-block'};
  font-size: ${(props) => props.fontSize || props.theme.field.label.fontSize};
  line-height: ${(props) => props.theme.field.label.fontHeight};
  margin-bottom: ${getMarginBottom};
  padding-top: ${(props) => props.paddingTop || '0'};
  color: ${(props) => (props.error && props.theme.field.label.errorColor) || props.theme.field.label.color};
`

export default FieldLabel
