import React, {Fragment} from 'react'
import {withTheme} from 'styled-components'
import {ELEMENTS_IDS} from 'const'
import {compose} from 'utils/reactUtils'
import {LanguageSwitcherSeparator, LanguageSwitcherLink, LanguageSwitcherContainer} from './LanguageSwitcherStyles'

const ACTIVE = 'active'

const StaticLanguageSwitcher = ({staticPageOptions}) => {
  const {language, urls} = staticPageOptions
  const allLanguages = Object.keys(urls)

  return (
    <LanguageSwitcherContainer>
      {allLanguages.map((lang, i) => (
        <Fragment>
          <LanguageSwitcherLink
            className={ELEMENTS_IDS.languageSwitcher}
            state={language === lang ? ACTIVE : undefined}
            to={urls[lang]}
            external>
            {lang}
          </LanguageSwitcherLink>
          {i + 1 !== allLanguages.length && <LanguageSwitcherSeparator staticMarkup>|</LanguageSwitcherSeparator>}
        </Fragment>
      ))}
    </LanguageSwitcherContainer>
  )
}

export default compose(withTheme)(StaticLanguageSwitcher)
