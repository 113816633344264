import React from 'react'

import CheckBoxError from './CheckBoxError'
import CheckBoxLabel from './CheckBoxLabel'
import CheckBoxInput from './CheckBoxInput'
import CheckBoxNote from './CheckBoxNote'
import CheckBoxWrapper from './CheckBoxWrapper'

export const onToggle = (onChange, onBlur) => (event) => {
  if (event && event.target) {
    const {name, checked} = event.target
    onChange && onChange({target: {name, value: checked}})
    onBlur && onBlur({target: {name, value: checked}})
  }
}

const CheckBox = ({
  onChange,
  onBlur,
  name,
  label,
  labelStyles,
  className,
  value,
  defaultChecked,
  error,
  disabled,
  groupItem,
  note,
  description,
}) => {
  return (
    <CheckBoxWrapper className={className} error={error}>
      <CheckBoxInput
        name={name}
        disabled={disabled}
        onChange={onToggle(onChange, onBlur)}
        value={value}
        defaultChecked={defaultChecked}
      />
      <CheckBoxLabel
        name={name}
        disabled={disabled}
        label={label}
        error={error}
        groupItem={groupItem}
        styles={labelStyles}
      />
      {note && <CheckBoxNote>{note}</CheckBoxNote>}
      {description && <CheckBoxNote>{description}</CheckBoxNote>}
      {error && <CheckBoxError>{error}</CheckBoxError>}
    </CheckBoxWrapper>
  )
}

export default CheckBox
