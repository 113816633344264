export const EVENT_CATEGORY = {
  account: 'Account',
  accountActivation: 'Account Activation',
  additionalInformation: 'Additional Information',
  address: 'Address',
  contact: 'Contact',
  information: 'Information',
  email: 'E-Mail',
  emailVerification: 'Email Verification',
  login: 'Login',
  logout: 'Logout',
  navigation: 'Navigation',
  password: 'Password',
  personalData: 'Personal Data',
  registration: 'Registration',
  registrationRc: 'Registration Ringier Connect',
}

export const FORM_STATE = {
  success: 'success',
  error: 'error',
}
