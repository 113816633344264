import merge from 'lodash/merge'
import config from 'config'
import {CLIENTS} from 'const'
import blic from '../blic'

const theme = merge({}, blic, {
  brand: {
    name: CLIENTS.ZENA,
    icon: {
      url: 'brands/zena/logo-zena.svg',
      href: 'https://zena.blic.rs/',
    },
    url: 'https://zena.blic.rs/',
    favicon: `${config.url}/assets/brands/zena/favicon.ico`,
  },
  shell: {
    header: {
      icon: {
        image: 'brands/zena/logo-zena.svg',
        height: '24px',
        portrait: {image: 'brands/zena/logo-zena.svg', height: '40px'},
        href: 'https://zena.blic.rs/',
      },
    },
  },
  otherBrandsLogos: [
    {
      icon: `${config.url}/assets/brands/zena/logo-zena-black.svg`,
      footerHeight: '14px',
      ringierSectionHeight: '14px',
    },
    {
      icon: `${config.url}/assets/brands/superrecepti/logo-superrecepti-black.svg`,
      footerHeight: '20px',
      ringierSectionHeight: '20px',
    },
    {
      icon: `${config.url}/assets/brands/blic/logo-blic-black.svg`,
      footerHeight: '20px',
      ringierSectionHeight: '20px',
    },
    {
      icon: `${config.url}/assets/brands/ana/logo-ana-black.svg`,
      footerHeight: '14px',
      ringierSectionHeight: '14px',
    },
  ],
})

export default theme
