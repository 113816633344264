import React from 'react'
import HelpText from 'components/Optional'

const BirthdateLabel = ({t}) => {
  return (
    <span>
      {t('inputs.birthdate')} {<HelpText>{t('date-pattern')}</HelpText>}
    </span>
  )
}

export default BirthdateLabel
