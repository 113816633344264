import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Note = styled.div`
  color: ${(props) => props.color || props.theme.note.color};
  font-family: ${(props) => (props.light ? props.theme.note.lightFamily : props.theme.note.family)};
  font-weight: ${(props) => (props.light ? props.theme.note.lightFontWeight : props.theme.note.fontWeight)};
  font-size: ${(props) => (props.light ? props.theme.font.mobile.size : props.theme.font.portrait.size)};
  line-height: ${(props) => (props.light ? props.theme.font.mobile.height : props.theme.font.portrait.height)};
  margin-bottom: ${(props) => props.marginBottom || '20px'};
  margin-top: ${(props) => props.marginTop};

  ${breakpoint('portrait')`
    font-size: ${(props) => props.theme.note.fontSize || props.theme.font.portrait.size};
    line-height: ${(props) => props.theme.font.portrait.height};

    & a {
      font-size: ${(props) => props.theme.note.fontSize || props.theme.font.portrait.size};
    }
  `};
`

export default Note
