import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Content = styled.div`
  flex: 1 0 auto;
  align-self: center;
  width: 100%;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }

  ${breakpoint('landscape')`
      width: 1024px;
  `};
`

export default Content
