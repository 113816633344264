import React, {Suspense} from 'react'
import {HashRouter, Switch, Route} from 'react-router-dom'
import {I18nextProvider} from 'react-i18next'
import GoogleAnalyticsService from 'services/GoogleAnalyticsService'
import {getClient} from 'theme/brands/brandUtils'

import Shell from 'components/shell'
import ConsentAppPage from './ConsentAppPage'
import {
  EmailVerified,
  Error,
  Unblock,
  About,
  Support,
  LegalNotice,
  BlockedIp,
  WebAuthnSetup,
  TwoFactorAuthTotpStart,
  TwoFactorAuthTotpSetup,
  TwoFactorAuthTotpBackup,
  TwoFactorAuthTotpSuccess,
  TwoFactorAuthSmsSetup,
  TwoFactorAuthSmsSuccess,
  TwoFactorAuthVerify,
  TwoFactorExpiredTokenError,
} from './ConsentAppAsyncRoutes'

import i18n from 'i18n-client'

const ConsentApp = () => {
  const footerOpts = {
    margin: '20px',
  }

  GoogleAnalyticsService.pushToGTMDataLayer({media_title: getClient()})

  return (
    <I18nextProvider i18n={i18n}>
      <HashRouter>
        <Suspense fallback="">
          <Switch>
            <Route path="/email/verified" exact render={() => <EmailVerified />} />
            <Route path="/webauthn/setup" exact render={() => <WebAuthnSetup />} />
            <Route path="/mfa/totp/start" exact render={() => <TwoFactorAuthTotpStart />} />
            <Route path="/mfa/totp/setup" exact render={() => <TwoFactorAuthTotpSetup />} />
            <Route path="/mfa/backup" exact render={() => <TwoFactorAuthTotpBackup />} />
            <Route path="/mfa/totp/success" exact render={() => <TwoFactorAuthTotpSuccess />} />
            <Route path="/mfa/sms/start" exact render={() => <TwoFactorAuthSmsSetup />} />
            <Route path="/mfa/sms/success" exact render={() => <TwoFactorAuthSmsSuccess />} />
            <Route path="/mfa/totp/verify" exact render={() => <TwoFactorAuthVerify />} />
            <Route path="/mfa/sms/verify" exact render={() => <TwoFactorAuthVerify />} />
            <Route path="/mfa/expired-token-error" exact render={() => <TwoFactorExpiredTokenError />} />
            <Route
              render={() => (
                <Shell footerOpts={footerOpts}>
                  <Switch>
                    <Route path="/blocked-ip" exact render={() => <BlockedIp />} />
                    <Route path="/error" render={() => <Error />} />
                    <Route path="/unblock" exact render={() => <Unblock />} />
                    <Route path="/about" exact render={() => <About />} />
                    <Route path="/support" exact render={() => <Support />} />
                    <Route path="/impressum" exact render={() => <LegalNotice />} />
                    <Route render={() => <ConsentAppPage />} />
                  </Switch>
                </Shell>
              )}
            />
          </Switch>
        </Suspense>
      </HashRouter>
    </I18nextProvider>
  )
}

export default ConsentApp
