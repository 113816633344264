import {GoogleAnalyticsService} from 'services/GoogleAnalyticsService'
import {getConnection} from './stringUtils'
import {get} from 'lodash'
export const sendGAEventsOnSignUp = (agreements, success, methodOverride, errorMessage) => {
  const method = methodOverride || getConnection(window.location.search)
  if (get(agreements, 'global.show')) {
    if (success) {
      GoogleAnalyticsService.trackSignUp({isRc: true, method})
    } else {
      GoogleAnalyticsService.trackSignUpFail({isRc: true, method, errorMessage})
    }
  }
  if (get(agreements, 'client.show')) {
    if (success) {
      GoogleAnalyticsService.trackSignUp({isRc: false, method})
    } else {
      GoogleAnalyticsService.trackSignUpFail({isRc: false, method, errorMessage})
    }
  }
}
