import React from 'react'
import styled from 'styled-components'

import config from 'config'
import {CheckBoxLabelView} from './CheckBoxLabel'
import {StyledCheckBoxIcon, CheckBoxIconView} from './CheckBoxIcon'

const CheckBoxInputView = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  /*Adding focus styles on the outer-box of the fake checkbox*/
  :focus + ${CheckBoxLabelView} ${StyledCheckBoxIcon} {
    background-image: url(${config.url}/assets/${(props) => props.theme.checkbox.icon.hoverImage});
  }

  :checked + ${CheckBoxLabelView} ${CheckBoxIconView} {
    height: ${(props) => props.theme.checkbox.icon.height};
    width: ${(props) => props.theme.checkbox.icon.width};
    min-width: ${(props) => props.theme.checkbox.icon.width};
    margin-right: ${(props) => props.theme.checkbox.icon.right};
    margin-top: ${(props) => props.theme.checkbox.icon.top};
  }

  :checked + ${CheckBoxLabelView} ${StyledCheckBoxIcon} {
    background-image: url(${config.url}/assets/${(props) => props.theme.checkbox.icon.checkedImage});
  }

  // add for preloading icon
  background: url(${config.url}/assets/${(props) => props.theme.checkbox.icon.checkedImage}) no-repeat -9999px -9999px,
    url(${config.url}/assets/${(props) => props.theme.checkbox.icon.hoverImage}) no-repeat -9999px -9999px,
    url(${config.url}/assets/${(props) => props.theme.checkbox.icon.errorImage}) no-repeat -9999px -9999px;
`

const CheckBoxInput = ({onChange, name, value, defaultChecked, disabled, error}) => {
  return (
    <CheckBoxInputView
      type="checkbox"
      disabled={disabled}
      name={name}
      id={name}
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={value}
      tabIndex={0}
      error={error}
    />
  )
}

export default CheckBoxInput
