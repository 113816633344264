import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getHeaderPadding = (props) => props.theme.shell.header.padding || '20px 20px 16px'
const getMinHeight = (props) =>
  props.email ? props.theme.shell.header.height.mobile : props.theme.shell.header.height.default
const HeaderInnerView = styled.div`
  max-width: 1024px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => props.email && 'column'};
  align-items: ${(props) => (props.email ? 'flex-start' : 'center')};
  padding: ${getHeaderPadding};
  justify-content: space-between;
  min-height: ${getMinHeight};

  /* prevent ie position flexboxissue */
  height: 1px;

  & > img {
    height: ${(props) => props.theme.shell.header.icon.height};
    width: ${(props) => props.theme.shell.header.icon.width};
    padding: ${(props) => props.email && '0px'};
    margin-top: ${(props) => props.theme.shell.header.icon.marginTop};
  }

  ${breakpoint('portrait')`
      min-height: ${(props) => props.theme.shell.header.height.portrait};
      padding: 0 40px;
      flex-direction: row;
      align-items: center;
      justify-content: unset;

      & > img {
        height: ${(props) => props.theme.shell.header.icon.portrait.height};
        width: auto;
        padding: 0;
        margin-top: 0;
      }
  `};
`

export default HeaderInnerView
