import React from 'react'
import i18n from 'i18n-client'

export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg
  )

export const renderMessage = (message, props, isGlobal) =>
  typeof message === 'string' ? (isGlobal ? message : i18n.t(message)) : React.createElement(message, props)
