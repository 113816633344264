import qs from 'qs'
import decode from 'jwt-decode'

const newsletterNameAndPeriodicity = /^(.*)\s*(\(.*\))(.*)$/

export const formatDate = (date) => {
  const dateObj = new Date(date)
  const units = [dateObj.getDate(), dateObj.getMonth() + 1, dateObj.getFullYear()]
  return units.map((item) => item.toString().padStart(2, '0')).join('.')
}

export const parseNewsletter = (newsletter) => {
  const newsletterParts = newsletter.match(newsletterNameAndPeriodicity)
  return newsletterParts ? newsletterParts.slice(1) : [newsletter, null]
}

export const parseNewsletterWithoutPeriodicy = (newsletter) => {
  const delimiter = '||'
  if (!newsletter.includes(delimiter)) {
    return [newsletter]
  }
  const [name, description] = newsletter.split(delimiter)
  return [name, null, description]
}

export const parseJWT = (token) => {
  try {
    return decode(token)
  } catch (e) {}
  return null
}

export const getParamFromQuery = (paramName, search) => {
  let param

  const queryParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  if (queryParams && queryParams[paramName]) {
    param = queryParams[paramName]
  }

  return param
}

export const getParamFromHash = (paramName, hash) => {
  let param
  const hashWithoutPath = hash.split('?')[1] || ''
  const hashParams = qs.parse(hashWithoutPath, {
    ignoreQueryPrefix: true,
  })

  if (hashParams && hashParams[paramName]) {
    param = hashParams[paramName]
  }

  return param
}

export const getHeliosUrlBaseUrlOverride = (search) => getParamFromQuery('helios_url', search)

export const getInteractionUid = (search) => getParamFromQuery('interaction', search)

export const getToken = (hash) => getParamFromHash('token', hash)

export const getState = (search) => getParamFromQuery('state', search)

export const getConnection = (search) => getParamFromQuery('connection', search)

export const getClient = (search) => getParamFromQuery('client', search)

export const getError = (search) => getParamFromQuery('error', search)

export const getErrorDescription = (search) => getParamFromQuery('error_description', search)

export const getErrorTraceId = (search) => getParamFromQuery('error_trace_id', search)

export const getErrorUpstream = (search) => getParamFromQuery('error_upstream', search)

export const getWebview = (search) => getParamFromQuery('webview', search)

export const getLinking = (search) => {
  const value = getParamFromQuery('linking', search)
  return value ? value === 'true' : undefined
}

export const getLoginCase = (search) => getParamFromQuery('login_case', search)

export const getSource = (search) => getParamFromQuery('source', search)

export const getFirstName = (search) => getParamFromQuery('first_name', search)

export const getTicket = (search) => getParamFromQuery('ticket', search)

export const getEmail = (search) => {
  const email = getParamFromQuery('email', search)
  return email ? decodeURIComponent(email) : ''
}

export const getLang = () => getParamFromQuery('lang', window.location.search) || process.env.REACT_APP_BASE_LANG || ''

export const getLoginHint = (search) => {
  const loginHint = getParamFromQuery('login_hint', search)
  return loginHint ? decodeURIComponent(loginHint) : ''
}

export const getEmailHint = (search) => {
  const emailHint = getParamFromQuery('email_hint', search)
  return emailHint ? decodeURIComponent(emailHint) : ''
}

export const getEmailForce = (search) => {
  const emailForce = getParamFromQuery('email_force', search)
  return emailForce ? decodeURIComponent(emailForce) : ''
}

export const trim = (value) => {
  return typeof value === 'string' && value ? value.trim() : value
}

export const createOrUpdateQueryParam = (name, value) => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })
  queryParams[name] = value
  const queryString = qs.stringify(queryParams)
  window.location.search = queryString
}

// copied from auth0-js https://github.com/auth0/auth0.js/blob/c35e6c18f905a0fca0dcb6edca129ea6661898f4/src/helper/random.js
export const randomString = (length) => {
  // eslint-disable-next-line
  const bytes = new Uint8Array(length)
  const result = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  const cryptoObj = window.crypto || window.msCrypto
  if (!cryptoObj) {
    return null
  }

  const random = cryptoObj.getRandomValues(bytes)

  for (let a = 0; a < random.length; a++) {
    result.push(charset[random[a] % charset.length])
  }

  return result.join('')
}

export const getClientIdFromGACookie = (gaCookie) => {
  try {
    return gaCookie.split('.').slice(2).join('.')
  } catch {
    return undefined
  }
}
