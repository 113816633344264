import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const NotificationBarMobileView = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 14px;

  ${breakpoint('mobile')`
    align-items: center;
    flex-direction: row;
    padding: 0 20px;
  `};
`

export default NotificationBarMobileView
