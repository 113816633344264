import React from 'react'
import styled from 'styled-components'
import config from 'config'

const PromoContainer = styled.div`
  background-color: #006939;
  height: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoCarlsberg = styled.img`
  height: 32px;
  width: 245px;
`

export const PromoHeader = (props) => {
  return (
    <PromoContainer {...props}>
      <LogoCarlsberg src={`${config.url}/assets/logo-carlsberg-BG.svg`} />
    </PromoContainer>
  )
}
