import config from 'config'
import {BRANDS, LOGIN_CASES} from 'const'
import {getHeaderLogo} from '../commonHelper'

const brandUrl = 'https://www.libertatea.ro/'

const NORMAL_FONT = 'Signika, Helvetica, Arial, sans-serif'
const BOLD_FONT = 'Signika-Bold, Helvetica, Arial, sans-serif'

export default {
  brand: {
    name: BRANDS.libertatea,
    url: brandUrl,
    favicon: `${config.url}/assets/brands/libertatea/favicon.ico`,
    fontColor: '#323232',
  },
  config: {
    separatorLanding: false,
    marketingOnePager: ({loginCase}) => loginCase === LOGIN_CASES.EMAIL_ONLY,
    newsletterOnePager: false,
    marketingConsent: true,
  },
  fontConfig: {
    custom: {
      urls: [`${config.url}/fonts/libertatea/index.css`],
    },
  },
  font: {
    family: NORMAL_FONT,
    fontWeight: 'normal',
    boldFamily: BOLD_FONT,
    boldFontWeight: 'bold',
    size: '14px',
    height: '20px',
    mobile: {
      size: '16px',
      height: '24px',
    },
    portrait: {
      size: '16px',
      height: '24px',
    },
    color: {
      dark: '#000000',
      light: '#FFFFFF',
    },
  },
  secondaryFont: {
    size: '16px',
    height: '24px',
    mobile: {
      size: '16px',
      height: '24px',
    },
    portrait: {
      size: '16px',
      height: '24px',
    },
  },
  title: {
    family: BOLD_FONT,
    size: '28px',
    height: '32px',
    mobile: {
      size: '28px',
      height: '32px',
    },
    portrait: {
      size: '42px',
      height: '46px',
    },
    weight: '600',
  },
  ringierBanner: {
    benefitsIcon: `${config.url}/assets/SI-icon-check-banner.svg`,
    linkColor: '#000000',
    linkHoverColor: '#000000',
    linkFontSize: '14px',
    padding: '40px',
    borderRadius: '0',
    benefitMessageColor: '#000000',
    backgroundColor: '#F1F1F2',
    fontSize: '16px',
    lineHeight: '22px',
    iconWidth: '24px',
    iconHeight: '24px',
    landscape: {
      fontSize: '16px',
      lineHeight: '20px',
      subtitleFontSize: '14px',
    },
    titleFontWeight: 'bold',
    titleFontSize: '18px',
    titleFontFamily: BOLD_FONT,
    onelogTitle: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
      color: '#000000',
      fontFamily: BOLD_FONT,
      margin: '0 0 20px 0',
    },
    onelogSection: {},
    logosViewWidth: '50%',
    benefitsMarginBottom: '40px',
  },
  otherBrandsLogos: [
    {
      icon: `${config.url}/assets/brands/libertatea/footer-logo.svg`,
      footerHeight: '28px',
      ringierSectionHeight: '28px',
    },
    {
      icon: `${config.url}/assets/brands/gsp/footer-logo.svg`,
      footerHeight: '17px',
      ringierSectionHeight: '17px',
    },
  ],
  note: {
    family: BOLD_FONT,
    lightFamily: NORMAL_FONT,
    fontSize: '18px',
    fontWeight: '700',
    lightFontWeight: '400',
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  notification: {
    family: '',
    success: '#0DA225',
    error: '#EA2700',
    pageError: '#EA2700',
    warning: '#F76B00',
    info: '#4B73DA',
    icon: {
      width: '24px',
      height: '24px',
      mobile: {
        width: '32px',
        height: '32px',
      },
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `icon-${type}.svg`,
    },
    pageMessage: {
      size: '16px',
      height: '24px',
      borderRadius: '0',
      colorFunc: (type) => '#FFFFFF',
    },
  },
  buttons: {
    height: '50px',
    primary: {
      border: 0,
      color: '#FFFFFF',
      background: '#EA2700',
      hoverBorder: 0,
      hoverBackground: '#C62100',
      focusBorder: '2px solid #333333',
      focusBackground: '#C62100',
      activeBorder: 0,
      activeBackground: '#EA2700',
      isAnimated: true,
    },
    secondary: {
      border: '1px solid currentColor',
      color: '#EA2700',
      background: '#fff',
      hoverBorder: '1px solid #C62100',
      hoverColor: '#C62100',
      focusBorder: '2px solid #C62100',
      focusBackground: '#fff',
      activeBorder: '2px solid #C62100',
    },
    disabled: {
      border: 0,
      color: '#FFFFFF',
      hoverColor: '#FFFFFF',
      background: '#D8D8D8',
      hoverBorder: 0,
      hoverBackground: '#D8D8D8',
      activeBorder: 0,
      activeBackground: '#D8D8D8',
    },
    facebook: {
      border: '1px solid #C7C8C9',
      color: '#000000',
      background: '#FFFFFF',
      hoverBorder: '1px solid #000000',
      hoverBackground: '#FFFFFF',
      focusBorder: '2px solid #000000',
      focusBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-facebook-24x24.svg',
      iconStyles: {
        padding: '12px',
        marginLeft: '0',
      },
      textStyles: {
        weight: 'bold',
        family: BOLD_FONT,
      },
      isAnimated: false,
      full: true,
    },
    apple: {
      border: '1px solid #C7C8C9',
      color: '#000000',
      background: '#FFFFFF',
      hoverBorder: '1px solid #000000',
      hoverBackground: '#FFFFFF',
      focusBorder: '2px solid #000000',
      focusBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'logo-apple-black.svg',
      iconStyles: {
        padding: '12px',
        marginLeft: '0',
      },
      textStyles: {
        weight: 'bold',
        family: BOLD_FONT,
      },
      isAnimated: false,
      full: true,
    },
    google: {
      border: '1px solid #C7C8C9',
      color: '#000000',
      background: '#FFFFFF',
      hoverBorder: '1px solid #000000',
      hoverBackground: '#FFFFFF',
      focusBorder: '2px solid #000000',
      focusBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-google-24x24.svg',
      iconStyles: {
        padding: '12px',
        marginLeft: '0',
      },
      textStyles: {
        weight: 'bold',
        family: BOLD_FONT,
      },
      isAnimated: false,
      full: true,
    },
    touchId: {
      border: 0,
      color: '#FFFFFF',
      background: '#EA2700',
      hoverBorder: 0,
      hoverBackground: '#C62100',
      focusBorder: '2px solid #333333',
      focusBackground: '#C62100',
      activeBorder: 0,
      activeBackground: '#EA2700',
      isAnimated: true,
      full: true,
      icon: 'icon-touchid.svg',
      iconStyles: {padding: '12px', marginLeft: '0'},
    },
    faceId: {
      border: 0,
      color: '#FFFFFF',
      background: '#EA2700',
      hoverBorder: 0,
      hoverBackground: '#C62100',
      focusBorder: '2px solid #333333',
      focusBackground: '#C62100',
      activeBorder: 0,
      activeBackground: '#EA2700',
      isAnimated: true,
      full: true,
      icon: 'icon-faceid.svg',
      iconStyles: {padding: '12px', marginLeft: '0'},
    },
    anyId: {
      border: 0,
      color: '#FFFFFF',
      background: '#EA2700',
      hoverBorder: 0,
      hoverBackground: '#C62100',
      focusBorder: '2px solid #333333',
      focusBackground: '#C62100',
      activeBorder: 0,
      activeBackground: '#EA2700',
      isAnimated: true,
      full: true,
      icon: 'icon-touchid.svg',
      iconStyles: {padding: '12px', marginLeft: '0'},
    },
    icon: {
      height: '24px',
      width: '24px',
    },
    text: {
      family: BOLD_FONT,
      color: '#004056',
      size: '14px',
      height: '24px',
      weight: 'bold',
      transform: 'uppercase',
    },
    border: {
      radius: '0',
    },
    linkOnSecondLine: true,
    dotsColor: '#FFFFFF',
  },
  link: {
    default: '#EA2700',
    hoverColor: '#C9182A',
    secondary: '#000000',
    footer: '#000000',
    footerHover: '#000000',
    secondaryHover: '#000000',
    isAgreementsLinkSecondary: true,
    textDecorationHover: 'none',
    align: 'flex-start',
  },
  logisterSection: {
    registerMarginTop: '20px',
    registerWeight: 300,
    subheaderBorderTop: '1px solid #d9d9d9',
    subheaderPadding: '12px 20px',
    subheaderFamily: BOLD_FONT,
    subheaderFontSize: '14px',
    subheaderLineHeight: '20px',
    subheaderTitleColor: '#000000',
    subheaderTitleMargin: '0 0 4px 0',
    subheaderLinkColor: '#000000',
    subheaderLinkHoverColor: '#000000',
    subheaderBackgroundColor: '#F1F1F2',
    noteColor: '#000000',
    noteFontSize: {
      default: '14px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'mobile',
      fontSize: {
        smallMobile: '28px',
        mobile: '28px',
        portrait: '28px',
        landscape: '34px',
        desktop: '34px',
      },
      lineHeight: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '40px',
        desktop: '40px',
      },
    },
    subtitle: {
      color: '#000000',
      fontSize: {
        default: '14px',
        portrait: '14px',
      },
      lineHeight: '20px',
      margin: '-8px 0 6px',
      link: {
        color: '#000000',
        hoverColor: '#000000',
      },
    },
  },
  form: {
    border: '',
    background: '#F1F1F2',
    fieldBackground: '',
    padding: '0 0 12px 0',
    sectionViewBackground: '#f8f8f8',
    marginBottom: '8px',
    loginSubmitMarginTop: '0',
    note: {
      fontSize: '18px',
    },
  },
  customerNumber: {
    margin: '0 0 20px 0',
    padding: '20px 0',
    backgroundColor: {
      field: '#f1f1f2',
      input: '#ffffff',
      error: '#ffffff',
    },
    label: {
      consent: {
        margin: '4px',
      },
      profile: {
        margin: '0px',
      },
    },
    description: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    button: {
      fontSize: '14px',
    },
    helpIcon: {
      img: 'streamingch-icon-hint.png',
      hoverImg: 'streamingch-icon-hint-hover.png',
      height: '24px',
      top: '14px',
      right: '14px',
    },
    closeIcon: {
      color: '#000000',
      height: '24px',
      margin: '2px 1px 0 10px;',
    },
    warning: {
      size: '16px',
      height: '24px',
      backgroundImage: 'streamingch-icon-error.svg',
    },
    buttons: {
      marginTop: '0px',
      marginBottom: '-5px',
    },
    success: {
      icon: 'streamingch-icon-success.svg',
      margin: '0px -20px -5px -20px',
      padding: '3px 20px 5px 56px',
    },
  },
  logisterForm: {
    border: '',
    background: '',
    padding: '',
    submitButton: 'primary',
    forgetLinkFloat: 'right',
  },
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        fontSize: '14px',
        marginTop: '6px',
      },
    },
  },
  registerPage: {
    backLinkMarginBottom: {
      default: '12px',
      portrait: '12px',
    },
    titleMargin: {
      default: '0 0 20px',
      portrait: '0 0 20px',
    },
    submitButtonMarginTop: 0,
  },
  emailVerifyPage: {
    linkMarginBottom: '10px',
    note: {
      fontFamily: BOLD_FONT,
    },
  },
  field: {
    error: {
      background: 'inherit',
      fontColor: '#DF0D29',
      size: '14px',
      height: '20px',
      padding: '4px 20px 0',
      margin: '0 -20px',
    },
    label: {
      color: '#000000',
      disabledColor: 'rgba(31,31,31,0.6)',
      family: NORMAL_FONT,
      boldFamily: BOLD_FONT,
      fontSize: '16px',
      fontHeight: '24px',
      bottomMargin: '4px',
      fontWeight: '300',
    },
    edging: {
      backgroundColor: '#FFFFFF',
    },
  },
  input: {
    background: '#FFFFFF',
    errorBackground: '#FFE8E8',
    border: '1px solid #C7C8C9',
    errorBorder: '1px solid #EA2700',
    focusBorder: '1px solid #C7C8C9',
    hoverBorder: '',
    borderRadius: '0',
    height: '50px',
    padding: '12px',
    email: {
      color: '#212529',
      backgroundColor: '#F1F1F2',
      border: '0',
      borderRadius: '0',
      button: {
        height: '32px',
        right: '12px',
        backgroundColor: '#FFFFFF',
        color: '#E01B2F',
        fontFamily: BOLD_FONT,
        fontSize: '14px',
        textDecoration: 'none',
        border: '1px solid #E01B2F',
        borderRight: '1px solid #E01B2F',
        borderRadius: '0',
        hoverColor: '#E01B2F',
        hoverBackgroundColor: '#FFFFFF',
        hoverBorder: '1px solid #C9182A',
        hoverBorderRight: '1px solid #C9182A',
      },
    },
    password: {
      color: '#000000',
      hoverColor: '#000000',
      size: '14px',
      height: '20px',
      top: '14px',
    },
    loading: {top: '7px'},
  },
  checkbox: {
    icon: {
      height: '20px',
      width: '20px',
      top: '3px',
      right: '12px',
      image: 'brands/libertatea/LIB-form-checkbox-default.svg',
      checkedImage: 'brands/libertatea/LIB-form-checkbox-checked.svg',
      hoverImage: 'brands/libertatea/LIB-form-checkbox-default.svg',
      errorImage: 'brands/libertatea/LIB-form-checkbox-error.svg',
      disabledImage: 'brands/libertatea/LIB-form-checkbox-inactive.svg',
    },
    errorMargin: '',
    padding: '16px 20px',
    labelFontHeight: '24px',
    group: {
      label: {
        marginBottom: '8px',
        padding: '0',
        backgroundColor: '',
      },
      marginBottom: '20px',
      padding: '0 20px',
      backgroundColor: '#F1F1F2',
      fontWeight: '400',
      fontSize: '16px',
      note: {
        fontWeight: '300',
        fontSize: '14px',
        marginLeft: '28px',
        color: '#000000',
      },
    },
    errorPadding: '4px 20px 0',
  },
  select: {
    icon: {
      width: '16px',
      height: '16px',
      image: 'SI-form-arrow-down.svg',
      focusImage: 'SI-form-arrow-down.svg',
      alt_image: 'SI-form-arrow-down.svg',
    },
    menu: {
      border: '1px solid #BBBBBB',
      checkIcon: 'NRJ-check-form-grey.svg',
      option: {
        focusBorder: '0',
        focusBackground: '#fff',
        focusColor: '#E30613',
      },
    },
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      top: '2px',
      image: 'brands/libertatea/LIB-form-radio.svg',
      checkedImage: 'brands/libertatea/LIB-form-radio-active.svg',
      hoverImage: 'brands/libertatea/LIB-form-radio.svg',
      errorImage: 'brands/libertatea/LIB-form-radio-error.svg',
      disabledImage: 'SI-form-radio-empty-inactive.svg',
    },
    errorMargin: '0 -20px 0px',
    labelFontHeight: '24px',
    labelMarginBottom: '8px',
  },
  errorPage: {
    font: {
      height: '18px',
      portrait: {
        height: '30px',
      },
    },
  },
  inputNote: {color: '#1F1F1F', size: '14px', height: '20px', marginTop: '4px'},
  separator: {
    background: '#C7C8C9',
    color: '#666666',
    height: '1px',
    fontSize: '14px',
    lineHeight: '16px',
    portraitMargin: '24px 0 28px 0',
  },
  tab: {
    fontSize: '18px',
    desktop: {focusBorder: 'solid 4px #000000', bottomMargin: '-4px', height: '80px'},
    mobile: {icon: {border: 'solid 2px #ACACAC', width: '14px', height: '14px', color: '#000000'}},
  },
  shell: {
    header: {
      color: '#fff',
      user: {
        font: {
          default: {
            size: '16px',
            height: '140%',
          },
          portrait: {
            size: '16px',
            height: '140%',
          },
        },
        showLoginAs: true,
      },
      font: {
        family: NORMAL_FONT,
        color: '#000000',
        size: '12px',
        height: '12px',
      },
      border: {
        width: '0 0 2px 0',
        color: '#ededef',
        style: 'solid',
      },
      height: {
        mobile: '106px',
        default: '60px',
        portrait: '80px',
      },
      ringierLogo: {
        img: 'logo-poweredby.svg',
        default: {height: '38px', width: '81px'},
        portrait: {height: '38px', width: '81px', marginTop: '7px', paddingTop: '4px'},
        marginLeft: 'auto',
        paddingTop: '1px',
      },
      icon: {
        image: getHeaderLogo(BRANDS.libertatea),
        height: '40px',
        marginTop: '-4px',
        portrait: {
          height: '54px',
          image: getHeaderLogo(BRANDS.libertatea),
        },
        href: brandUrl,
      },
      logoMarginRight: {
        default: '20px',
        portrait: '40px',
      },
    },
    footer: {
      copyright: {
        backgroundColor: '#333333',
        darkFontColor: '#FFFFFF',
        darkHover: '#FFFFFF',
        darkPadding: '12px 20px 12px 20px',
      },
      backgroundColor: '#F1F1F2',
      color: '#000000',
      family: BOLD_FONT,
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '20px',
      logoHeight: '22px',
      brandsInnerViewPadding: '20px 40px 24px',
      logoPadding: '0',
      logoPortraitPadding: '0 0 4px 0',
      onelogLogo: {
        src: `${config.url}/assets/logo-onelog-black.svg`,
        height: '20px',
        width: '94px',
        margin: '0 0 8px 0',
      },
    },
  },
  consent: {
    subtitle: {
      marginBottom: '8px',
    },
    checkbox: {
      marginBottom: '20px',
      paddingBottom: '',
    },
  },
  mobileNumber: {
    code: {
      letterSpacing: '7px',
      underline: {
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 7px)',
        desktopLeft: 'calc(25% - 20px);',
        desktopTop: 'calc(50% - 9px)',
      },
    },
  },
  address: {
    padding: '',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  mfa: {
    backgroundColor: '#f8f8f8',
    inputBackgroundColor: '#FFFFFF',
  },
  languageSwitcher: {
    profileMenuIcon: 'OL-icon-arrow-down-grey.svg',
    profileMenuColor: '#3C5162',
  },
}
