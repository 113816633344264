import React from 'react'
import {renderToStaticMarkup} from 'react-dom/server'
import get from 'lodash/get'
import config from 'config'
import StaticLogo from './StaticLogo'

const StaticHeaderLogo = ({theme, staticPageOptions: {language}}) => {
  const image = get(theme, `shell.header.icon.imageByLang[${language}]`, theme.shell.header.icon.image)
  const src = `${config.url}/assets/${image}`

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<div ${theme.brand.url && `onClick="window.location.assign('${theme.brand.url}')"`}>
          ${renderToStaticMarkup(<StaticLogo theme={theme} src={src} onClick={!!theme.brand.url} />)}</div>`,
      }}
    />
  )
}

export default StaticHeaderLogo
