import React, {Fragment} from 'react'
import {translate} from 'react-i18next'
import Grid from 'styled-components-grid'
import {withTheme} from 'styled-components'
import LogisterPageTitle from './LogisterPageTitle'
import {LogisterFormTitleNote, LogisterPageButton} from './LogisterPageStyles'
import {ELEMENTS_IDS} from 'const'
import {compose} from 'utils/reactUtils'

const {googleLogin, microsoftLogin} = ELEMENTS_IDS.logisterPage

const LogisterPageGrid = ({loginCase, loginWithGoogle, loginWithMicrosoft, t, theme}) => {
  return (
    <Fragment>
      <div />
      <Grid valign="top">
        <Grid.Unit size={{portrait: 1 / 2}}>
          <LogisterPageTitle loginCase={loginCase} />
          <LogisterFormTitleNote>{t('logister-header-note')}</LogisterFormTitleNote>
          <Fragment>
            <LogisterPageButton
              id={googleLogin}
              label="buttons.google"
              {...theme.buttons.google}
              onClick={loginWithGoogle}
            />
            <LogisterPageButton
              id={microsoftLogin}
              label="buttons.microsoft"
              {...theme.buttons.microsoft}
              onClick={loginWithMicrosoft}
            />
          </Fragment>
        </Grid.Unit>
      </Grid>
    </Fragment>
  )
}

export default compose(translate(), withTheme)(LogisterPageGrid)
