import React from 'react'
import get from 'lodash/get'
import {getLang} from 'utils/stringUtils'

import FooterLogo from './FooterLogo'
import FooterLogosView from './FooterLogosView'

const FooterLogos = (props) => {
  const lang = props.lang || getLang()
  const logos = props.logos.map((logo) => ({...logo, icon: get(logo, `iconByLang[${lang}]`, logo.icon)}))
  return (
    <FooterLogosView logosCount={logos.length}>
      {logos.map((logo, index) => (
        <FooterLogo key={index} src={logo.icon} height={logo.footerHeight} />
      ))}
    </FooterLogosView>
  )
}

export default FooterLogos
