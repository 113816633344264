import React, {Component} from 'react'
import {ThemeProvider} from 'styled-components'
import WebfontLoader from '@dr-kobros/react-webfont-loader'
import * as logger from 'utils/logger'
import {getLang, createOrUpdateQueryParam} from 'utils/stringUtils'

import theme from 'theme'
import App from 'apps/App'
import {isProfileApp} from 'utils/envUtils'
import {NON_GERMAN_BRANDS_LANGUAGES, BRANDS_LANGUAGES} from 'utils/languageUtils'

logger.init()

class Root extends Component {
  constructor() {
    super()
    if (isProfileApp()) {
      this.setDefaultLanguageOnProfileForNonGermanBrands()
    }
  }

  setDefaultLanguageOnProfileForNonGermanBrands = () => {
    const lang = getLang()
    const route = window.location.pathname.slice(1)
    const brand = Object.keys(BRANDS_LANGUAGES).find((b) => b === route)
    const nonGermanBrands = Object.keys(NON_GERMAN_BRANDS_LANGUAGES)

    if (brand && !lang && nonGermanBrands.includes(brand)) {
      const defaultLanguage = NON_GERMAN_BRANDS_LANGUAGES[brand][0]
      createOrUpdateQueryParam('lang', defaultLanguage)
    }
  }

  render() {
    // removed <I18nextProvider i18n={i18n}> by purpose
    // optimization for profile app to load faster. We will load i18n texts later after profile data request is instantiated
    return (
      <ThemeProvider theme={theme}>
        <WebfontLoader config={theme.fontConfig}>
          <App />
        </WebfontLoader>
      </ThemeProvider>
    )
  }
}

export default Root
