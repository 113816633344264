import React from 'react'
import styled from 'styled-components'

import config from 'config'

const CheckBoxIconView = styled.div`
  display: inline-block;
  margin-top: ${(props) => props.theme.checkbox.icon.top};
  margin-right: ${(props) => props.theme.checkbox.icon.right};
  box-sizing: border-box;
  height: ${(props) => props.theme.checkbox.icon.height};
  width: ${(props) => props.theme.checkbox.icon.width};
  min-width: ${(props) => props.theme.checkbox.icon.width};
  cursor: pointer;
`

const StyledCheckBoxIcon = styled.div`
  cursor: pointer;
  background-size: contain;
  background-image: url(${config.url}/assets/${(props) => (props.error ? props.theme.checkbox.icon.errorImage : props.theme.checkbox.icon.image)});
  background-repeat: no-repeat;
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  height: 100%;
  width: 100%;

  &:hover {
    background-image: url(${config.url}/assets/${(props) => props.theme.checkbox.icon.hoverImage});
  }
`

const CheckBoxIcon = ({disabled, error}) => (
  <CheckBoxIconView>
    <StyledCheckBoxIcon disabled={disabled} error={error} />
  </CheckBoxIconView>
)

export {StyledCheckBoxIcon, CheckBoxIconView}

export default CheckBoxIcon
