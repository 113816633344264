import React from 'react'
import get from 'lodash/get'
import {openUrl} from 'utils/browserUtils'
import config from 'config'
import {isProfileApp} from 'utils/envUtils'
import {getLang} from 'utils/stringUtils'
import {getBrandComponents} from 'theme/brands/componentsConfig'
import {ELEMENTS_IDS} from 'const'

import Logo from 'components/Logo'

const getImage = (theme, mobile) => {
  const lang = getLang()

  const imageByLang = get(theme, `shell.header.icon.imageByLang[${lang}]`)
  if (imageByLang) {
    return imageByLang
  }

  const portraitImage = get(theme, `shell.header.icon.portrait.image`)
  if (portraitImage && !mobile) {
    return portraitImage
  }

  return theme.shell.header.icon.image
}

const getLogosByProject = ({theme, src}) => {
  return (
    <Logo
      isProfile={isProfileApp()}
      className={ELEMENTS_IDS.brandLogo}
      src={src}
      onClick={theme.brand.url ? openUrl(theme) : undefined}
    />
  )
}

const HeaderLogo = ({theme, mobile}) => {
  const LogosComponent = getBrandComponents(theme.brand.name).HeaderLogos
  const src = `${config.url}/assets/${getImage(theme, mobile)}`

  return (LogosComponent && <LogosComponent />) || getLogosByProject({theme, src})
}

export default HeaderLogo
