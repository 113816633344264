import styled from 'styled-components'
import ButtonView from './ButtonView'

const ButtonIcon = styled.div`
  margin-left: ${(props) => Object(props.styles).marginLeft || '16px'};
  padding: ${(props) => Object(props.styles).padding || '0'};
  height: ${(props) => props.theme.buttons.icon.height};
  width: ${(props) => props.theme.buttons.icon.width};
  background-image: url(${process.env.PUBLIC_URL}/assets/${(props) => props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => Object(props.styles).background};

  ${ButtonView}:hover & {
    background-image: url(${process.env.PUBLIC_URL}/assets/${(props) => props.hoverIcon || props.icon});
    background-color: ${(props) => Object(props.styles).hoverBackground};
  }
`

export default ButtonIcon
