import ringierComponentsConfig from './ringier'
import ringierelearningComponentsConfig from './ringierelearning'
import sportalConfig from './sportal'
import blikkConfig from './blikk'
import egeszsegkalauzConfig from './egeszsegkalauz'
import glamourConfig from './glamour'
import kiskegyedConfig from './kiskegyed'
import noizzConfig from './noizz'
import ruzsConfig from './ruzs'
import sportalhuConfig from './sportalhu'
import lapotConfig from './lapot'
import blic from './blic'
import superrecepti from './superrecepti'
import sportalsr from './sportalsr'
import zena from './zena'
import pulsonline from './pulsonline'
import ana from './ana'
import nekretnine from './nekretnine'
import blictv from './blictv'
import sportsk from './sportsk'
import abola from './abola'
import sportalgr from './sportalgr'
import gsp from './gsp'
import libertatea from './libertatea'

const componentsConfig = {
  ...ringierComponentsConfig,
  ...ringierelearningComponentsConfig,
  ...sportalConfig,
  ...blikkConfig,
  ...egeszsegkalauzConfig,
  ...glamourConfig,
  ...kiskegyedConfig,
  ...noizzConfig,
  ...ruzsConfig,
  ...sportalhuConfig,
  ...lapotConfig,
  ...blic,
  ...superrecepti,
  ...sportalsr,
  ...zena,
  ...pulsonline,
  ...ana,
  ...nekretnine,
  ...blictv,
  ...sportsk,
  ...abola,
  ...sportalgr,
  ...gsp,
  ...libertatea,
}

const getBrandComponents = (brand) => componentsConfig[brand]

export {getBrandComponents}
