import React from 'react'
import {translate} from 'react-i18next'

import View from 'components/View'
import Title from 'components/Title'
import PageMessage from 'components/PageMessage'
import * as stringUtils from 'utils/stringUtils'

const EmailVerifiedErrorMessage = ({t}) => {
  const firstName = decodeURIComponent(stringUtils.getFirstName(window.location.search) || '')
  const title = firstName ? t('email-not-verified.personal-title', {firstName}) : t('email-not-verified.title')

  return (
    <View>
      <Title>{title}</Title>
      <PageMessage info>{t('email-not-verified.message')}</PageMessage>
    </View>
  )
}

export default translate()(EmailVerifiedErrorMessage)
