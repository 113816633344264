import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Logo from 'components/Logo'

const StaticLogo = styled(Logo)`
  margin-top: 4px;
  height: ${(props) => props.theme.shell.header.icon.height};

  ${breakpoint('portrait')`
    height: ${(props) => props.theme.shell.header.icon.portrait.height};
  `};
`

export default StaticLogo
