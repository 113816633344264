import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const NotificationBarInnerView = styled.div`
  max-width: 1024px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px 8px;

  ${breakpoint('mobile')`
      padding: 20px 20px;
  `};

  ${breakpoint('portrait')`
      padding: 20px 40px;
  `};
`

export default NotificationBarInnerView
