import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const HeaderUserView = styled.div`
  font-size: ${(props) => props.theme.shell.header.user.font.default.size};
  line-height: ${(props) => props.theme.shell.header.user.font.default.height};
  color: ${(props) => props.theme.brand.fontColor || '#f8f8f8'};
  flex-shrink: 0;

  ${breakpoint('portrait')`
    text-align: right;
    margin-left: auto;
    font-size: ${(props) => props.theme.shell.header.user.font.portrait.size};
    line-height: ${(props) => props.theme.shell.header.user.font.portrait.height};
  `};
`

export default HeaderUserView
