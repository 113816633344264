import get from 'lodash/get'
import qs from 'qs'
import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'
import _ from 'lodash'
import {getClient, getConnection, getLang} from 'utils/stringUtils'
import {EVENT_CATEGORY, FORM_STATE} from 'const/googleAnalytics'
import {getDeployEnv} from 'utils/envUtils'
import {getBrand} from 'utils/logger'
import {getOnlyNewsletterTitles, getNewsletterChanges} from 'utils/newsletters'

const lang = getLang()

export const LOGIN_METHODS = {
  SWISSID: 'swissid',
  MICROSOFT: 'microsoft',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  APPLE: 'apple',
  NATIVE: 'native',
  SEAMLESS: 'seamless',
}
export class GoogleAnalyticsService {
  static pushToGTMDataLayer = (event) => {
    try {
      if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = []
      }
      window.dataLayer.push(event)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  static addUnloadListener = () => {
    const exitFunction = () => {
      let shouldFireSuccessfulLoginTag = false
      if (Array.isArray(window.dataLayer)) {
        const event = window.dataLayer.find((element) => element.event === 'nativeLogin')
        const eventTriggeredTimestamp = get(event, 'timestamp') || 0 // 0 ms instead of undefined to avoid NaN below
        const allowedDiff = 10000 // ms

        shouldFireSuccessfulLoginTag = Date.now() - eventTriggeredTimestamp < allowedDiff
      }

      if (shouldFireSuccessfulLoginTag) {
        this.pushToGTMDataLayer({event: 'beforeunload'})

        const timeout = 20 // ms
        setTimeout(() => {}, timeout)
      }
    }

    const eventToListen = 'onpagehide' in window ? 'pagehide' : 'beforeunload'

    window.addEventListener(eventToListen, exitFunction, false)
  }

  static setExternalTrackingCookie = () => {
    const oldValue = Cookies.get('_ext_ga')
    const queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    const {gacid, ga4_sid: ga4sid, ga4_value: ga4value} = queryString

    if (!!gacid && (!oldValue || oldValue !== gacid)) {
      Cookies.set('_ext_ga', gacid, {expires: 100, domain: process.env.REACT_APP_OVERRIDE_DOMAIN})
      Cookies.set('_ga', gacid, {expires: 100, domain: process.env.REACT_APP_OVERRIDE_DOMAIN})
    }

    if (!!ga4sid && !!ga4value) {
      Cookies.set(`_ga_${ga4sid}`, ga4value, {
        expires: 100,
        domain: process.env.REACT_APP_OVERRIDE_DOMAIN,
      })
    }
  }

  static trackLoginSuccess = (userId, method) => {
    const brand = getBrand()
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'login',
      user_id: userId,
      login: {
        method: method || LOGIN_METHODS.NATIVE,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_category: EVENT_CATEGORY.login,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${LOGIN_METHODS.NATIVE}`,
      },
    })
  }

  static trackLoginError = (loginMethod, errorMessage) => {
    const brand = getBrand()
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'login',
      login: {
        method: loginMethod,
        form_state: FORM_STATE.error,
        media_title: brand,
        language: lang,
        error_message: errorMessage,
        event_category: EVENT_CATEGORY.login,
        event_action: 'Error',
        event_label: `${brand}::${lang}::${loginMethod}`,
      },
    })
  }

  static trackSignUpFail = ({isRc, method, errorMessage}) => {
    const brand = getBrand()
    const lang = getLang()
    const eventName = `sign_up${isRc ? '_rc' : ''}`
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method: method || getConnection(window.location.search) || LOGIN_METHODS.SEAMLESS,
        form_state: FORM_STATE.error,
        media_title: brand,
        language: lang,
        error_message: errorMessage,
        event_category: isRc ? EVENT_CATEGORY.registrationRc : EVENT_CATEGORY.registration,
        event_action: 'Fail',
        event_label: `${brand}::${lang}::${LOGIN_METHODS.NATIVE}`,
      },
    })
  }

  static trackSignUp = ({isRc, method}) => {
    const brand = getBrand()
    const lang = getLang()
    const eventName = `sign_up${isRc ? '_rc' : ''}`
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method: method || getConnection(window.location.search) || LOGIN_METHODS.SEAMLESS,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_category: isRc ? EVENT_CATEGORY.registrationRc : EVENT_CATEGORY.registration,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
      },
    })
  }

  static trackSignUpStart = () => {
    const eventName = 'sign_up_start'
    const brand = getBrand()
    const lang = getLang()
    const method = LOGIN_METHODS.NATIVE
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
      },
    })
  }

  static trackSignUpContinue = () => {
    const eventName = 'sign_up_continue'
    const brand = getBrand()
    const lang = getLang()
    const method = LOGIN_METHODS.NATIVE
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
      },
    })
  }

  static trackLoginStart = () => {
    const eventName = 'login_start'
    const brand = getBrand()
    const lang = getLang()
    const method = LOGIN_METHODS.NATIVE
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
      },
    })
  }

  static trackEmailNextClick = (funnel) => {
    const eventName = 'email_next'
    const brand = getBrand()
    const lang = getLang()
    const method = LOGIN_METHODS.NATIVE
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
        funnel,
      },
    })
  }

  static trackSocialLoginNextClick = (method) => {
    const eventName = 'social_next'
    const brand = getBrand()
    const lang = getLang()
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: eventName,
      [eventName]: {
        method,
        form_state: FORM_STATE.success,
        media_title: brand,
        language: lang,
        event_action: 'Success',
        event_label: `${brand}::${lang}::${method}`,
      },
    })
  }

  static trackVirtualPageView = (location, userId) => {
    const brand = getBrand()
    let pagePath = location.pathname
    const clientId = getClient(window.location.search)

    if (pagePath === '/register') {
      const qsParams = qs.parse(location.search, {ignoreQueryPrefix: true})
      const hasEmail = Boolean(qsParams.email)
      const isOnepager = qsParams.is_onepager === 'true'
      const isLazyMigrationOnePager = qsParams.is_lazy_migration_one_pager === 'true'
      pagePath += `?email_prefilled=${hasEmail}&is_onepager=${isOnepager}&is_lazy_migration_one_pager=${isLazyMigrationOnePager}`
    }

    this.pushToGTMDataLayer({
      event: 'virtPageView',
      page_path: pagePath,
      page_type: '--',
      page_title: document.title,
      ga_client_id: Cookies.get('_ga'),
      ...(userId && {user_id: userId}),
      ...(clientId && {client_id: clientId}),
      media_title: brand,
      language: lang,
      environment: getDeployEnv(),
      first_time_signup: '--',
      workflow_info: '--',
      migration: '--',
    })
  }

  static trackNewsletterSubscriptionSuccess = (method, newsletterList, brand) => {
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'subscribe_newsletter',
      subscribe_newsletter: {
        method,
        media_title: brand,
        newsletter_list: getOnlyNewsletterTitles(newsletterList),
        form_state: FORM_STATE.success,
      },
    })
  }

  static trackNewsletterUnsubscriptionSuccess = (method, newsletterList, brand) => {
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'unsubscribe_newsletter',
      unsubscribe_newsletter: {
        method,
        media_title: brand,
        newsletter_list: getOnlyNewsletterTitles(newsletterList),
        form_state: FORM_STATE.success,
      },
    })
  }

  static trackNewsletterSubscriptionFail = (method, newsletterList, brand) => {
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'subscribe_newsletter',
      subscribe_newsletter: {
        method,
        media_title: brand,
        newsletter_list: getOnlyNewsletterTitles(newsletterList),
        form_state: FORM_STATE.error,
      },
    })
  }

  static trackNewsletterUnsubscriptionFail = (method, newsletterList, brand) => {
    this.pushToGTMDataLayer({
      event: 'gaEvent',
      event_name: 'unsubscribe_newsletter',
      unsubscribe_newsletter: {
        method,
        media_title: brand,
        newsletter_list: getOnlyNewsletterTitles(newsletterList),
        form_state: FORM_STATE.error,
      },
    })
  }

  static checkSubscriptionsForGA = (brand, newNewsletters, initialNewsletters, success = true) => {
    // Supports all different newsletters models: { ... }, [], { [brand]: {} }
    const initialBrandNewsletters = _.get(initialNewsletters, brand, initialNewsletters)
    const newBrandNewsletters = _.get(newNewsletters, brand, newNewsletters)
    const isFromProfile = !!initialBrandNewsletters && Object.keys(initialBrandNewsletters).length > 0

    if (newBrandNewsletters && !Array.isArray(newBrandNewsletters)) {
      const {subscriptionList, unsubscriptionList} = getNewsletterChanges(newBrandNewsletters, initialBrandNewsletters)
      const method = isFromProfile
        ? GoogleAnalyticsService.NEWSLETTER_METHODS.PROFILE
        : GoogleAnalyticsService.NEWSLETTER_METHODS.SIGN_UP

      if (subscriptionList.length > 0) {
        if (success) {
          GoogleAnalyticsService.trackNewsletterSubscriptionSuccess(method, subscriptionList, brand)
        } else {
          GoogleAnalyticsService.trackNewsletterSubscriptionFail(method, subscriptionList, brand)
        }
      }
      if (isFromProfile && unsubscriptionList.length > 0) {
        if (success) {
          GoogleAnalyticsService.trackNewsletterUnsubscriptionSuccess(method, unsubscriptionList, brand)
        } else {
          GoogleAnalyticsService.trackNewsletterUnsubscriptionFail(method, unsubscriptionList, brand)
        }
      }
    }
  }
}

GoogleAnalyticsService.SIGN_EVENT = {
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
}

GoogleAnalyticsService.NEWSLETTER_METHODS = {
  PROFILE: 'profile tick box',
  SIGN_UP: 'registration form tick box',
}

GoogleAnalyticsService.WEBAUTH_EVENTS = {ACTIVATE: 'activate', POSTPONE: 'postpone', REJECT: 'reject'}

export default GoogleAnalyticsService
