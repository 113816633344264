import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'
import {renderMessage} from 'utils/reactUtils'

import NotificationBarView from '../notification-bar/NotificationBarView'
import NotificationBarInnerView from '../notification-bar/NotificationBarInnerView'
import NotificationBarMobileView from '../notification-bar/NotificationBarMobileView'
import NotificationBarMessage from '../notification-bar/NotificationBarMessage'
import NotificationBarIcon from '../notification-bar/NotificationBarIcon'
import NotificationBarCloseIcon from '../notification-bar/NotificationBarCloseIcon'
import types from '../notification-bar/notificationBarTypes'

class NotificationBar extends React.Component {
  render() {
    let {
      notification: {message = '', show = false, type = types.error, isDismissable} = {},
      hideNotification,
      style,
      id,
    } = this.props

    message = renderMessage(message, {onClose: hideNotification}, true)

    return (
      <div style={{...style, zIndex: 1}}>
        <NotificationBarView type={type} isOpen={show}>
          <NotificationBarInnerView id={id}>
            <NotificationBarIcon type={type} />
            <NotificationBarMobileView>
              <NotificationBarMessage>
                <StyledMarkdown linkTarget={'_blank'}>{message}</StyledMarkdown>
              </NotificationBarMessage>
            </NotificationBarMobileView>
            {isDismissable && <NotificationBarCloseIcon onClick={hideNotification} />}
          </NotificationBarInnerView>
        </NotificationBarView>
      </div>
    )
  }
}

export {NotificationBar as PureNotificationBar}

export default NotificationBar

const StyledMarkdown = styled(Markdown)`
  p {
    margin: 0;
    a {
      color: inherit;
    }
  }
`
