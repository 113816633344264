import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import get from 'lodash/get'

const getFooter = (props) => props.theme.shell.footer
const getColor = (props) => getFooter(props).color
const getFontSize = (props) =>
  get(props, 'theme.shell.footer.text.fontSize') || getFooter(props).fontSize || props.theme.font.mobile.size
const getLineHeight = (props) => getFooter(props).lineHeight || props.theme.font.mobile.height
const getFontWeight = (props) => getFooter(props).fontWeight
const getFontFamily = (props) => getFooter(props).family
const getMarginBottom = (props) => getFooter(props).textMarginBottom || '20px;'
const getMarginBottomMobile = (props) => getFooter(props).textMarginBottomMobile || '16px;'

const FooterText = styled.div`
  text-align: center;
  color: ${getColor};
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  margin-bottom: ${getMarginBottomMobile};
  font-weight: ${getFontWeight};
  font-family: ${getFontFamily};

  ${breakpoint('mobile')`
    margin-bottom: ${getMarginBottom};
  `};
`

export default FooterText
