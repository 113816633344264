import styled from 'styled-components'
import ErrorText from 'components/ErrorText'

const CheckBoxError = styled(ErrorText)`
  padding: ${(props) => props.theme.checkbox.errorPadding || '8px 20px'};
  margin: ${(props) => props.theme.checkbox.errorMargin};
  box-sizing: border-box;
  background-color: ${(props) => props.theme.field.error.background} !important;
`

export default CheckBoxError
