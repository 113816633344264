import styled from 'styled-components'

const getMarginLeft = (props) => props.theme.checkbox.group.note.marginLeft
const getMarginTop = (props) => props.theme.checkbox.group.note.marginTop || 0
const getColor = (props) => props.theme.checkbox.group.note.color
const getFontSize = (props) => props.theme.checkbox.group.note.fontSize
const getLineHeight = (props) => props.theme.checkbox.group.note.lineHeight
const getFontWeight = (props) => props.theme.checkbox.group.note.fontWeight

const CheckBoxNote = styled.label`
  margin-left: ${getMarginLeft};
  margin-top: ${getMarginTop};
  color: ${getColor};
  box-sizing: border-box;
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  line-height: ${getLineHeight};
`

export default CheckBoxNote
