import styled from 'styled-components'
import get from 'lodash/get'

const getColor = (props) => props.theme.link.footer
const getFontSize = (props) =>
  get(props, 'theme.shell.footer.link.fontSize') || props.theme.shell.footer.fontSize || props.theme.font.mobile.size
const getLineHeight = (props) => props.theme.shell.footer.lineHeight || props.theme.font.mobile.height
const getMargin = (props) => props.theme.shell.footer.moreSoonMargin || '0 0 4px 0'

const MoreSoon = styled.p`
  color: ${getColor};
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  margin: ${getMargin};
  text-align: center;
`

export default MoreSoon
