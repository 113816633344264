import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Logo from 'components/Logo'

const HeaderRingierLogo = styled(Logo)`
  display: block;
  width: ${(props) => props.theme.shell.header.ringierLogo.portrait.width};
  height: ${(props) => props.theme.shell.header.ringierLogo.portrait.height};

  ${breakpoint('portrait')`
    width: ${(props) => props.theme.shell.header.ringierLogo.default.width};
    height: ${(props) => props.theme.shell.header.ringierLogo.default.height};
  `};
`

export default HeaderRingierLogo
