import urljoin from 'url-join'
import {getInteractionUid} from 'utils/stringUtils'
import {isMobileClient} from 'theme/brands/brandUtils'
import * as envUtils from './envUtils'

export const getBackUrl = () => {
  const interactionUid = getInteractionUid(window.location.search)
  return urljoin(process.env.REACT_APP_AUTH0_ISSUER, `/interaction/${interactionUid}/cancel`)
}

export const getDomain = () => {
  return window.location.hostname.substring(window.location.hostname.indexOf('.') + 1, window.location.hostname.length)
}

export const openUrl = (theme) => {
  const backUrl = theme.brand.url || getBackUrl()

  return backUrl ? () => window.location.assign(backUrl) : undefined
}

export const getLocationSetter = (url) => {
  return !isMobileClient(window) && (() => window.location.assign(url))
}

export const setFavicon = (icon) => {
  if (envUtils.isBrowser()) {
    const element = document.querySelector("link[rel='shortcut icon']")
    if (element) {
      element.href = icon
    }
  }
}

export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  return /android/i.test(userAgent)
}

export const isIOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    userAgent
  )
}

export const getDeviceType = () => {
  if (isMobileClient(window)) {
    return 'native'
  }

  if (isMobile()) {
    return 'mobile'
  }

  return 'desktop'
}
