const PUBLIC_URL = process.env.PUBLIC_URL || ''
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || ''
const CONSENT_URL = process.env.REACT_APP_CONSENT_URL || ''
const BLOB_URL = process.env.REACT_APP_BLOB_URL || ''
const PROJECT = process.env.REACT_APP_PROJECT || 'rcbg'
const isGtmAndOneTrustEnabled = ['True', 'true'].includes(process.env.REACT_APP_IS_GTM_AND_ONETRUST_ENABLED)

const links = {
  about: `${CONSENT_URL}/#/about`,
  support: `${CONSENT_URL}/#/support`,
  impressum: `${CONSENT_URL}/#/impressum`,
  blockedIp: `${CONSENT_URL}/#/blocked-ip`,
  agb: `${CONSENT_URL}/static/agb.html`,
  dsb: `${CONSENT_URL}/static/dsb.html`,
  customerNumber: 'https://www.hznews.ch/service/kontaktieren-sie-unseren-kundenservice',
}

export default {
  url: PUBLIC_URL,
  backendUrl: BACKEND_URL,
  links,
  consentUrl: CONSENT_URL,
  blobUrl: BLOB_URL,
  project: PROJECT,
  isGtmAndOneTrustEnabled,
}
