import styled from 'styled-components'

/*
  Workaround to solve bug with display: flex
  not applied to <button /> in some browsers
  https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
 */

const ButtonChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export default ButtonChildrenWrapper
