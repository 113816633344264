import React from 'react'
import styled, {withTheme} from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import config from 'config'

const PageMessageView = styled.div`
  margin-top: 20px;
  display: flex;

  ${breakpoint('portrait')`
    margin-top: ${(props) => props.theme.notification.pageMessage.marginTop || '40px'};
  `};
`

const PageMessageTextView = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.notification.pageMessage.family || props.theme.notification.family};
  padding: ${(props) => props.theme.notification.pageMessage.textPadding || '12px'};
  border: ${(props) => props.border || `2px solid ${props.color}`};
  border-left: 0;
  border-radius: ${(props) => props.theme.notification.pageMessage.textBorderRadius || '0px'}
  font-size: ${(props) => props.theme.notification.pageMessage.sizeMobile || props.theme.font.size};
  line-height: ${(props) => props.theme.notification.pageMessage.heightMobile || props.theme.font.height};

  ${breakpoint('portrait')`
      padding: ${(props) => props.theme.notification.pageMessage.textPaddingPortait || '20px'};
      font-size: ${(props) => props.theme.notification.pageMessage.size};
      line-height: ${(props) => props.theme.notification.pageMessage.height};
  `};
`

const PageMessageIconView = styled.div`
  background-color: ${(props) => props.color};
  border: ${(props) => props.border};
  border-radius: ${(props) => props.theme.notification.pageMessage.iconBorderRadius || '0px'};
  padding: ${(props) => props.theme.notification.pageMessage.iconPadding || '12px'};
  ${breakpoint('portrait')`
      padding: ${(props) => props.theme.notification.pageMessage.iconPaddingPortrait || '20px'};
  `};
`

const PageMessageIcon = styled.img`
  height: ${(props) => props.theme.notification.icon.height};
  width: ${(props) => props.theme.notification.icon.width};

  ${breakpoint('portrait')`
      height: ${(props) => props.theme.notification.icon.mobile.height};
      width: ${(props) => props.theme.notification.icon.mobile.width};
  `};
`

const chooseIcon = (props) => {
  if (props.error || props.warning) {
    return `${config.url}/assets/${props.theme.notification.icon.imageFunc('error')}`
  }
  if (props.info) {
    return `${config.url}/assets/${props.theme.notification.icon.imageFunc('info')}`
  }
  return `${config.url}/assets/${props.theme.notification.icon.imageFunc('success')}`
}

const chooseColor = (props) => {
  if (props.error) {
    return props.theme.notification.error
  }
  if (props.info) {
    return props.theme.notification.info
  }
  if (props.warning) {
    return props.theme.notification.warning
  }
  return props.theme.notification.success
}

const chooseBorder = (props) => {
  if (props.error) {
    return props.theme.notification.errorBorder
  }
  if (props.info) {
    return props.theme.notification.infoBorder
  }
  if (props.warning) {
    return props.theme.notification.warningBorder
  }
  return props.theme.notification.successBorder
}

const PageMessage = ({children, className, ...props}) => {
  const icon = chooseIcon(props)
  const color = chooseColor(props)
  const border = chooseBorder(props)
  return (
    <PageMessageView className={className}>
      <PageMessageIconView color={color} border={border}>
        <PageMessageIcon src={icon} />
      </PageMessageIconView>
      <PageMessageTextView color={color} border={border}>
        {children}
      </PageMessageTextView>
    </PageMessageView>
  )
}

export default withTheme(PageMessage)
