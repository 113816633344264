import React from 'react'
import styled, {keyframes} from 'styled-components'

const ButtonSpinnerView = styled.div`
  margin: auto;
  display: flex;
  opacity: 1;
  justify-content: center;
`

const scale = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }

  50% {
    transform: scale(1.25);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.4;
  }
`
const ButtonSpinnerDot = styled.div`
  width: 8px;
  height: 8px;
  background: #ffffff;
  border-radius: 36px;
  margin: 4px;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  &:nth-child(1) {
    animation-name: ${scale};
    animation-delay: 0ms;
  }

  &:nth-child(2) {
    animation-name: ${scale};
    animation-delay: 250ms;
  }

  &:nth-child(3) {
    animation-name: ${scale};
    animation-delay: 500ms;
  }
`

const ButtonSpinner = () => {
  return (
    <ButtonSpinnerView>
      <ButtonSpinnerDot />
      <ButtonSpinnerDot />
      <ButtonSpinnerDot />
    </ButtonSpinnerView>
  )
}

export default ButtonSpinner
