import Cookies from 'js-cookie'
import remove from 'lodash/remove'
import {Buffer} from 'buffer/'
import {getHeliosUrlBaseUrlOverride, getInteractionUid, getLang, parseJWT} from 'utils/stringUtils'
import {
  WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY,
  WEB_AUTHN_ACCOUNTS_COOKIE_NAME,
  DOMAIN_BY_ENV,
  BASE_DOMAIN_BY_PROJECT,
} from 'const'
import {fetchRest} from 'utils/asyncUtils'
import {getDeviceName, getBrowserName} from 'utils/deviceUtils'
import config from 'config'

const deployEnv = process.env.REACT_APP_ENV || 'dev'

class WebAuthnService {
  constructor(rawToken) {
    const {sub, credentialUserId, challenge, email} = parseJWT(rawToken)
    this.token = rawToken
    this.sub = sub
    this.credentialUserId = credentialUserId
    this.challenge = challenge
    this.email = email
  }

  createCredential = async () => {
    const publicKey = {
      rp: {
        id: `${DOMAIN_BY_ENV[deployEnv]}${BASE_DOMAIN_BY_PROJECT[config.project]}`,
        name: 'Ringier Connect',
        icon: `https://consent.${DOMAIN_BY_ENV[deployEnv]}${BASE_DOMAIN_BY_PROJECT[config.project]}/assets/favicon.ico`,
      },
      user: {
        id: new TextEncoder().encode(this.credentialUserId),
        name: this.email,
        displayName: this.email,
      },

      challenge: new TextEncoder().encode(this.challenge),
      pubKeyCredParams: [
        {type: 'public-key', alg: -7},
        {type: 'public-key', alg: -257},
      ],

      authenticatorSelection: {
        authenticatorAttachment: 'platform',
        requireResidentKey: true,
        userVerification: 'required',
      },
      attestation: 'none',
    }

    return navigator.credentials.create({publicKey})
  }

  setupWebAuthn = async (shouldSetup) => {
    const cookiesDomain = WebAuthnService._getCookiesDomain()

    if (shouldSetup) {
      const credential = await this.createCredential()

      const device = getDeviceName()
      const browser = getBrowserName()

      const createCredentialUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/webauthn/register`
      const {response} = credential

      await fetchRest(
        createCredentialUrl,
        'POST',
        {
          device,
          browser,
          clientDataJSON: {
            type: 'Buffer',
            data: Array.from(new Uint8Array(response.clientDataJSON)),
          },
          authenticatorData: {
            type: 'Buffer',
            data: Array.from(new Uint8Array(response.attestationObject)),
          },
        },
        {
          Authorization: `Bearer ${this.token}`,
        }
      )
      const credentialId = Buffer.from(credential.rawId).toString('hex')

      let credentials = []
      try {
        credentials = JSON.parse(Cookies.get(WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY) || '[]')
        credentials.push(credentialId)
      } catch (e) {
        credentials = [credentialId]
      }

      Cookies.set(WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY, JSON.stringify(credentials), {
        expires: 365 * 100, // expires in 100 years
        path: '',
        domain: cookiesDomain,
      })
    }

    // 4. update cookie
    let accounts = []
    try {
      accounts = JSON.parse(Cookies.get(WEB_AUTHN_ACCOUNTS_COOKIE_NAME) || '[]')
      accounts.push(this.sub)
    } catch (e) {
      accounts = [this.sub]
    }

    Cookies.set(WEB_AUTHN_ACCOUNTS_COOKIE_NAME, JSON.stringify(accounts), {
      expires: 365 * 100, // expires in 100 years
      path: '',
      domain: cookiesDomain,
    })

    // 5. redirect to OIDC to finish current interaction
    const interactionUrl = this._getInteractionUrl()
    const lang = getLang()
    this.post(interactionUrl, {lang})
  }

  setupWebAuthnNextTime = async () => {
    const interactionUrl = this._getInteractionUrl() + '/setupWebAuthnNextTime'
    const lang = getLang()
    this.post(interactionUrl, {lang})
  }

  // Post to the provided URL with the specified parameters.
  post = (path, params) => {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = path

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = encodeURIComponent(params[key])

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  }

  static removeAccountFromCookie = (sub) => {
    const cookiesDomain = WebAuthnService._getCookiesDomain()

    let accounts = []
    try {
      accounts = JSON.parse(Cookies.get(WEB_AUTHN_ACCOUNTS_COOKIE_NAME) || '[]')
      accounts = remove(accounts, (accountId) => accountId === sub)
    } catch (e) {
      return
    }

    Cookies.set(WEB_AUTHN_ACCOUNTS_COOKIE_NAME, JSON.stringify(accounts), {
      expires: 365 * 100, // expires in 100 years
      path: '',
      domain: cookiesDomain,
    })
  }

  static _getCookiesDomain = () => {
    let cookiesDomain
    // case for development on localhost
    if (getHeliosUrlBaseUrlOverride(window.location.search)) {
      cookiesDomain = ''
      // AWS deployment
    } else {
      cookiesDomain = process.env.REACT_APP_AUTH0_DOMAIN.replace('login', '')
    }

    return cookiesDomain
  }

  _getInteractionUrl = () => {
    const baseUrl =
      getHeliosUrlBaseUrlOverride(window.location.search) || `https://${process.env.REACT_APP_AUTH0_DOMAIN}`
    const interactionId = getInteractionUid(window.location.search)
    return `${baseUrl}/interaction/${interactionId}`
  }
}

export default WebAuthnService
