import React from 'react'
import {getLang} from 'utils/stringUtils'
import {GlobalNotificationService} from 'services'

import NotificationBar from './NotificationBar'
import {getClient} from 'theme/brands/brandUtils'

const getCurrentNotification = (notifications, landing) => {
  const landingPageNotification = notifications.find(({isOnlyLandingpage}) => isOnlyLandingpage)
  const globalNotification = notifications.find(({isOnlyLandingpage}) => !isOnlyLandingpage)
  if (landing && landingPageNotification) {
    return landingPageNotification
  }
  if (globalNotification) {
    return globalNotification
  }
  return null
}

const getNotificationMessage = ({messages}) => {
  const lang = getLang()
  const targetLanguageMessage = messages.find(({language}) => language === lang)
  if (targetLanguageMessage && targetLanguageMessage.text) {
    if (targetLanguageMessage.text.includes('$BRAND_NAME')) {
      return targetLanguageMessage.text.replace('$BRAND_NAME', getClient(window))
    }
  }
  return targetLanguageMessage.text
}

const withGlobalNotifications =
  ({landing} = {}) =>
  (WrappedComponent) => {
    return class extends React.Component {
      constructor(props) {
        super(props)
        this.state = {show: false}
        this.service = new GlobalNotificationService()
      }

      async componentDidMount() {
        try {
          const notifications = await this.service.getEnabledNotifications()
          const currentNotification = getCurrentNotification(notifications, landing)
          if (!currentNotification) {
            return
          }

          this.setState({
            type: currentNotification.type,
            message: getNotificationMessage(currentNotification),
            show: true,
            isDismissable: currentNotification.isDismissable,
          })
        } catch {
          // if request fails - do nothing
        }
      }

      hideNotification = () => this.setState({show: false})

      render() {
        return (
          <>
            <NotificationBar
              id={'notification-bar'}
              notification={this.state}
              hideNotification={this.hideNotification}
            />
            <WrappedComponent {...this.props}>{this.props.children}</WrappedComponent>
          </>
        )
      }
    }
  }

export default withGlobalNotifications
