import React from 'react'
import {translate} from 'react-i18next'

import ButtonIcon from './ButtonIcon'
import Text from './ButtonText'
import View from './ButtonView'
import ButtonChildrenWrapper from './ButtonChildrenWrapper'
import ButtonSpinnerDot from './ButtonSpinnerDot'

const Button = ({
  t,
  label,
  children,
  onClick,
  className,
  color,
  hoverColor,
  focusColor,
  activeColor,
  background,
  hoverBackground,
  focusBackground,
  activeBackground,
  border,
  hoverBorder,
  focusBorder,
  activeBorder,
  icon,
  hoverIcon,
  iconStyles,
  textStyles = {},
  disabled,
  full,
  type,
  small,
  id,
  isAnimated,
  loading,
}) => {
  return (
    <View
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={className}
      color={color}
      type={type}
      hoverColor={hoverColor}
      activeColor={activeColor}
      focusColor={focusColor}
      border={border}
      hoverBorder={hoverBorder}
      activeBorder={activeBorder}
      focusBorder={focusBorder}
      background={background}
      hoverBackground={hoverBackground}
      focusBackground={focusBackground}
      activeBackground={activeBackground}
      full={full}
      isAnimated={isAnimated}
      small={small}>
      <ButtonChildrenWrapper>
        {icon && <ButtonIcon color={color} icon={icon} hoverIcon={hoverIcon} styles={iconStyles} />}
        {loading ? (
          <ButtonSpinnerDot />
        ) : (
          <Text icon={icon} styles={textStyles}>
            {t(label || children)}
          </Text>
        )}
      </ButtonChildrenWrapper>
    </View>
  )
}

export {Button as PureButton}

export default translate()(Button)
