import omitBy from 'lodash/omitBy'
import i18n from 'i18n-client'
import {BRANDS} from 'const'
import {getLang} from './stringUtils'
import config from 'config'

const {links} = config

export const isEn = () => i18n.language === 'en-US'

export const isDe = () => i18n.language === 'de-DE'

const lang = getLang()

// first language assigned as default
export const BRANDS_LANGUAGES = {
  [BRANDS.ringier]: ['sr', 'en'],
  [BRANDS.blic]: ['sr', 'en'],
  [BRANDS.sportsk]: ['sk', 'en'],
}

export const NON_GERMAN_BRANDS_LANGUAGES = omitBy(BRANDS_LANGUAGES, ([defaultLanguage]) => defaultLanguage === 'sr')

const getTranslationLanguage = (languages = [], defaultLanguage) => {
  const pageLanguage = getLang()
  const knownLanguage = languages.find((lang) => lang === pageLanguage)
  return knownLanguage || defaultLanguage
}

export const getAllowedT = (brand, t) => {
  const languages = BRANDS_LANGUAGES[brand]
  if (languages && languages.length) {
    const [defaultLanguage] = languages
    const fixedLanguage = getTranslationLanguage(languages, defaultLanguage)
    return i18n.getFixedT(fixedLanguage)
  }
  return t
}

export const getGeneralOrAllowedT = (brand, t, needToUseAllowedT) => {
  if (needToUseAllowedT) {
    return getAllowedT(brand, t)
  }
  return t
}

export const getGeneralOrAllowedLang = (brand, needToUseAllowedLang) => {
  if (needToUseAllowedLang) {
    const languages = BRANDS_LANGUAGES[brand]
    if (languages && languages.length) {
      const [defaultLanguage] = languages
      return getTranslationLanguage(languages, defaultLanguage)
    }
  }
  return getLang()
}

export const getAboutLink = () => links.about.replace('#/', `?lang=${lang}#/`)

export const getImpressumLink = () => links.impressum.replace('#/', `?lang=${lang}#/`)

export const getSupportLink = () => links.support.replace('#/', `?lang=${lang}#/`)

export const getAgbLink = () => (lang !== 'de' ? links.agb.replace('/static', `/static/${lang}`) : links.agb)

export const getDsbLink = () => (lang !== 'de' ? links.dsb.replace('/static', `/static/${lang}`) : links.dsb)
