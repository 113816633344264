import {injectGlobal} from 'styled-components'
import styledNormalize from 'styled-normalize'
import * as envUtils from 'utils/envUtils'
import {setFavicon} from 'utils/browserUtils'
import {getTheme} from './brands/brandUtils'

const brandTheme = getTheme(envUtils.isBrowser() ? window : null)

setFavicon(brandTheme.brand.favicon)

const theme = {
  ...brandTheme,
  breakpoints: {
    smallMobile: 0,
    middleMobile: 400,
    mobile: 481,
    portrait: 768,
    landscape: 1024,
    desktop: 1440,
  },
}

injectGlobal`
   ${styledNormalize}

  body {
    font-family: ${theme.font.family};
    font-weight: ${theme.font.fontWeight};
    color: ${theme.font.color.dark};
    background-color: ${theme.backgroundColor || '#FFFFFF'}
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
     color: ${theme.font.color.dark};
  }

  html {
    font-size:  ${theme.font.size};
    line-height: ${theme.font.height};
  }

   @media (min-width: ${theme.breakpoints.mobile}px){
      html {
        font-size:  ${theme.font.mobile.size};
        line-height: ${theme.font.mobile.height};
      }
   }

   @media (min-width: ${theme.breakpoints.portrait}px){
      html {
        font-size:  ${theme.font.portrait.size};
        line-height: ${theme.font.portrait.height};
      }
   }
`

export default theme
