import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import {Link as RouterLink} from 'react-router-dom'

const Link = ({external, to, inheritColor, block, noUnderline, secondary, agreement, info, ...props}) => {
  return external ? (
    <a href={to} {...props}>
      {props.children}
    </a>
  ) : (
    <RouterLink {...props} to={to} />
  )
}

const getColor = (props) => {
  if (props.inheritColor) {
    return 'inherit'
  }

  if (props.secondary) {
    return props.theme.link.secondary
  }

  if (props.info) {
    return props.theme.link.infoColor
  }

  if (props.agreement) {
    return props.theme.link.agreement
  }

  return props.theme.link.default
}

const getHoverColor = (props) => {
  if (props.inheritColor) {
    return 'inherit'
  }

  if (props.secondary) {
    return props.theme.link.secondaryHover
  }

  if (props.info) {
    return props.theme.link.infoHoverColor
  }

  if (props.agreement && props.theme.link.agreementHover) {
    return props.theme.link.agreementHover
  }

  return props.theme.link.default
}

const getDisplay = (props) => props.block && 'table !important'
const getPaddingTop = (props) => props.block && '8px'
const getTextDecoration = (props) => (props.noUnderline ? 'none' : 'underline')
const getHoverTextDecoration = (props) => props.theme.link.textDecorationHover || getTextDecoration(props)
const getFontSize = (props) => props.fontSize || props.theme.secondaryFont.mobile.size
const getPortraitFontSize = (props) => props.fontSize || 'inherit'

const StyledLink = styled(Link)`
  display: ${getDisplay};
  padding-top: ${getPaddingTop};
  cursor: pointer;
  text-decoration: ${getTextDecoration};
  font-size: ${getFontSize};
  color: ${getColor};

  &:hover {
    color: ${getHoverColor};
    text-decoration: ${getHoverTextDecoration};
  }

  ${breakpoint('portrait')`
      font-size: ${getPortraitFontSize};
  `};
`

export default StyledLink
