import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import {parseJWT, getToken, getLoginCase, getInteractionUid} from 'utils/stringUtils'
import {isProfileApp, isConsentApp, isLoginApp, isBrowser} from 'utils/envUtils'
import {getClient} from 'theme/brands/brandUtils'
import {isEmpty} from 'utils/objectUtils'
import {BRANDS} from 'const'

export const getBrand = () => {
  if (isConsentApp() || isLoginApp()) {
    return getClient(isBrowser() ? window : null)
  }
  if (isProfileApp()) {
    const brand = isBrowser() ? window.location.pathname.substring(1) : null
    if (brand === 'global.profile') return 'ringier'
    return Object.keys(BRANDS).includes(brand) ? brand : undefined
  }
}

const extractLoginCase = () => {
  if (isConsentApp() || isLoginApp()) {
    return getLoginCase(window.location.search)
  }
}

const extractRoute = () => get(window, 'location.pathname')

const getExtras = (info = {}) => {
  const brand = getBrand()
  const loginCase = extractLoginCase()
  const route = extractRoute()
  let extras = {}

  if (brand) extras.brand = brand
  if (loginCase) extras.loginCase = loginCase
  if (route) extras.route = route
  if (!isEmpty(info)) extras = {...extras, ...info}

  return !isEmpty(extras) ? extras : null
}

export const init = () => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    try {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_KEY,
        environment: process.env.REACT_APP_ENV,
        release: process.env.REACT_APP_SENTRY_RELEASE_NAME,
        denyUrls: [/googletagmanager\.com/i],
        beforeSend(event) {
          if (event.user) {
            delete event.user.email
          }
          return event
        },
        ignoreErrors: [
          // If user navigates or refreshes page during XHR (https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/3)
          'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.',
          'TypeError: Cancelled',
          'TypeError: cancelled',
          'TypeError: Abgebrochen',
          'TypeError: annulé',
          'TypeError: avbrutt',
          /Loading chunk \d+ failed/,
        ],
      })
    } catch {}

    Sentry.configureScope((scope) => {
      scope.setTag('interactionId', getInteractionUid(window.location.search))
    })

    try {
      const token = getToken(window.location.hash)
      if (token) {
        const payload = parseJWT(token)
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: payload.sub,
            email: payload.email,
          })
        })
      }
    } catch {
      // :)
    }
  }
}

// TODO think about PII https://docs.sentry.io/data-management/sensitive-data/
export const error = (error, info) => {
  if (process.env.REACT_APP_SENTRY_KEY) {
    Sentry.withScope((scope) => {
      const extras = getExtras(info)
      extras && scope.setExtras(extras)
      Sentry.captureException(error)
    })
  }
}
