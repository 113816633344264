import React from 'react'
import i18n from 'i18n-client'
import Link from 'components/Link'
import {ELEMENTS_IDS} from 'const'
import * as logger from 'utils/logger'

export const UserExist = ({email, onForgetPassword}) => (
  <div>
    {i18n.t('auth-errors.user_exists.message')}
    <Link inheritColor block to={`/login?email=${email}`}>
      {i18n.t('auth-errors.user_exists.continue')}
    </Link>
    <Link external inheritColor block onClick={onForgetPassword}>
      {i18n.t('auth-errors.user_exists.password')}
    </Link>
  </div>
)

// Light version of UserExist which only contains a link to brand main page
export const UserExistForConsent = ({brandUrl}) => (
  <div>
    {i18n.t('auth-errors.user_exists.message')}
    <Link inheritColor external block to={brandUrl}>
      {i18n.t('auth-errors.user_exists.link')}
    </Link>
  </div>
)

export const UserExistMessage = () => <>{i18n.t('auth-errors.user_exists.message')}</>

export const InvalidUserPassword = ({onForgetPassword}) => (
  <div id={ELEMENTS_IDS.loginPage.nativeLoginError}>
    {i18n.t('auth-errors.invalid_user_password.message')}
    <br />
    <Link external inheritColor block onClick={onForgetPassword}>
      {i18n.t('auth-errors.invalid_user_password.link')}
    </Link>
  </div>
)

export const HTTP_ERRORS = {
  BAD_REQUEST: 400,
  CONFLICT: 409,
  TOO_MANY_REQUESTS: 429,
  UNPROCESSABLE_ENTITY: 422,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_ACCEPTABLE: 406,
}

export const getErrorMessage = (error) => {
  switch (true) {
    case error.status === HTTP_ERRORS.CONFLICT: {
      return UserExist
    }
    case error.status === HTTP_ERRORS.FORBIDDEN && error.message === 'email is blocked': {
      return 'auth-errors.too_many_attempts'
    }
    case error.status === HTTP_ERRORS.BAD_REQUEST && error.message === 'invalid captcha': {
      return 'notification.captcha-error'
    }
    default: {
      return 'notification.error'
    }
  }
}

export const catchError = (ErrorComponent) => (ChildComponent) => {
  return class ErrorHandler extends React.Component {
    constructor(props) {
      super(props)
      this.state = {hasError: false}
    }

    render() {
      if (this.state.hasError) {
        return ErrorComponent ? <ErrorComponent /> : null
      }
      return <ChildComponent {...this.props} />
    }

    componentDidCatch(error, info) {
      logger.error(error, info)
      this.setState({hasError: true})
    }
  }
}
