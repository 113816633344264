const ELEMENTS_IDS = {
  logisterPage: {
    swissIdLogin: 'swiss-id-login-btn',
    facebookLogin: 'facebook-login-btn',
    googleLogin: 'google-login-btn',
    microsoftLogin: 'microsoft-login-btn',
    swissIdInfo: 'swiss-id-info',
    appleLogin: 'apple-login-btn',
    backToBrand: 'back-to-brand',
    continue: 'first-step-continue-btn',
    register: 'first-step-register',
    moreInfo: 'more-info', // class name
    biometricsLogin: 'biometrics-login',
  },
  loginPage: {
    nativeLoginError: 'native-login-error-message',
    loginNative: 'native-login-btn',
  },
  forgetPasswordCaptchaPage: {
    submit: 'forget-password-submit-btn',
    cancel: 'forget-password-cancel-btn',
  },
  forgetPassword: 'forget-password-btn',
  findEmail: 'find-email-btn',
  registerNative: 'native-register-btn',
  consentRegister: 'consent-register-btn',
  consentSupport: 'consent-support-btn',
  brandLogo: 'brand-logo', // class name
  languageSwitcher: 'language-switcher', // class name
  emailVerificationPage: {
    resendEmail: 'resend-email',
    continueToLogin: 'continue-to-login',
    submitCaptcha: 'submit-captcha-btn',
    cancelCaptcha: 'cancel-captcha-btn',
  },
  setNewPassword: 'set-new-password-btn',
  profilePage: {
    logout: 'logout-btn',
    resetPassword: 'reset-password-btn',
    updateProfile: {
      general: 'update-general-tab-btn',
      blic: 'update-blic-tab-btn',
      sportal: 'update-sportal-tab-btn',
      abola: 'update-abola-tab-btn',
    },
  },
  footer: {
    impressum: 'impressum',
    dsb: 'dsb',
    agb: 'agb',
    support: 'support',
    about: 'about',
    cookiesetting: 'cookiesetting',
  },
  webauthn: {
    yes: 'webauthn-yes-btn',
    no: 'webauthn-no-btn',
    never: 'webauthn-never-btn',
  },
  mfa: {
    expiredToken: 'mfa-expired-token-cancel-setup-btn',
    returnToProfile: 'mfa-return-to-profile-btn',
    setupCancel: 'mfa-setup-cancel', // class name
    totpActivate: 'mfa-totp-activate-btn',
    totpDeactivate: 'mfa-totp-deactivate-btn',
    totpStart: 'mfa-totp-start-btn',
    totpSetup: 'mfa-totp-setup-btn',
    smsActivate: 'mfa-sms-activate-btn',
    smsDeactivate: 'mfa-sms-deactivate-btn',
    smsSetupSubmitMobileNumber: 'mfa-sms-setup-submit-mobile-number-btn',
    smsSetupSubmitVerificationCode: 'mfa-sms-setup-submit-verification-code-btn',
    recoveryCodesGenerate: 'mfa-recovery-codes-generate-btn',
    recoveryCodesViewed: 'mfa-recovery-codes-viewed-btn',
    verificationCancel: 'mfa-verification-cancel-btn',
    verificationVerify: 'mfa-verification-verify-btn',
  },
  avatar: {
    upload: 'avatar-upload',
    edit: {
      save: 'avatar-edit-save',
      cancel: 'avatar-edit-cancel',
    },
    preview: {
      close: 'avatar-preview-close',
    },
  },
}

export {ELEMENTS_IDS}
