import styled from 'styled-components'

const getFontFamily =
  () =>
  ({theme}) =>
    theme.font.boldFamily
const getFontWight =
  () =>
  ({theme}) =>
    theme.font.boldFontWeight || 500

const Bold = styled.b`
  font-family: ${getFontFamily};
  font-weight: ${getFontWight};
`

export default Bold
