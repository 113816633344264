import styled from 'styled-components'
import breakpoint, {map} from 'styled-components-breakpoint'
import Button from './button'
import Title from '../../../../../apps/hosted-login/common/FormTitle'

const getColor = (props) => props.theme.logisterSection.noteColor
const getDefaultFontSize = (props) => props.theme.logisterSection.noteFontSize.default
const getPortraitFontSize = (props) => props.theme.logisterSection.noteFontSize.portrait
const getLogisterButtonWidth = (props) => props.theme.logisterSection.socialButtonWidth

const LogisterFormTitleNote = styled.div`
  margin: -4px 0 20px;
  font-size: ${getDefaultFontSize};
  line-height: 20px;
  color: ${getColor};

  ${breakpoint('portrait')`
      font-size: ${getPortraitFontSize};
  `};
`

const LogisterPageView = styled.div`
  padding: 16px 20px 20px;
  margin: auto;

  ${breakpoint('mobile')`
      width: 82.5%
      padding: 16px 20px 0;
  `};

  ${breakpoint('portrait')`
      width: calc(100% - 80px);
      padding: 20px 40px 20px;
  `};
`

const LogisterPageButton = styled(Button)`
  :not(:last-of-type) {
    margin-bottom: 20px;
  }
  width: ${getLogisterButtonWidth};
`

const getFontSize = (props) => map(props.theme.logisterSection.title.fontSize, (val) => `font-size: ${val};`)
const getLineHeight = (props) => map(props.theme.logisterSection.title.lineHeight, (val) => `line-height: ${val};`)

const LogisterPageTitle = styled(Title)`
  ${getFontSize};
  ${getLineHeight};
`

const getTextChangeBreakpoint = (props) => props.theme.logisterSection.title.textChangeBreakpoint

const LogisterPageTitleShort = styled(LogisterPageTitle)`
  display: block;

  ${(props) => breakpoint(getTextChangeBreakpoint(props))`
    display: none;
  `};
`

const LogisterPageTitleLong = styled(LogisterPageTitle)`
  display: none;

  ${(props) => breakpoint(getTextChangeBreakpoint(props))`
    display: block;
  `};
`

export {LogisterFormTitleNote, LogisterPageView, LogisterPageButton, LogisterPageTitleShort, LogisterPageTitleLong}
