import React, {Component, Suspense} from 'react'
import {withRouter, Route, Switch, Redirect} from 'react-router-dom'
import {translate} from 'react-i18next'
import get from 'lodash/get'
import {catchError} from 'utils/errorUtils'
import {compose} from 'utils/reactUtils'
import {getToken, getParamFromQuery} from 'utils/stringUtils'
import * as envUtils from 'utils/envUtils'
import {getClient} from 'theme/brands/brandUtils'
import {ConsentService, NewslettersService} from 'services'
import Loading from 'components/loading'
import NotFound from 'components/not-found'
import ScrollToTop from 'components/ScrollToTop'

import UnexpectedError from 'components/unexpected-error'
import {withNotificationBarState} from 'components/notification-bar'
import {EmailVerification, About, NewMainForm} from './ConsentAppAsyncRoutes'
import {withGlobalNotifications} from 'components/global-notification-bar'

const token = getToken(window.location.hash)
const brand = getClient(envUtils.isBrowser() ? window : null)

class ConsentAppPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
    this.newslettersService = new NewslettersService(token, brand)
  }

  async componentDidMount() {
    this.consentService = new ConsentService(token)
    if (token) {
      try {
        this.payload = await this.consentService.getUsefulPayload()
      } catch (e) {
        if (e.status === 404) {
          this.props.history.push('/about')
          return
        } else {
          throw e
        }
      }
    }
    this.setState({loading: false})

    if (
      !this.isInputRequired() &&
      this.payload &&
      this.payload.requiresEmailVerification &&
      this.props.history.location.pathname !== '/email'
    ) {
      this.props.history.push('/email')
    }
    this.trackHandler()
  }

  trackUserRegistration() {
    if (this.payload) {
      const trackerPayload = {
        email: this.payload.email,
        gender: get(this.payload, 'userData.gender.value'),
        zipCode: get(this.payload, 'userData.zipCode.value'),
        city: get(this.payload, 'userData.city.value'),
        country: get(this.payload, 'userData.contry.value'),
      }
      window.rp('trackUserRegistration', trackerPayload)
    }
  }

  trackHandler() {
    if (window.rp) {
      this.trackUserRegistration()
    } else {
      const intervalId = setInterval(() => {
        if (window.rp) {
          clearInterval(intervalId)
          this.trackUserRegistration()
        }
      }, 100)
    }
  }

  isInputRequired = () =>
    this.payload &&
    (this.payload.requiresData ||
      this.payload.requiresAgreements ||
      this.payload.requiresAdmeiraOptIn ||
      this.payload.requiresMigration)

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    const {showNotification} = this.props

    return (
      <ScrollToTop>
        <Suspense fallback="">
          <Switch>
            <Route
              path="/"
              exact
              render={() =>
                this.payload ? (
                  <NewMainForm
                    showNotification={showNotification}
                    fetchNewsletters={this.newslettersService.fetchNewsletters}
                    linkingOnly={!this.isInputRequired()}
                    payload={this.payload}
                    service={this.consentService}
                    brand={brand}
                  />
                ) : (
                  <About />
                )
              }
            />
            <Route
              path="/email"
              exact
              render={() =>
                this.payload && getParamFromQuery('interaction', window.location.search) ? (
                  <EmailVerification
                    showNotification={showNotification}
                    service={this.consentService}
                    connection={!this.isInputRequired() && this.payload.connection}
                    email={this.payload.email}
                    sub={this.payload.sub}
                    linking={this.payload.linking}
                  />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route exact path="/_=_" render={() => <Redirect to="/" />} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    )
  }
}

export {ConsentAppPage as PureConsentAppPage}

export default compose(
  catchError(UnexpectedError),
  withNotificationBarState({header: true}),
  withGlobalNotifications(),
  translate(),
  withRouter
)(ConsentAppPage)
