import HostedLoginService from './HostedLoginService'
import ConsentService from './ConsentService'
import ProfileAuthService from './ProfileAuthService'
import ProfileUpdateService from './ProfileUpdateService'
import ProfileDeletionService from './ProfileDeletionService'
import ProfileDataDownloadService from './ProfileDataDownloadService'
import WebAuthnService from './WebAuthnService'
import MfaService from './MfaService'
import CaptchaService from './CaptchaService'
import GlobalNotificationService from './GlobalNotificationService'
import GameService from './GameService'
import NewslettersService from './NewslettersService'
import RedirectNotificationService from './RedirectNotificationService'
import UploadService from './UploadService'

export {
  HostedLoginService,
  ConsentService,
  ProfileAuthService,
  ProfileUpdateService,
  ProfileDeletionService,
  ProfileDataDownloadService,
  WebAuthnService,
  CaptchaService,
  GlobalNotificationService,
  MfaService,
  GameService,
  NewslettersService,
  RedirectNotificationService,
  UploadService,
}
