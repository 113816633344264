import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Title from 'components/Title'

const StyledTitle = styled(Title)`
  margin: ${(props) => props.theme.registerPage.titleMargin.default};

  ${breakpoint('portrait')`
    margin: ${(props) => props.theme.registerPage.titleMargin.portrait};
  `};
`
export default StyledTitle
