import {fetchRest} from 'utils/asyncUtils'
import config from 'config'

class GlobalNotificationService {
  getEnabledNotifications = async () => {
    return fetchRest(`${config.backendUrl}/v2/notifications?enabled=true`, 'GET', null, {})
  }
}

export default GlobalNotificationService
