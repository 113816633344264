import styled from 'styled-components'
import {translate} from 'react-i18next'
import React from 'react'

const getMarginBottom = (props) => {
  const styles = props.theme.loginForm.forgetLink.wrapper
  return props.nativeLoginError ? styles.errorMarginBottom : styles.defaultMarginBottom
}

const LinkWrapper = styled.div`
  margin-bottom: ${getMarginBottom};
`

const getColor = (props) => props.theme.link.secondary
const getHoverColor = (props) => props.theme.link.secondaryHover
const getLineHeight = (props) => props.theme.secondaryFont.mobile.height
const getMarginTop = (props) => (props.error ? props.theme.loginForm.forgetLink.link.marginTop : '4px')
const getFontSize = (props) => props.theme.loginForm.forgetLink.link.fontSize

const Link = styled.a`
  display: inline-block;
  cursor: pointer;
  color: ${getColor};
  text-decoration-line: underline;
  text-decoration-color: ${getColor};
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  margin-top: ${getMarginTop};

  &:hover {
    color: ${getHoverColor};
    text-decoration-color: ${getHoverColor};
  }
`

const LoginFormForgetLink = ({nativeLoginError, error, t, onClick}) => {
  return (
    <LinkWrapper nativeLoginError={nativeLoginError}>
      {!nativeLoginError && (
        <Link onClick={onClick} error={error}>
          {t('forget.link')}
        </Link>
      )}
    </LinkWrapper>
  )
}

export default translate()(LoginFormForgetLink)
