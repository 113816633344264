import config from 'config'
import {BRANDS} from 'const'
import {getLang} from 'utils/stringUtils'
import {getBannerFooterLogosByProject} from '../commonHelper'

const NORMAL_FONT = "'LatoWebLight', Times New Roman, sans-serif"
const BOLD_FONT = "'LatoWeb', Times New Roman, sans-serif"

const getBrandLinkByLanguage = () => {
  const lang = getLang()
  const aboutLink = (config.links || {}).about || '' // for some reason links property of config is not available during test run
  return aboutLink.replace('#/', `?lang=${lang}#/`)
}

export default {
  brand: {
    name: BRANDS.ringier,
    url: getBrandLinkByLanguage(),
    icon: {
      url: 'logo_Ringier.svg',
      height: '20px',
      portraitHeight: '40px',
      landscapeHeight: '46px',
    },
    favicon: `${config.url}/assets/favicon.ico`,
    fontColor: '#162D46',
  },
  config: {
    chatButton: false,
    separatorLanding: false,
    marketingOnePager: ({loginCase}) => loginCase === 'email_only',
    newsletterOnePager: false,
  },
  fontConfig: {
    custom: {
      urls: [`${config.url}/fonts/ringier/index.css`],
    },
  },
  font: {
    family: NORMAL_FONT,
    boldFamily: BOLD_FONT,
    size: '16px',
    height: '140%',
    boldFontWeight: '400',
    color: {
      dark: '#162D46',
      light: '#ffffff',
    },
    portrait: {
      size: '16px',
      height: '140%',
    },
    mobile: {
      size: '16px',
      height: '140%',
    },
    profileBasic: 'font-size: 16px; line-height: 140%;',
    profileSmall: 'font-size: 14px; line-height: 140%;',
  },
  background: {
    light: '#ffffff',
    dark: ' #47484a',
  },
  ringierBanner: {
    borderRadius: '8px',
    marginRight: '-10px',
    fontSize: '16px',
    lineHeight: '22px',
    benefitsIcon: `/assets/RC-icon-check.svg`,
    backgroundColor: '#F5F5F7',
    linkFontSize: '12px',
    linkColor: '#162D46',
    linkHoverColor: '#007FB6',
    benefitMessageColor: '#162D46',
    iconWidth: '24px',
    iconHeight: '24px',
    landscape: {
      fontSize: '16px',
      subtitleFontSize: '12px',
    },
    titleFontWeight: '400',
    titleFontFamily: BOLD_FONT,
    titleFontSize: '16px',
    onelogTitle: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: '400',
      fontFamily: BOLD_FONT,
    },
  },
  otherBrandsLogos: getBannerFooterLogosByProject(),
  notification: {
    family: '',
    success: '#31B6B3',
    error: '#D35052',
    warning: '#F5AE65',
    pageError: '#D35052',
    info: '#009FE3',
    icon: {
      // actually icon width should be 20px, but icon-{type}.svg contains
      // extra space so we have to compensate it
      width: '24px',
      height: '24px',
      mobile: {
        width: '24px',
        height: '24px',
      },
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `icon-${type}.svg`,
    },
    pageMessage: {
      size: '16px',
      height: '140%',
      sizeMobile: '16px',
      heightMobile: '140%',
      textPadding: '16px',
      textPaddingPortait: '16px',
      borderRadius: '8px',
      textBorderRadius: '0 8px 8px 0',
      iconBorderRadius: '8px 0 0 8px',
      iconPadding: '16px',
      iconPaddingPortrait: '16px',
    },
  },
  customerNumber: {
    marginBottom: '20px',
    padding: '',
    backgroundColor: '',
    helpIcon: {
      img: 'RC-icon-hint.svg',
      hoverImg: 'RC-icon-hint-hover.svg',
      top: '10px',
      right: '14px',
    },
    closeIcon: {
      color: '#878787',
      margin: '2px 1px 0 10px',
    },
    warning: {},
    buttons: {},
    success: {},
  },
  shell: {
    header: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
      hideRingier: true,
      hideRingierOnMobile: true,
      font: {
        color: '#000000',
        size: '12px',
        height: '12px',
        family: NORMAL_FONT,
      },
      height: {
        mobile: '110px',
        portrait: '80px',
        default: '60px',
      },
      padding: '18px 20px',
      color: '#FFFFFF',
      user: {
        font: {
          default: {
            size: '16px',
            height: '140%',
          },
          portrait: {
            size: '16px',
            height: '140%',
          },
        },
        showLoginAs: true,
      },
      icon: {
        image: 'logo-rc.svg',
        height: '28px',
        portrait: {
          height: '40px',
          image: 'logo-rc.svg',
        },
      },
      logoMarginRight: {
        default: '0',
        portrait: '0',
      },
    },
    footer: {
      copyright: {
        fontColor: '#DCDCDC',
        backgroundColor: '#506274',
        darkHover: '#FFFFFF',
        darkFontColor: '#F1F1F1',
        hover: '#000000',
        darkPadding: '12px 0',
      },
      backgroundColor: '#F5F5F7',
      color: '#323232',
      family: BOLD_FONT,
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      logoHeight: '22px',
      brandsInnerViewPadding: '20px 40px 24px',
    },
  },
  title: {
    family: BOLD_FONT,
    size: '32px',
    height: '120%',
    mobile: {
      size: '32px',
      height: '120%',
    },
    portrait: {
      size: '48px',
      height: '120%',
    },
    profile: {
      size: '32px',
      height: '120%',
    },
    fontSize: {
      smallMobile: '32px',
      mobile: '32px',
      portrait: '32px',
      landscape: '48px',
      desktop: '48px',
    },
    lineHeight: {
      smallMobile: '120%',
      mobile: '120%',
      portrait: '120%',
      landscape: '120%',
      desktop: '120%',
    },
  },
  note: {
    lightColor: '#162D46',
    lightFamily: NORMAL_FONT,
    family: BOLD_FONT,
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  buttons: {
    height: '40px',
    border: {
      size: '1px',
      radius: '20px',
    },
    primary: {
      border: '2px solid #009FE3',
      background: '#009FE3',
      color: '#ffffff',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      hoverColor: '#ffffff',
      activeBorder: '2px solid #506274',
      activeBackground: '#009FE3',
      focusBorder: '2px solid #506274',
      full: true,
    },
    secondary: {
      background: '#FFFFFF',
      border: '1px solid #009FE3',
      color: '#009FE3',
      hoverBorder: '1px solid #007FB6',
      hoverColor: '#007FB6',
      hoverBackground: '#FFFFFF',
      focusBorder: '2px solid #506274',
      focusColor: '#009FE3',
      focusBackground: '#FFFFFF',
      activeBorder: '1px solid #009FE3',
      activeColor: '#009FE3',
      activeBackground: '#FFFFFF',
    },
    disabled: {
      color: '#878787',
      hoverColor: '#878787',
      border: '1px solid #DCDCDC',
      background: '#DCDCDC',
      hoverBorder: null,
      hoverBackground: '#DCDCDC',
      activeBorder: null,
      activeBackground: '#DCDCDC',
    },
    profilePrimary: {
      border: '1px solid #162D46',
      background: '#162D46',
      color: '#ffffff',
      hoverBorder: '1px solid #506274',
      hoverBackground: '#506274',
      hoverColor: '#ffffff',
      activeBorder: '1px solid #506274',
      activeBackground: '#506274',
      focusBorder: '2px solid #009FE3',
    },
    profileSecondary: {
      background: '#FFFFFF',
      border: '1px solid #162D46',
      color: '#162D46',
      hoverBorder: '1px solid #506274',
      hoverColor: '#506274',
      activeBorder: '1px solid #506274',
      activeColor: '#506274',
      focusBorder: '2px solid #009FE3',
    },
    icon: {
      height: '36px',
      width: '38px',
    },
    text: {
      family: BOLD_FONT,
      color: '#000000',
      size: '16px',
    },
    facebook: {
      border: '1px solid #8A96A2',
      color: '#162D46',
      background: '#FFFFFF',
      hoverBorder: '1px solid #162D46',
      hoverBackground: '#FFFFFF',
      activeBorder: '1px solid #506274',
      activeBackground: '#FFFFFF',
      focusBorder: '2px solid #506274',
      icon: 'icon-facebook-24x24.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    apple: {
      border: '1px solid #8A96A2',
      color: '#162D46',
      background: '#FFFFFF',
      hoverBorder: '1px solid #162D46',
      hoverBackground: '#FFFFFF',
      activeBorder: '1px solid #506274',
      activeBackground: '#FFFFFF',
      focusBorder: '2px solid #506274',
      icon: 'logo-apple-black.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    google: {
      border: '1px solid #8A96A2',
      color: '#162D46',
      background: '#FFFFFF',
      hoverBorder: '1px solid #162D46',
      hoverBackground: '#FFFFFF',
      activeBorder: '1px solid #506274',
      activeBackground: '#FFFFFF',
      focusBorder: '2px solid #506274',
      icon: 'icon-google-24x24.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    touchId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      icon: 'icon-touchid.svg',
      iconStyles: {marginLeft: '6px', padding: '0'},
      isAnimated: false,
    },
    faceId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      icon: 'icon-faceid.svg',
      iconStyles: {marginLeft: '6px', padding: '0'},
      isAnimated: false,
    },
    anyId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      isAnimated: false,
    },
  },
  logisterForm: {
    border: '',
    background: '',
    padding: '20px 0px 20px 0px',
    landscapePadding: '40px',
    submitButton: 'primary',
    forgetLinkFloat: '',
  },
  registerPage: {
    backLinkMarginBottom: {
      default: '20px',
      portrait: '20px',
    },
    titleMargin: {
      default: '0 0 20px',
      portrait: '0 0 20px',
    },
    submitButtonMarginTop: 20,
  },
  secondaryFont: {
    size: '14px',
    height: '16px',
    portrait: {
      size: '18px',
      height: '24px',
      backlink: '16px',
    },
    mobile: {
      size: '16px',
      height: '22px',
    },
  },
  separator: {
    background: ' #acacac',
    color: '#878787',
    height: '1px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16px',
    padding: '0',
    portraitMargin: '40px',
    overrideMargin: '24px 0 40px 0',
  },
  logisterSection: {
    subheaderPadding: '12px 20px',
    subheaderFontSize: '16px',
    subheaderLineHeight: '24px',
    subheaderTitleColor: '#000000',
    subheaderLinkColor: '#545454',
    subheaderLinkHoverColor: '#FF0000',
    subheaderBackgroundColor: '#F8F8F8',
    noteFontSize: {
      default: '12px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'landscape',
      fontSize: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '48px',
        desktop: '48px',
      },
      lineHeight: {
        smallMobile: '38px',
        mobile: '38px',
        portrait: '38px',
        landscape: '58px',
        desktop: '58px',
      },
    },
    registerMarginTop: '20px',
    registerSize: '16px',
    socialButtonWidth: '100%',
  },
  emailVerifyPage: {
    subtitle: {
      marginTop: '24px',
      marginBottom: '16px',
      fontSize: '20px',
    },
    noteMarginTop: '40px',
    noteMarginBottom: '8px',
    linkMarginBottom: '8px',
    note: {
      fontFamily: BOLD_FONT,
      fontSize: '16px',
    },
  },
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        fontSize: '14px',
        marginTop: '6px',
      },
    },
  },
  form: {
    border: '',
    background: '#f6f6f6',
    padding: '0 0 16px 0',
    fieldBackground: '',
    sectionViewBackground: '#f8f8f8',
    loginSubmitMarginTop: '20',
    marginBottom: '8px',
    note: {
      fontSize: '18px',
    },
  },
  field: {
    error: {
      background: '',
      fontColor: '#D35052',
      size: '14px',
      height: '22px',
      padding: '4px 20px',
    },
    label: {
      color: '',
      disabledColor: '#000000',
      family: BOLD_FONT,
      fontSize: '16px',
      fontHeight: '22px',
      bottomMargin: '8px',
    },
    edging: {
      backgroundColor: '#F5F5F7',
    },
    optional: {
      family: NORMAL_FONT,
      color: '#878787',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      margin: '16px',
    },
  },
  input: {
    border: '1px solid #8A96A2',
    borderRadius: '8px',
    focusBorder: '2px solid #506274',
    errorBorder: '1px solid #E11A1A',
    errorBackground: '#FFFFFF',
    height: '40px',
    email: {
      backgroundColor: '#F5F5F7',
      color: '#162D46',
      fontSize: '18px',
      border: '1px solid #8A96A2',
      borderRadius: '8px',
      overrideBackground: '#FFFFFF',
      button: {
        height: '28px',
        right: '6px',
        backgroundColor: '#FFFFFF',
        color: '#009FE3',
        fontFamily: BOLD_FONT,
        fontSize: '14px',
        textDecoration: 'none',
        border: '1px solid #009FE3',
        borderRadius: '8px',
        hoverColor: '#FFFFFF',
        hoverBackgroundColor: '#009FE3',
        hoverBorder: '1px solid #009FE3',
        padding: '4px 12px',
      },
    },
    password: {
      color: '#162D46',
      hoverColor: '#007FB6',
      size: '14px',
      height: '20px',
      top: '10px',
    },
    loading: {
      top: '3px',
    },
  },
  inputNote: {
    color: '#162D46',
    family: NORMAL_FONT,
    size: '14px',
    height: '20px',
    marginTop: '4px',
  },
  textarea: {
    height: '80px',
  },
  link: {
    default: '#009FE3',
    hoverColor: '#007FB6',
    footer: '#323232',
    align: 'start',
    infoColor: '#009FE3',
    infoHoverColor: '#02628B',
    secondary: '#545454',
    secondaryHover: '#000000',
    agreement: '#000000',
    isAgreementsLink: true,
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      image: 'RC-radio.svg',
      checkedImage: 'RC-radio-active.svg',
      hoverImage: 'RC-radio.svg',
      errorImage: 'RC-radio-error.svg',
      disabledImage: 'RC-radio-disabled.svg',
    },
    errorMargin: '20px -20px -16px',
    labelFontHeight: '25px',
  },
  checkbox: {
    icon: {
      height: '20px',
      width: '20px',
      top: '2px',
      right: '12px',
      image: 'RC-checkbox.svg',
      checkedImage: 'RC-checkbox-checked.svg',
      hoverImage: 'RC-checkbox.svg',
      errorImage: 'RC-checkbox-error.svg',
      focusImage: 'RC-checkbox-focus.svg',
      disabledImage: 'RC-checkbox-disable.svg',
    },
    labelFontHeight: '22px',
    errorMargin: '20px -20px -16px',
    padding: '20px',
    errorPadding: '4px 20px 0',
    group: {
      marginBottom: '',
      padding: '',
      backgroundColor: '',
      fontWeight: '500',
      fontSize: '16px',
      family: BOLD_FONT,
      label: {
        marginBottom: '0',
        padding: '20px 0 0 20px',
        backgroundColor: '#f6f6f6',
      },
      note: {
        fontWeight: '300',
        fontSize: '16px',
        marginLeft: '32px',
        marginTop: '4px',
        lineHeight: '22px',
      },
      portrait: {
        fontSize: '16px',
      },
    },
  },
  select: {
    borderBottomRightRadiusMenuIsOpen: '0',
    borderBottomLeftRadiusMenuIsOpen: '0',
    icon: {
      width: '16px',
      height: '16px',
      image: 'from-arrow-down.svg',
      alt_image: 'from-arrow-down.svg',
    },
    menu: {
      border: '1px solid #545454',
      option: {
        focusBorder: '0',
        focusBackground: '#F1F1F1',
        hoverBackground: '#F5F5F7',
        selectedFontFamily: BOLD_FONT,
      },
      placeholder: {
        color: '#162D46',
      },
    },
  },
  errorPage: {
    font: {
      height: '18px',
      portrait: {
        height: '30px',
      },
    },
  },
  tab: {
    fontSize: '16px',
    desktop: {
      focusBorder: 'solid 4px #162D46',
      bottomMargin: '-4px',
      border: 'solid 4px #DCDCDC',
      height: '70px',
    },
    mobile: {
      fontSize: '20px',
      decoration: 'none',
      icon: {
        border: 'solid 2px #c5cad1',
        width: '14px',
        height: '14px',
        color: 'transparent',
      },
    },
  },
  mobileNumber: {
    code: {
      letterSpacing: '8px',
      paddingLeft: {
        mobile: {
          normal: 'calc(50% - 50px)',
          error: 'calc(50% - 51px)',
        },
        desktop: {
          normal: 'calc(25% - 50px)',
          error: 'calc(25% - 51px)',
        },
      },
      underline: {
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 9px)',
        desktopLeft: 'calc(25% - 19px)',
        desktopTop: 'calc(50% - 12px)',
      },
    },
  },
  phoneNumber: {
    container: {
      backgroundColor: '#FFFFFF',
    },
  },
  languageSwitcher: {
    color: 'white',
    separatorColor: 'rgba(255, 255, 255, 0.6)',
  },
  address: {
    padding: '',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  consent: {
    checkbox: {
      marginBottom: '8px',
      paddingBottom: '16px',
    },
  },
  webauthn: {
    dropdown: {
      color: '#009FE3',
      filter: 'invert(44%) sepia(90%) saturate(2698%) hue-rotate(170deg) brightness(99%) contrast(102%)',
      hoverColor: '#007FB6',
      hoverFilter: 'invert(37%) sepia(50%) saturate(6248%) hue-rotate(178deg) brightness(91%) contrast(101%)',
      fontSize: '16px',
      lineHeight: '22px',
      icon: 'from-arrow-down-blue.svg',
    },
  },
  mfa: {
    subtitleFontSize: '16px',
    subtitleFontFamily: NORMAL_FONT,
    backgroundColor: '#F5F5F7',
  },
  edging: {
    borderRadius: '8px',
  },
  avatar: {
    backgroundColor: '#F5F5F7',
    border: 'none',
    linkButtonColor: '#162D46',
    linkButtonHoverColor: '#162D46',
    modal: {
      zoomInThumbBackground: '#F5F5F7',
    },
  },
}
