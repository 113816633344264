import styled from 'styled-components'

const getBackgroundColor = (props) => props.theme.shell.header.color
const getShadow = (props) => props.theme.shell.header.boxShadow || 'none'
const getBorder = (props) => props.theme.shell.header.border || {}
const getBorderWidth = (props) => getBorder(props).width
const getBorderColor = (props) => getBorder(props).color
const getBorderStyle = (props) => getBorder(props).style

const getBackground = (props) =>
  props.theme.shell.header.background ? `background: ${props.theme.shell.header.background};` : ''

const HeaderView = styled.header`
  ${getBackground}
  background-color: ${getBackgroundColor};
  border-width: ${getBorderWidth};
  border-color: ${getBorderColor};
  border-style: ${getBorderStyle};
  box-shadow: ${getShadow};
  position: relative;
  z-index: 1;
`

export default HeaderView
