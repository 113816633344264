import {CLIENTS} from '../brandUtils'

import SmallNote from '../commonComponents/SmallNote'
import EmailVerifiedSuccessMessage from '../ringier/components/EmailVerifiedSuccessMessage'

export default {
  [CLIENTS.RINGIER]: {
    SmallNote,
    EmailVerifiedSuccessMessage,
  },
}
