import React from 'react'
import {translate} from 'react-i18next'

import {CopyrightView, CopyrightTitle} from './CopyrightView'
import config from 'config'
import {ELEMENTS_IDS} from 'const'
import styled from 'styled-components'

const {links} = config
const ids = ELEMENTS_IDS.footer
const AGREEMENTS = ['agb', 'dsb']

const Copyright = ({t, dark, handleLink, lang}) => {
  const linksWithLang = Object.keys(links)
    .filter((link) => !AGREEMENTS.includes(link))
    .reduce((acc, link) => ({...acc, [link]: links[link].replace('#/', `?lang=${lang}#/`)}), {})
  const agreementsWithLang = AGREEMENTS.reduce(
    (acc, agreement) => ({
      ...acc,
      [agreement]: lang !== 'de' ? links[agreement].replace('/static', `/static/${lang}`) : links[agreement],
    }),
    {}
  )

  const ButtonLink = styled.button`
    background: none;
    border: none;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
    font-family: inherit;
  `

  return (
    <CopyrightView dark={dark}>
      <CopyrightTitle>
        {t('copyright.copyright')}
        <br />
      </CopyrightTitle>
      <a id={ids.impressum} href={linksWithLang.impressum} onClick={handleLink}>
        {t('copyright.policy.1')}
      </a>{' '}
      |{' '}
      <a id={ids.dsb} href={agreementsWithLang.dsb} onClick={handleLink}>
        {t('copyright.policy.2')}
      </a>{' '}
      |{' '}
      <a id={ids.agb} href={agreementsWithLang.agb} onClick={handleLink}>
        {t('copyright.policy.3')}
      </a>{' '}
      |{' '}
      <a id={ids.support} href={linksWithLang.support} onClick={handleLink}>
        {t('copyright.policy.4')}
      </a>{' '}
      |{' '}
      {config.isGtmAndOneTrustEnabled && (
        <span>
          <ButtonLink id={ids.cookiesetting} onClick={() => window.OneTrust && window.OneTrust.ToggleInfoDisplay()}>
            {t('cmp-cookie-settings')}
          </ButtonLink>{' '}
          |{' '}
        </span>
      )}
      <a id={ids.about} href={linksWithLang.about} onClick={handleLink}>
        {t('copyright.policy.5')}
      </a>
    </CopyrightView>
  )
}

export {Copyright as PureCopyright}

export default translate()(Copyright)
