import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getHeight = (props) => (props.small ? '36px' : props.theme.buttons.height)
const getBorder = (props) => props.border
const getBorderRadius = (props) => props.theme.buttons.border.radius
const getCursor = (props) => !props.disabled && 'pointer'
const getBackground = (props) => props.background
const getColor = (props) => props.color
const getHoverBackgroundColor = (props) => props.hoverBackground || props.background
const getHoverColor = (props) => props.hoverColor || props.color
const getHoverBorder = (props) => props.hoverBorder || props.border
const getFocusBackgroundColor = (props) => props.focusBackground || props.background
const getFocusColor = (props) => props.focusColor || props.color
const getFocusBorder = (props) => props.focusBorder || props.border
const getActiveBackgroundColor = (props) => props.activeBackground || props.background
const getActiveColor = (props) => props.activeColor || props.color
const getActiveBorder = (props) => props.activeBorder || props.border
const getPortraitWidth = (props) => (props.full ? '100%' : 'calc(50% - 10px)')

const ButtonView = styled.button`
  height: ${getHeight};
  border: ${getBorder};
  border-radius: ${getBorderRadius};
  cursor: ${getCursor};
  width: 100%;
  padding: 0;
  background: ${getBackground};
  color: ${getColor};
  outline: 0;
  font-size: 16px;

  &:hover {
    background: ${getHoverBackgroundColor};
    color: ${getHoverColor};
    border: ${getHoverBorder};
  }

  &:focus {
    background: ${getFocusBackgroundColor};
    color: ${getFocusColor};
    border: ${getFocusBorder};
  }

  &:active {
    background: ${getActiveBackgroundColor};
    color: ${getActiveColor};
    border: ${getActiveBorder};
  }

  ${breakpoint('portrait')`
     width: ${getPortraitWidth};
  `};
`

export default ButtonView
