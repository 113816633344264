import React from 'react'
import FooterLogos from './footer-logos'
import FooterText from './FooterText'
import FooterView from './FooterView'
import FooterBrandsInnerView from './FooterBrandsInnerView'
import FooterLink from './FooterLink'
import {PureCopyright} from './copyright/Copyright'
import FooterBrandsView from './FooterBrandsView'
import {ELEMENTS_IDS} from 'const'
import theme from 'theme'
import {staticT} from './staticFooterUtils'
import {getAboutLink} from 'utils/languageUtils'

const StaticFooter = (props) => {
  const {footerOpts = {}, staticPageOptions} = props
  const {hideLogosOnPortrait, margin} = footerOpts

  const lang = staticPageOptions.language
  const t = staticT(lang)

  return (
    <FooterView margin={margin}>
      <FooterBrandsInnerView hideLogosOnPortrait={hideLogosOnPortrait}>
        <FooterBrandsView>
          <FooterText>{t('footer-brands')}</FooterText>
          {theme.otherBrandsLogos && <FooterLogos lang={lang} logos={theme.otherBrandsLogos} />}
          <FooterLink className={ELEMENTS_IDS.logisterPage.moreInfo} href={getAboutLink()}>
            {t('footer-info-button')}
          </FooterLink>
        </FooterBrandsView>
      </FooterBrandsInnerView>
      <PureCopyright t={t} dark lang={lang} shouldShowCopyright />
    </FooterView>
  )
}

export {StaticFooter as PureFooter}

export default StaticFooter
