import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getWidth = (props) => (props.icon ? 'calc(100% - 40px)' : '100%')
const getFontFamily = (props) => props.styles.family || props.theme.buttons.text.family
const getFontSize = (props) => props.styles.size || props.theme.buttons.text.size
const getFontWeight = (props) => props.styles.weight || props.theme.buttons.text.weight

const ButtonText = styled.div`
  flex-grow: 1;
  font-family: ${getFontFamily};
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  align-self: center;
  position: absolute;
  width: ${getWidth};
  right: 0;

  ${breakpoint('mobile')`
     width: 100%;
  `};
`

export default ButtonText
