import styled from 'styled-components'

const LogosView = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 8px;
`

export default LogosView
