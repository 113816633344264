import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Link from 'components/Link'

const getBannerTheme = (props) => props.theme.ringierBanner
const getFontSize = (props) => getBannerTheme(props).fontSize
const getLandscapeFontSize = (props) => getBannerTheme(props).landscape.fontSize
const getLineHeight = (props) => getBannerTheme(props).lineHeight
const getTitleFontWeight = (props) => getBannerTheme(props).titleFontWeight
const getTitleFontFamily = (props) => getBannerTheme(props).titleFontFamily
const getTitleFontSize = (props) => getBannerTheme(props).titleFontSize
const getBackgroundColor = (props) => getBannerTheme(props).backgroundColor

const RingierBannerView = styled.div`
  box-sizing: border-box;
  padding: 40px;
  margin-left: 40px;
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  background-color: ${getBackgroundColor};

  ${breakpoint('landscape')`
    font-size: ${getLandscapeFontSize};
    line-height: 24px;
    margin-left: 88px;
  `};
`

const RingierBannerTitle = styled.div`
  font-weight: ${getTitleFontWeight};
  font-family: ${getTitleFontFamily};
  font-size: ${getTitleFontSize};
  margin-bottom: 20px;
`

const RingierBannerBenefit = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: ${getTitleFontSize};
`

const getIcon = (props) => getBannerTheme(props).benefitsIcon
const getIconWidth = (props) => getBannerTheme(props).iconWidth
const getIconHeight = (props) => getBannerTheme(props).iconHeight
const getMessageColor = (props) => getBannerTheme(props).benefitMessageColor
const getLinkColor = (props) => getBannerTheme(props).linkColor
const getLinkHoverColor = (props) => getBannerTheme(props).linkHoverColor
const getLinkFontSize = (props) => getBannerTheme(props).linkFontSize

const RingierBannerBenefitIcon = styled.img.attrs({
  src: getIcon,
})`
  width: ${getIconWidth};
  height: ${getIconHeight};
  padding-right: 10px;
  flex-shrink: 0;
`

const RingierBannerBenefitMessage = styled.div`
  color: ${getMessageColor};
`

const RingierBannerLink = styled(Link)`
  color: ${getLinkColor};
  font-size: ${getLinkFontSize}

  &:hover {
    color: ${getLinkHoverColor};
  }
`

export {
  RingierBannerView,
  RingierBannerTitle,
  RingierBannerBenefit,
  RingierBannerBenefitIcon,
  RingierBannerBenefitMessage,
  RingierBannerLink,
}
