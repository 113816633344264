import config from 'config'
import {BRANDS} from 'const'
import {getLang} from 'utils/stringUtils'
import {getBannerFooterLogosByProject} from '../commonHelper'

const NORMAL_FONT = "'LatoWebLight', Times New Roman, sans-serif"
const BOLD_FONT = "'LatoWeb', Times New Roman, sans-serif"

const getBrandLinkByLanguage = () => {
  const lang = getLang()
  const aboutLink = (config.links || {}).about || '' // for some reason links property of config is not available during test run
  return aboutLink.replace('#/', `?lang=${lang}#/`)
}

export default {
  brand: {
    name: BRANDS.ringier,
    url: getBrandLinkByLanguage(),
    icon: {
      url: 'logo_Ringier.svg',
      height: '20px',
      portraitHeight: '40px',
      landscapeHeight: '46px',
    },
    favicon: `${config.url}/assets/favicon.ico`,
    fontColor: '#FFFFFF',
  },
  config: {
    chatButton: false,
    separatorLanding: false,
    marketingOnePager: ({loginCase}) => loginCase === 'email_only',
    newsletterOnePager: false,
  },
  fontConfig: {
    custom: {
      urls: [`${config.url}/fonts/ringier/index.css`],
    },
  },
  backgroundColor: '#000000',
  captchaTheme: 'dark',
  actionButtonIconPostfix: '-dark-theme',
  font: {
    family: NORMAL_FONT,
    boldFamily: BOLD_FONT,
    size: '16px',
    height: '140%',
    boldFontWeight: '400',
    color: {
      dark: '#FFFFFF',
      light: '#FFFFFF',
    },
    portrait: {
      size: '16px',
      height: '140%',
    },
    mobile: {
      size: '16px',
      height: '140%',
    },
    profileBasic: 'font-size: 16px; line-height: 140%;',
    profileSmall: 'font-size: 14px; line-height: 140%;',
  },
  background: {
    light: '#ffffff',
    dark: ' #47484a',
  },
  ringierBanner: {
    borderRadius: '8px',
    marginRight: '-10px',
    fontSize: '16px',
    lineHeight: '22px',
    benefitsIcon: `/assets/RC-icon-check-dark-theme.svg`,
    backgroundColor: '#222222',
    linkFontSize: '12px',
    linkColor: '#FFFFFF',
    linkHoverColor: '#FFFFFF',
    benefitMessageColor: '#FFFFFF',
    iconWidth: '24px',
    iconHeight: '24px',
    landscape: {
      fontSize: '16px',
    },
    titleFontWeight: '400',
    titleFontFamily: BOLD_FONT,
    titleFontSize: '16px',
    onelogTitle: {
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: '400',
      fontFamily: BOLD_FONT,
    },
  },
  otherBrandsLogos: getBannerFooterLogosByProject(),
  notification: {
    family: '',
    success: '#31B6B3',
    error: '#D35052',
    warning: '#F5AE65',
    pageError: '#D35052',
    info: '#009FE3',
    icon: {
      // actually icon width should be 20px, but icon-{type}.svg contains
      // extra space so we have to compensate it
      width: '24px',
      height: '24px',
      mobile: {
        width: '24px',
        height: '24px',
      },
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `icon-${type}.svg`,
    },
    pageMessage: {
      size: '16px',
      height: '140%',
      sizeMobile: '16px',
      heightMobile: '140%',
      textPadding: '16px',
      textPaddingPortait: '16px',
      borderRadius: '8px',
      textBorderRadius: '0 8px 8px 0',
      iconBorderRadius: '8px 0 0 8px',
      iconPadding: '16px',
      iconPaddingPortrait: '16px',
    },
  },
  customerNumber: {
    marginBottom: '20px',
    padding: '',
    backgroundColor: '',
    helpIcon: {
      img: 'RC-icon-hint.svg',
      hoverImg: 'RC-icon-hint-hover.svg',
      top: '10px',
      right: '14px',
    },
    closeIcon: {
      color: '#878787',
      margin: '2px 1px 0 10px',
    },
    warning: {},
    buttons: {},
    success: {},
  },
  shell: {
    header: {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
      hideRingier: true,
      hideRingierOnMobile: true,
      font: {
        color: '#000000',
        size: '12px',
        height: '12px',
        family: NORMAL_FONT,
      },
      height: {
        mobile: '110px',
        portrait: '80px',
        default: '60px',
      },
      padding: '18px 20px',
      color: '#222222',
      user: {
        font: {
          default: {
            size: '16px',
            height: '140%',
          },
          portrait: {
            size: '16px',
            height: '140%',
          },
        },
        showLoginAs: true,
      },
      icon: {
        image: 'logo-ringierconnect-white.svg',
        height: '28px',
        portrait: {
          height: '40px',
          image: 'logo-ringierconnect-white.svg',
        },
      },
      logoMarginRight: {
        default: '0',
        portrait: '0',
      },
    },
    footer: {
      copyright: {
        fontColor: '#FFFFFF',
        backgroundColor: '#323232',
        darkHover: '#FFFFFF',
        darkFontColor: '#FFFFFF',
        hover: '#FFFFFF',
        darkPadding: '12px 0',
      },
      backgroundColor: '#222222',
      color: '#FFFFFF',
      family: BOLD_FONT,
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      logoHeight: '22px',
      brandsInnerViewPadding: '20px 40px 24px',
    },
  },
  title: {
    family: BOLD_FONT,
    size: '32px',
    height: '120%',
    mobile: {
      size: '32px',
      height: '120%',
    },
    portrait: {
      size: '32px',
      height: '120%',
    },
    profile: {
      size: '32px',
      height: '120%',
    },
    fontSize: {
      smallMobile: '32px',
      mobile: '32px',
      portrait: '32px',
      landscape: '32px',
      desktop: '32px',
    },
    lineHeight: {
      smallMobile: '120%',
      mobile: '120%',
      portrait: '120%',
      landscape: '120%',
      desktop: '120%',
    },
  },
  note: {
    lightColor: '#FFFFFF',
    lightFamily: NORMAL_FONT,
    family: BOLD_FONT,
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  buttons: {
    dotsColor: '#C5CAD1',
    height: '40px',
    border: {
      size: '1px',
      radius: '20px',
    },
    primary: {
      border: '2px solid #009FE3',
      background: '#009FE3',
      color: '#ffffff',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      hoverColor: '#ffffff',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #C5CAD1',
      full: true,
    },
    secondary: {
      background: 'rgba(0, 0, 0, 0)',
      border: '1px solid #009FE3',
      color: '#009FE3',
      hoverBorder: '1px solid #007FB6',
      hoverColor: '#009FE3',
      hoverBackground: '#162D46',
      focusBorder: '2px solid #009FE3',
      focusColor: '#009FE3',
      focusBackground: 'rgba(0, 0, 0, 0)',
      activeBorder: '1px solid #007FB6',
      activeColor: '#009FE3',
      activeBackground: '#162D46',
    },
    disabled: {
      color: '#878787',
      hoverColor: '#878787',
      border: '1px solid #DCDCDC',
      background: '#DCDCDC',
      hoverBorder: null,
      hoverBackground: '#DCDCDC',
      activeBorder: null,
      activeBackground: '#DCDCDC',
    },
    profilePrimary: {
      border: '2px solid #FFFFFF',
      background: '#FFFFFF',
      color: '#162D46',
      hoverBorder: '2px solid #C5CAD1',
      hoverBackground: '#C5CAD1',
      hoverColor: '#162D46',
      activeBorder: '2px solid #C5CAD1',
      activeBackground: '#C5CAD1',
      focusBorder: '2px solid #009FE3',
    },
    profileSecondary: {
      background: 'rgba(0, 0, 0, 0)',
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      hoverBorder: '1px solid #C5CAD1',
      hoverColor: '#FFFFFF',
      hoverBackground: '#323232',
      focusBorder: '2px solid #FFFFFF',
      focusColor: '#FFFFFF',
      focusBackground: 'rgba(0, 0, 0, 0)',
      activeBorder: '1px solid #C5CAD1',
      activeBackground: '#323232',
    },
    profileDisabled: {
      color: '#878787',
      hoverColor: '#878787',
      border: '1px solid #323232',
      background: '#323232',
      hoverBorder: null,
      hoverBackground: '#323232',
      activeBorder: null,
      activeBackground: '#323232',
    },
    icon: {
      height: '36px',
      width: '38px',
    },
    text: {
      family: BOLD_FONT,
      color: '#000000',
      size: '16px',
    },
    facebook: {
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      background: 'rgba(0, 0, 0, 0)',
      hoverBorder: '1px solid #C5CAD1',
      hoverBackground: '#222222',
      activeBorder: '1px solid #C5CAD1',
      activeBackground: '#222222 ',
      focusBorder: '2px solid #8A96A2',
      icon: 'icon-facebook-24x24-dark-theme.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#FFFFFF',
      activeColor: '#FFFFFF',
      isAnimated: false,
      full: true,
    },
    apple: {
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      background: 'rgba(0, 0, 0, 0)',
      hoverBorder: '1px solid #C5CAD1',
      hoverBackground: '#222222',
      activeBorder: '1px solid #C5CAD1',
      activeBackground: '#222222 ',
      focusBorder: '2px solid #8A96A2',
      icon: 'icon-apple-24x24-dark-theme.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#FFFFFF',
      activeColor: '#FFFFFF',
      isAnimated: false,
      full: true,
    },
    google: {
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      background: 'rgba(0, 0, 0, 0)',
      hoverBorder: '1px solid #C5CAD1',
      hoverBackground: '#222222',
      activeBorder: '1px solid #C5CAD1',
      activeBackground: '#222222 ',
      focusBorder: '2px solid #8A96A2',
      icon: 'icon-google-24x24-dark-theme.svg',
      iconStyles: {
        marginLeft: '6px',
      },
      textStyles: {
        weight: 400,
      },
      hoverColor: '#FFFFFF',
      activeColor: '#FFFFFF',
      isAnimated: false,
      full: true,
    },
    touchId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      icon: 'icon-touchid.svg',
      iconStyles: {marginLeft: '6px', padding: '0'},
      isAnimated: false,
    },
    faceId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      icon: 'icon-faceid.svg',
      iconStyles: {marginLeft: '6px', padding: '0'},
      isAnimated: false,
    },
    anyId: {
      border: '2px solid #009FE3',
      borderRadius: '40px',
      color: '#ffffff',
      background: '#009FE3',
      hoverBorder: '2px solid #007FB6',
      hoverBackground: '#007FB6',
      activeBorder: '2px solid #007FB6',
      activeBackground: '#007FB6',
      focusBorder: '2px solid #506274',
      focusBackground: '#009FE3',
      isAnimated: false,
    },
  },
  logisterForm: {
    border: '',
    background: '',
    padding: '20px 0px 20px 0px',
    landscapePadding: '40px',
    submitButton: 'primary',
    forgetLinkFloat: '',
  },
  registerPage: {
    backLinkMarginBottom: {
      default: '20px',
      portrait: '20px',
    },
    titleMargin: {
      default: '0 0 20px',
      portrait: '0 0 20px',
    },
    submitButtonMarginTop: 20,
  },
  secondaryFont: {
    size: '14px',
    height: '16px',
    portrait: {
      size: '18px',
      height: '24px',
      backlink: '16px',
    },
    mobile: {
      size: '16px',
      height: '22px',
    },
  },
  separator: {
    background: ' #878787',
    color: '#FFFFFF',
    height: '1px',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '0',
    portraitMargin: '40px',
    overrideMargin: '24px 0 40px 0',
  },
  logisterSection: {
    subheaderPadding: '12px 20px',
    subheaderFontSize: '16px',
    subheaderLineHeight: '24px',
    subheaderTitleColor: '#000000',
    subheaderLinkColor: '#545454',
    subheaderLinkHoverColor: '#FF0000',
    subheaderBackgroundColor: '#F8F8F8',
    noteFontSize: {
      default: '12px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'landscape',
      fontSize: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '32px',
        desktop: '32px',
      },
      lineHeight: {
        smallMobile: '38px',
        mobile: '38px',
        portrait: '38px',
        landscape: '58px',
        desktop: '58px',
      },
    },
    registerMarginTop: '20px',
    registerSize: '16px',
    socialButtonWidth: '100%',
  },
  emailVerifyPage: {
    subtitle: {
      marginTop: '24px',
      marginBottom: '16px',
      fontSize: '20px',
    },
    noteMarginTop: '40px',
    noteMarginBottom: '8px',
    linkMarginBottom: '8px',
    note: {
      fontFamily: BOLD_FONT,
      fontSize: '16px',
    },
  },
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        color: '#FFFFFF',
        hoverColor: '#FFFFFF',
        fontSize: '14px',
        marginTop: '6px',
      },
    },
  },
  form: {
    border: '',
    background: '#222222',
    padding: '0 0 16px 0',
    fieldBackground: '',
    sectionViewBackground: '#f8f8f8',
    loginSubmitMarginTop: '20',
    marginBottom: '8px',
    note: {
      fontSize: '18px',
    },
  },
  field: {
    error: {
      background: '',
      fontColor: '#FF7171',
      size: '16px',
      height: '22px',
    },
    label: {
      color: '',
      disabledColor: '#000000',
      family: BOLD_FONT,
      fontSize: '16px',
      fontHeight: '22px',
      bottomMargin: '8px',
    },
    edging: {
      backgroundColor: '#222222',
    },
    optional: {
      family: NORMAL_FONT,
      color: '#878787',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      margin: '16px',
    },
  },
  input: {
    color: '#FFFFFF',
    background: '#222222',
    border: '1px solid #8A96A2',
    borderRadius: '8px',
    focusBorder: '2px solid #506274',
    errorBorder: '1px solid #FF7171',
    errorBackground: '#222222',
    height: '40px',
    email: {
      backgroundColor: '#323232',
      color: '#DCDCDC',
      fontSize: '18px',
      border: '1px solid #8A96A2',
      borderRadius: '8px',
      button: {
        height: '28px',
        right: '6px',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: '#009FE3',
        fontFamily: BOLD_FONT,
        fontSize: '14px',
        textDecoration: 'none',
        border: '1px solid #009FE3',
        borderRadius: '8px',
        hoverColor: '#009FE3',
        hoverBackgroundColor: '#162D46',
        hoverBorder: '1px solid #007FB6',
        padding: '4px 12px',
      },
    },
    password: {
      color: '#FFFFFF',
      hoverColor: '#FFFFFF',
      size: '14px',
      height: '20px',
      top: '10px',
    },
    loading: {
      top: '3px',
    },
  },
  inputNote: {
    color: '#FFFFFF',
    family: NORMAL_FONT,
    size: '14px',
    height: '20px',
    marginTop: '4px',
  },
  textarea: {
    height: '80px',
  },
  link: {
    default: '#009FE3',
    hoverColor: '#007FB6',
    footer: '#FFFFFF',
    align: 'start',
    infoColor: '#009FE3',
    infoHoverColor: '#02628B',
    secondary: '#FFFFFF',
    secondaryHover: '#FFFFFF',
    agreement: '#FFFFFF',
    agreementHover: '#FFFFFF',
    isAgreementsLink: true,
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      image: 'RC-radio-dark-theme.svg',
      checkedImage: 'RC-radio-active-dark-theme.svg',
      hoverImage: 'RC-radio-dark-theme.svg',
      errorImage: 'RC-radio-error-dark-theme.svg',
      disabledImage: 'RC-radio-disabled-dark-theme.svg',
      profileImage: 'RC-radio-profile-dark-theme.svg',
      profileCheckedImage: 'RC-radio-profile-active-dark-theme.svg',
      profileHoverImage: 'RC-radio-profile-dark-theme.svg',
      profileErrorImage: 'RC-radio-profile-error-dark-theme.svg',
      profileDisabledImage: 'RC-radio-profile-disabled-dark-theme.svg',
    },
    errorMargin: '20px -20px -16px',
    labelFontHeight: '25px',
  },
  checkbox: {
    icon: {
      height: '20px',
      width: '20px',
      top: '3px',
      right: '12px',
      image: 'RC-checkbox-dark-theme.svg',
      checkedImage: 'RC-checkbox-checked-dark-theme.svg',
      hoverImage: 'RC-checkbox-dark-theme.svg',
      errorImage: 'RC-checkbox-error-dark-theme.svg',
      disabledImage: 'RC-checkbox-disabled-dark-theme.svg',
    },
    labelFontHeight: '22px',
    errorMargin: '20px -20px -16px',
    padding: '20px',
    errorPadding: '4px 20px 0',
    group: {
      marginBottom: '',
      padding: '',
      backgroundColor: '',
      fontWeight: '500',
      fontSize: '16px',
      family: BOLD_FONT,
      label: {
        marginBottom: '0',
        padding: '20px 0 0 0',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      note: {
        fontWeight: '300',
        fontSize: '16px',
        marginLeft: '32px',
        marginTop: '4px',
        lineHeight: '22px',
      },
      portrait: {
        fontSize: '18px',
      },
    },
  },
  select: {
    borderBottomLeftRadiusMenuIsOpen: '0px',
    borderBottomRightRadiusMenuIsOpen: '0px',
    icon: {
      width: '16px',
      height: '16px',
      image: 'from-arrow-down-grey.svg',
      alt_image: 'from-arrow-down-grey.svg',
    },
    menu: {
      checkIcon: 'NB-check-form-white.svg',
      list: {
        backgroundColor: '#222222',
      },
      border: '2px solid #506274',
      color: '#FFFFFF',
      option: {
        focusBorder: '0',
        focusBackground: '#506274',
      },
      singleValue: {
        color: '#FFFFFF',
      },
      placeholder: {
        color: '#8A96A2',
      },
    },
  },
  errorPage: {
    font: {
      height: '18px',
      portrait: {
        height: '30px',
      },
    },
  },
  tab: {
    fontSize: '16px',
    desktop: {
      focusBorder: 'solid 4px #FFFFFF',
      bottomMargin: '-4px',
      border: 'solid 4px #323232',
      height: '70px',
      hoverColor: '#FFFFFF',
    },
    mobile: {
      fontSize: '20px',
      decoration: 'none',
      icon: {
        arrowDownIcon: 'RC-icon-arrow-down-white.svg',
        arrowUpIcon: 'RC-icon-arrow-up-white.svg',
        border: 'solid 2px #878787',
        width: '14px',
        height: '14px',
        color: 'transparent',
      },
    },
  },
  mobileNumber: {
    code: {
      letterSpacing: '8px',
      paddingLeft: {
        mobile: {
          normal: 'calc(50% - 50px)',
          error: 'calc(50% - 51px)',
        },
        desktop: {
          normal: 'calc(25% - 50px)',
          error: 'calc(25% - 51px)',
        },
      },
      underline: {
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 9px)',
        desktopLeft: 'calc(25% - 19px)',
        desktopTop: 'calc(50% - 12px)',
      },
    },
  },
  phoneNumber: {
    container: {
      backgroundColor: '#FFFFFF',
    },
  },
  languageSwitcher: {
    color: 'white',
    separatorColor: 'rgba(255, 255, 255, 0.6)',
  },
  address: {
    padding: '',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  consent: {
    checkbox: {
      marginBottom: '8px',
      paddingBottom: '16px',
    },
  },
  webauthn: {
    dropdown: {
      color: '#009FE3',
      filter: 'invert(44%) sepia(90%) saturate(2698%) hue-rotate(170deg) brightness(99%) contrast(102%)',
      hoverColor: '#007FB6',
      hoverFilter: 'invert(37%) sepia(50%) saturate(6248%) hue-rotate(178deg) brightness(91%) contrast(101%)',
      fontSize: '16px',
      lineHeight: '22px',
      icon: 'from-arrow-down-blue.svg',
    },
  },
  mfa: {
    subtitleFontSize: '16px',
    subtitleFontFamily: NORMAL_FONT,
    backgroundColor: '#222222',
    backArrowIcon: 'dark',
    setup: {
      color: '#FFFFFF',
      printIcon: 'RC-icon-print-new-white.svg',
      downloadIcon: 'RC-icon-download-new-white.svg',
      copyIcon: 'RC-icon-copy-white.svg',
      step: {
        backgroundColor: '#222222',
      },
    },
  },
  avatar: {
    backgroundColor: '#222222',
    border: 'none',
    errorBorder: '1px solid #E01B2F',
    borderRadius: '8px',
    linkButtonColor: '#FFFFFF',
    linkButtonHoverColor: '#FFFFFF',
    defaultAvatarImage: {
      padding: '0px',
      selectedPadding: '0px',
    },
    uploadedAvatarImage: {
      border: '1px solid #666666',
      hoverBorder: '1px solid #666666',
      selectedBorder: '1px solid #666666',
      hoverSelectedBorder: '1px solid #666666',
      padding: '0px',
      selectedPadding: '0px',
    },
    modal: {
      overlayBackgroundColor: 'rgb(138, 150, 162, 0.6)',
      contentBackgroundColor: '#000000',
      headerBackgroundColor: '#323232',
      actionsBackgroundColor: '#323232',
      borderRadius: '8px',
      closeIconImage: 'icon-close.svg',
      zoomInThumbBackground: '#F5F5F7',
      previewContainerBorder: '1px solid #000000',
      previewImageBorder: 'none',
    },
  },
  edging: {
    borderRadius: '8px',
  },
  aboutPage: {
    header: {
      backgroundColor: '#323232',
    },
  },
  legalNoticePage: {
    link: {
      border: 'none',
      color: '#FFFFFF',
      hoverColor: '#FFFFFF',
      activeColor: '#FFFFFF',
    },
  },
  supportPage: {
    externalLink: {
      color: '#FFFFFF',
      image: 'RC-icon-external-link-white.svg',
    },
  },
  profile: {
    about: {
      backgroundColor: '#323232',
    },
    submitButtonsView: {
      backgroundColor: '#222222',
      borderBottom: '2px solid #878787',
    },
    separator: {
      color: '#878787',
    },
    edging: {
      backgroundColor: '#222222',
    },
    externalLink: {
      color: '#FFFFFF',
      icon: 'RC-icon-external-link-white.svg',
      iconHover: 'RC-icon-external-link-hover-white.svg',
    },
    editIcon: 'OL-icon-edit-white.svg',
    editIconHover: 'OL-icon-edit-hover-white.svg',
    removeIcon: 'OL-icon-trash-white.svg',
    removeIconHover: 'OL-icon-trash-hover-white.svg',
    downloadIcon: 'OL-icon-download-white.svg',
    downloadIconHover: 'OL-icon-download-hover-white.svg',
    deactivateIcon: 'OL-icon-deactivate-white.svg',
    deactivateIconHover: 'OL-icon-deactivate-hover-white.svg',
    generateIcon: 'RC-icon-generate-white.svg',
    generateIconHover: 'RC-icon-generate-hover-white.svg',
  },
  modalDialog: {
    overlayBackgroundColor: 'rgba(138, 150, 162, 0.6)',
    contentBackgroundColor: '#000000',
    closeIcon: 'RC-icon-close-new-white.svg',
  },
}
