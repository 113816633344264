export const getOnlyNewsletterTitles = (newsletters) =>
  newsletters
    .map((newsletterName) => {
      const [titleNewsletterName] = newsletterName.split('||')
      const [cleanedTitleNewsletterName] = titleNewsletterName.split(' (')
      return cleanedTitleNewsletterName
    })
    .join(' | ')

export const getNewsletterChanges = (newBrandNewsletters, initialBrandNewsletters = {}) =>
  Object.keys(newBrandNewsletters).reduce(
    (updatedNewsletters, newsletterName) => {
      if (initialBrandNewsletters[newsletterName] !== newBrandNewsletters[newsletterName]) {
        if (newBrandNewsletters[newsletterName]) {
          updatedNewsletters.subscriptionList.push(newsletterName)
        } else {
          updatedNewsletters.unsubscriptionList.push(newsletterName)
        }
      }
      return updatedNewsletters
    },
    {subscriptionList: [], unsubscriptionList: []}
  )
