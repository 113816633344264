import React from 'react'
import {translate} from 'react-i18next'
import FooterLogos from './footer-logos'
import FooterText from './FooterText'
import FooterView from './FooterView'
import FooterBrandsInnerView from './FooterBrandsInnerView'
import FooterLink from './FooterLink'
import Copyright from './copyright'
import FooterBrandsView from './FooterBrandsView'
import {ELEMENTS_IDS, BRANDS} from 'const'
import theme from 'theme'
import {getShouldShouwMoreSoonByProject} from 'theme/brands/commonHelper'
import {getLang} from 'utils/stringUtils'
import {getAboutLink} from 'utils/languageUtils'
import MoreSoon from './MoreSoon'

const Footer = (props) => {
  const {t, footerOpts = {}} = props
  const {hideLogosOnPortrait, margin} = footerOpts

  const isRingier = theme.brand.name === BRANDS.ringier
  const shouldShowMoreSoon = getShouldShouwMoreSoonByProject()
  const shouldShowNewTitle = theme.brand.name === BRANDS.sportalgr
  const lang = getLang()

  const handleLink = (e) => {
    if (isRingier) {
      return
    }
    e.preventDefault()
    const href = e.target.href
    const width = window.innerWidth
    if (width > 1023) {
      return window.open(href)
    } else {
      return (window.location.href = href)
    }
  }

  return (
    <FooterView margin={margin}>
      <FooterBrandsInnerView hideLogosOnPortrait={hideLogosOnPortrait}>
        <FooterBrandsView>
          <FooterText>{t(shouldShowNewTitle ? 'footer-brands-new' : 'footer-brands')}</FooterText>
          {!!theme.otherBrandsLogos.length && <FooterLogos logos={theme.otherBrandsLogos} />}
          {shouldShowMoreSoon && <MoreSoon>{t('ringier-banner.more-soon')}</MoreSoon>}
          <FooterLink className={ELEMENTS_IDS.logisterPage.moreInfo} href={getAboutLink()} onClick={handleLink}>
            {t('footer-info-button')}
          </FooterLink>
        </FooterBrandsView>
      </FooterBrandsInnerView>
      <Copyright dark handleLink={handleLink} lang={lang} />
    </FooterView>
  )
}

export {Footer as PureFooter}

export default translate()(Footer)
