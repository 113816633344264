import qs from 'qs'
import includes from 'lodash/includes'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'
import config from 'config'
import {getInteractionUid} from 'utils/stringUtils'
import * as logger from 'utils/logger'

const removeQs = (url) => {
  if (!url) return url
  if (url.indexOf('?') < 0) return url
  return url.substring(0, url.indexOf('?'))
}

const getResponseBodyAndMessage = async (response) => {
  // cloning response here to prevent body from locking since in can only be read once
  const responseClone = response.clone()
  const responseBody = responseClone.text ? await responseClone.text() : undefined
  let responseJsonBody
  let errorMessage
  try {
    responseJsonBody = JSON.parse(responseBody)
    errorMessage = get(responseJsonBody, 'message')
  } catch {}

  return {responseBody: responseJsonBody || responseBody, errorMessage}
}

async function handleHTTPErrors(response, opt = {}, noLogHttpStatusCodes = [], path) {
  if (!response || !response.ok) {
    const isNativeLoginError =
      response.status < 500 && response.status !== 403 && path && path.includes('/native/login') // pass 403 to handle ip blocking case
    const shouldStatusCodeBeLogged = !includes(noLogHttpStatusCodes, response.status)
    const {responseBody, errorMessage} = await getResponseBodyAndMessage(response)
    response.message = errorMessage
    if (shouldStatusCodeBeLogged && !isNativeLoginError) {
      ;(async () => {
        if (response.status === 403 && errorMessage === 'ip is blocked') {
          const {ip} = responseBody
          const ipBlockedPageUrl = config.links.blockedIp + (ip ? `?ip=${ip}` : '')
          window.location.replace(ipBlockedPageUrl)
        }

        Sentry.withScope(function (scope) {
          scope.setFingerprint(['', removeQs(path), response.status, errorMessage]) // Match on: default (stack-trace only), the request-path, the HTTP status-code and the message in the body, if it exists
          logger.error(new Error(`'${response.status}' (${errorMessage}) during XHR request to ${path}`), {
            status: response.status,
            statusText: response.statusText,
            url: response.url,
            type: response.type,
            body: responseBody,
          })
        })
      })()
    }
    return Promise.reject(response)
  }

  if (response.status === 201 || response.status === 204) {
    return Promise.resolve({})
  }

  return Promise.resolve(opt.isJson ? response.json() : response)
}

export const fetchRest = async (path, method, body, headers = {}, noLogHttpStatusCodes = [], options = {}) => {
  try {
    const response = await fetch(path, {
      method,
      cache: 'no-store',
      body: body ? JSON.stringify(body) : body,
      headers: {
        'Content-Type': 'application/json',
        pragma: 'no-cache',
        'cache-control': 'no-store',
        'X-Trace-Interaction-Id': getInteractionUid(window.location.search),
        ...headers,
      },
      ...options,
    })
    return handleHTTPErrors(response, {isJson: true}, noLogHttpStatusCodes, path)
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['', removeQs(path)])
      logger.error(e, {meta: 'Error during rest fetch request'}, [], path)
    })
    throw e
  }
}

export const submitFormData = async (path, method, body, headers = {}) => {
  try {
    const response = await fetch(path, {
      method,
      body,
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-store',
        'X-Trace-Interaction-Id': getInteractionUid(window.location.search),
        ...headers,
      },
    })
    return handleHTTPErrors(response, {isJson: true})
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['', removeQs(path)])
      logger.error(e, {meta: 'Error during rest form data request'}, [], path)
    })
    throw e
  }
}

export const fetchForm = async (path, method, body, headers = {}, noLogHttpStatusCodes = []) => {
  try {
    const response = await fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-Trace-Interaction-Id': getInteractionUid(window.location.search),
        ...headers,
      },
      body: body ? qs.stringify(body) : body,
      credentials: 'include',
    })

    return handleHTTPErrors(response, {}, noLogHttpStatusCodes, path)
  } catch (e) {
    Sentry.withScope(function (scope) {
      scope.setFingerprint(['', removeQs(path)])
      logger.error(e, {meta: 'Error during form fetch request'}, [], path)
    })
    throw e
  }
}

// currently used only to wrap auth0 calls
export const promisify = (func, noLogHttpStatusCodes) => {
  return (params) =>
    new Promise((resolve, reject) => {
      func(params, (err, data) => {
        if (err) {
          if (!includes(noLogHttpStatusCodes, err.statusCode)) {
            Sentry.withScope(function (scope) {
              const url = get(err, 'original.url')
              scope.setFingerprint(['', removeQs(url), err.code]) // Match on: default (stack-trace only) plus the err.code
              logger.error(new Error(`Auth0 error ${err.code} when calling ${url}`), {error: err})
            })
          }
          reject(err)
        }
        resolve(data)
      })
    })
}
