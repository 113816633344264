export const isTest = () => process.env.NODE_ENV === 'test'

export const isDev = () => process.env.NODE_ENV === 'development'

export const isProd = () => process.env.NODE_ENV === 'production'

export const isBrowser = () => !!process.browser

export const isLoginApp = () => process.env.REACT_APP_TYPE === 'HOSTED'

export const isProfileApp = () => process.env.REACT_APP_TYPE === 'PROFILE'

export const isConsentApp = () => process.env.REACT_APP_TYPE === 'CONSENT'

export const isHosted = () => isLoginApp()

export const getDeployEnv = () => process.env.REACT_APP_ENV

export const getDomainByEnv = () => {
  const DOMAIN_PREFIX_BY_ENV = {dev: 'd.', stg: 's.', local: 'd.', prod: ''}
  return process.env.REACT_APP_OVERRIDE_DOMAIN || `${DOMAIN_PREFIX_BY_ENV[getDeployEnv()]}onelog.ch`
}
