import React from 'react'
import {withTheme} from 'styled-components'
import {compose} from 'utils/reactUtils'

import HeaderView from '../HeaderView'
import HeaderInnerView from '../HeaderInnerView'
import StaticHeaderLogo from './StaticHeaderLogo'
import StaticLanguageSwitcher from 'components/language-switcher/StaticLanguageSwitcher'

const StaticHeader = ({theme, staticPageOptions}) => {
  const withLanguageSwitcher = staticPageOptions.urls

  return (
    <HeaderView>
      <HeaderInnerView>
        <StaticHeaderLogo staticPageOptions={staticPageOptions} theme={theme} />
        {withLanguageSwitcher && <StaticLanguageSwitcher staticPageOptions={staticPageOptions} />}
      </HeaderInnerView>
    </HeaderView>
  )
}

export default compose(withTheme)(StaticHeader)
