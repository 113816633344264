import config from 'config'
import {CLIENTS} from 'const'

const NORMAL_FONT = "'Open Sans', Times New Roman, sans-serif"
const BOLD_FONT = "'Open Sans', Times New Roman, sans-serif"

export default {
  brand: {
    name: CLIENTS.SPORTAL,
    icon: {
      height: '44px',
      portraitHeight: '60px',
      landscapeHeight: '86px',
      desktopHeight: '44px',
      href: 'https://sportal.bg/',
      marginTop: '5px',
    },
    url: 'https://sportal.bg/',
    favicon: `${config.url}/assets/brands/sportal/favicon.ico`,
    fontColor: '#FFFFFF',
  },
  fontConfig: {custom: {urls: [`${config.url}/fonts/sportal/index.css`]}},
  backgroundColor: '#000000',
  captchaTheme: 'dark',
  actionButtonIconPostfix: '-dark-theme',
  font: {
    family: NORMAL_FONT,
    boldFamily: BOLD_FONT,
    size: '14px',
    height: '20px',
    mobile: {size: '16px', height: '24px'},
    portrait: {size: '16px', height: '24px'},
    color: {dark: '#FFFFFF', light: '#FFFFFF'},
    fontWeight: '300',
    boldFontWeight: '400',
  },
  secondaryFont: {
    size: '14px',
    height: '20px',
    mobile: {size: '16px', height: '20px'},
    portrait: {size: '16px', height: '24px'},
  },
  title: {
    family: BOLD_FONT,
    size: '32px',
    height: '120%',
    mobile: {size: '32px', height: '120%'},
    portrait: {size: '32px', height: '120%'},
    landscape: {size: '48px', height: '120%'},
    desktop: {size: '48px', height: '120%'},
    weight: '400',
  },
  ringierBanner: {
    borderRadius: '8px',
    benefitsIcon: `${config.url}/assets/RC-icon-check-dark-theme.svg`,
    linkColor: '#FFFFFF',
    linkHoverColor: '#FFFFFF',
    linkFontSize: '12px',
    benefitMessageColor: '#FFFFFF',
    backgroundColor: '#1A1A1A',
    titleFontWeight: 400,
    titleFontFamily: BOLD_FONT,
    fontSize: '16px',
    lineHeight: '20px',
    iconWidth: '24px',
    iconHeight: '24px',
    landscape: {
      fontSize: '16px',
      subtitleFontSize: '12px',
    },
  },
  otherBrandsLogos: [
    {
      icon: `${config.url}/assets/brands/sportal/logo-sportal-dark-theme.svg`,
      footerHeight: '20px',
      ringierSectionHeight: '20px',
    },
  ],
  note: {
    family: BOLD_FONT,
    fontSize: '20px',
    fontWeight: '400',
    lightFontWeight: '300',
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  notification: {
    family: BOLD_FONT,
    success: '#006939',
    error: '#FF0000',
    pageError: '#FF0000',
    warning: '#FF8400',
    info: '#009FE3',
    icon: {
      width: '24px',
      height: '24px',
      mobile: {width: '32px', height: '32px'},
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `icon-${type}.svg`,
    },
    pageMessage: {
      size: '16px',
      height: '24px',
      iconBorderRadius: '8px 0px 0px 8px',
      textBorderRadius: '0px 8px 8px 0px',
      family: NORMAL_FONT,
    },
  },
  buttons: {
    dotsColor: '#FFFFFF',
    height: '40px',
    border: {
      radius: '5px',
    },
    primary: {
      border: '2px',
      color: '#FFFFFF',
      background: '#006939',
      hoverBackground: '#0B5620',
      hoverColor: '#FFFFFF',
      focusBorder: '2px solid #FFFFFF',
      focusColor: '#FFFFFF',
      focusBackground: '#006939',
      activeBorder: 'none',
      activeColor: '#FFFFFF',
      activeBackground: '#0B5620',
      isAnimated: true,
    },
    secondary: {
      border: '1px solid #019C55',
      color: '#FFFFFF',
      background: 'rgba(0, 0, 0, 0)',
      hoverBorder: '1px solid #006939',
      hoverColor: '#FFFFFF',
      hoverBackground: 'rgba(0, 0, 0, 0)',
      focusBorder: '2px solid #019C55',
      focusColor: '#FFFFFF',
      focusBackground: 'rgba(0, 0, 0, 0)',
      activeBorder: '1px solid #006939',
      activeColor: '#FFFFFF',
      activeBackground: 'rgba(0, 0, 0, 0)',
    },
    avatarSecondary: {
      border: '1px solid #019C55',
      color: '#FFFFFF',
      background: 'rgba(0, 0, 0, 0)',
      hoverBorder: '1px solid #006939',
      hoverColor: '#FFFFFF',
      hoverBackground: 'rgba(0, 0, 0, 0)',
      focusBorder: '2px solid #019C55',
      focusColor: '#FFFFFF',
      focusBackground: 'rgba(0, 0, 0, 0)',
      activeBorder: '1px solid #006939',
      activeColor: '#FFFFFF',
      activeBackground: 'rgba(0, 0, 0, 0)',
    },
    disabled: {
      border: '#C1C1C1',
      color: '#FFFFFF',
      hoverColor: '#FFFFFF',
      background: '#C1C1C1',
      hoverBorder: null,
      hoverBackground: '#C1C1C1',
      activeBorder: null,
      activeBackground: '#C1C1C1',
    },
    apple: {
      defaultPadding: '1px',
      focusPadding: '0px',
      activeColor: '#FF8400',
      background: '#000000',
      border: '1px solid #878787',
      color: '#FFFFFF',
      focusBorder: '2px solid #878787',
      hoverBorder: '1px solid #FF8400',
      activeBorder: '1px solid #FF8400',
      focusColor: '#FFFFFF',
      full: true,
      hoverColor: '#FF8400',
      icon: 'icon-apple-24x24-dark-theme.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
    },
    facebook: {
      defaultPadding: '1px',
      focusPadding: '0px',
      activeColor: '#FF8400',
      background: '#000000',
      border: '1px solid #878787',
      color: '#FFFFFF',
      focusBorder: '2px solid #878787',
      hoverBorder: '1px solid #FF8400',
      activeBorder: '1px solid #FF8400',
      focusColor: '#FFFFFF',
      full: true,
      hoverColor: '#FF8400',
      icon: 'icon-facebook-24x24-dark-theme.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
      isAnimated: false,
    },
    google: {
      defaultPadding: '1px',
      focusPadding: '0px',
      activeColor: '#FF8400',
      background: '#000000',
      border: '1px solid #878787',
      color: '#FFFFFF',
      focusBorder: '2px solid #878787',
      hoverBorder: '1px solid #FF8400',
      activeBorder: '1px solid #FF8400',
      focusColor: '#FFFFFF',
      full: true,
      hoverColor: '#FF8400',
      icon: 'icon-google-24x24-dark-theme.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
      isAnimated: false,
    },
    touchId: {
      border: '2px solid #262626',
      color: '#FFFFFF',
      background: '#262626',
      hoverBackground: '#FF8400',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #FF8400',
      focusBorder: '2px solid #FF8400',
      focusBackground: '#262626',
      activeBorder: '2px solid #FF8400',
      activeBackground: '#FF8400',
      isAnimated: true,
      icon: 'icon-touchid.svg',
      hoverIcon: 'icon-touchid.svg',
      iconStyles: {padding: '6px 12px', marginLeft: '0'},
    },
    faceId: {
      border: '2px solid #262626',
      color: '#FFFFFF',
      background: '#262626',
      hoverBackground: '#FF8400',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #FF8400',
      focusBorder: '2px solid #FF8400',
      focusBackground: '#262626',
      activeBorder: '2px solid #FF8400',
      activeBackground: '#FF8400',
      isAnimated: true,
      icon: 'icon-faceid.svg',
      hoverIcon: 'icon-faceid.svg',
      iconStyles: {padding: '6px 12px', marginLeft: '0'},
    },
    anyId: {
      border: '2px solid #262626',
      color: '#FFFFFF',
      background: '#262626',
      hoverBackground: '#FF8400',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #FF8400',
      focusBorder: '2px solid #FF8400',
      focusBackground: '#262626',
      activeBorder: '2px solid #FF8400',
      activeBackground: '#FF8400',
      isAnimated: true,
    },
    icon: {height: '24px', width: '24px'},
    text: {
      family: BOLD_FONT,
      color: '#000000',
      size: '14px',
      weight: '600',
      transform: 'uppercase',
    },
    linkOnSecondLine: true,
  },
  link: {
    default: '#FF8400',
    hoverColor: '#FFFFFF',
    secondary: '#FFFFFF',
    footer: '#FFFFFF',
    footerHover: '#FFFFFF',
    secondaryHover: '#FFFFFF',
    isAgreementsLinkSecondary: true,
    align: 'start',
  },
  logisterSection: {
    registerFamily: BOLD_FONT,
    registerMarginTop: '20px',
    registerWeight: 400,
    subheaderBorderTop: '1px solid #d9d9d9',
    subheaderPadding: '16px 20px',
    subheaderFontSize: '14px',
    subheaderFamily: BOLD_FONT,
    subheaderLineHeight: '20px',
    subheaderTitleColor: '#000000',
    subheaderLinkColor: '#777777',
    subheaderLinkHoverColor: '#000000',
    subheaderBackgroundColor: '#f9f9f9',
    noteColor: '#777777',
    noteFontSize: {
      default: '12px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'mobile',
      fontSize: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '48px',
        desktop: '48px',
      },
      lineHeight: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '57px',
        desktop: '57px',
      },
    },
  },
  form: {
    border: '',
    background: '#262626',
    fieldBackground: '',
    padding: '0 0 12px 0',
    sectionViewBackground: '#f8f8f8',
    marginBottom: '8px',
    loginSubmitMarginTop: '0',
  },
  logisterForm: {
    border: '',
    background: '',
    padding: '',
    submitButton: 'primary',
    forgetLinkFloat: 'right',
  },
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        fontSize: '14px',
        marginTop: '8px',
      },
    },
  },
  registerPage: {
    backLinkMarginBottom: {default: '12px', portrait: '20px'},
    titleMargin: {default: '0 0 12px', portrait: '0 0 20px'},
    submitButtonMarginTop: 0,
  },
  emailVerifyPage: {
    smallNoteMarginTop: '8px',
    linkMarginBottom: '12px',
    pageMessageMarginBottom: '44px',
    noteMarginBottom: '8px',
    noteMarginTop: '40px',
    note: {
      fontFamily: BOLD_FONT,
    },
    subtitle: {
      fontSize: '20px',
    },
  },
  field: {
    margin: '-20px -20px 20px -20px',
    mobileMargin: '-20px -20px 20px -20px',
    optional: {
      family: NORMAL_FONT,
      weight: '300',
    },
    error: {
      background: 'inherit',
      fontColor: '#FF0000',
      size: '14px',
      height: '20px',
      padding: '4px 20px 0',
      margin: '0 -20px',
    },
    label: {
      color: '#FFFFFF',
      disabledColor: 'rgba(31,31,31,0.6)',
      family: BOLD_FONT,
      fontSize: '16px',
      fontHeight: '24px',
      bottomMargin: '4px',
      fontWeight: '400',
    },
    edging: {
      backgroundColor: '#262626',
    },
  },
  input: {
    borderRadius: '5px',
    border: '1px solid #666666',
    focusBorder: '2px solid #666666',
    hoverBorder: '1px solid #666666',
    height: '40px',
    padding: '8px 12px',
    email: {
      color: '#000000',
      backgroundColor: '#CCCCCC',
      border: '1px solid #666666',
      borderRadius: '5px',
      button: {
        height: '28px',
        right: '6px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '14px',
        fontFamily: BOLD_FONT,
        textDecoration: 'none',
        border: '1px solid #000000',
        borderRight: '1px solid #000000',
        borderRadius: '8px',
        hoverColor: '#000000',
        hoverBackgroundColor: '#FFFFFF',
        hoverBorder: '1px solid #000000',
        hoverBorderRight: '1px solid #000000',
      },
    },
    password: {color: '#000000', hoverColor: '#000000', size: '14px', height: '20px', top: '9px'},
    loading: {top: '7px'},
    background: '#FFFFFF',
    errorBackground: '#FFFFFF',
    errorBorder: '1px solid #FF0000',
  },
  customerNumber: {
    warning: {},
    buttons: {},
    success: {},
  },
  checkbox: {
    borderRadius: '8px',
    icon: {
      height: '20px',
      width: '20px',
      top: '3px',
      right: '12px',
      image: 'brands/sportal/sportal-form-checkbox-dark-theme.svg',
      checkedImage: 'brands/sportal/sportal-form-checkbox-checked-dark-theme.svg',
      hoverImage: 'brands/sportal/sportal-form-checkbox-dark-theme.svg',
      errorImage: 'brands/sportal/sportal-form-checkbox-error-dark-theme.svg',
      disabledImage: 'brands/sportal/sportal-form-checkbox-inactive-dark-theme.svg',
    },
    errorMargin: '',
    padding: '16px',
    labelFontHeight: '24px',
    group: {
      label: {
        marginBottom: '4px',
        padding: '0',
        backgroundColor: '',
      },
      marginBottom: '20px',
      padding: '0 20px',
      backgroundColor: '#F9F9F9',
      fontWeight: '400',
      fontSize: '16px',
      note: {fontWeight: '300', fontSize: '14px', marginLeft: '28px', color: '#777777', lineHeight: '22px'},
    },
    errorPadding: '4px 20px 0',
  },
  select: {
    icon: {
      width: '16px',
      height: '16px',
      image: 'SPORTAL-icon-arrow-down-orange.svg',
      focusImage: 'NRJ-from-arrow-down-hover.svg',
      alt_image: 'NRJ-icon-arrow-down-grey.svg',
    },
    menu: {
      border: '1px solid #BBBBBB',
      checkIcon: 'NRJ-check-form-grey.svg',
      option: {
        focusBorder: '0',
        focusBackground: '#FFFFFF',
        focusColor: '#414141',
      },
    },
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      image: 'brands/sportal/sportal-form-radio-dark-theme.svg',
      checkedImage: 'brands/sportal/sportal-form-radio-active-dark-theme.svg',
      hoverImage: 'brands/sportal/sportal-form-radio-dark-theme.svg',
      errorImage: 'brands/sportal/sportal-form-radio-error-dark-theme.svg',
      disabledImage: 'brands/sportal/sportal-form-radio-inactive-dark-theme.svg',
    },
    errorMargin: '0 -20px 0px',
    labelFontHeight: '22px',
    labelMarginBottom: '8px',
  },
  errorPage: {font: {height: '18px', portrait: {height: '30px'}}},
  inputNote: {
    family: NORMAL_FONT,
    weight: '300',
    color: '#FFFFFF',
    size: '14px',
    height: '20px',
    marginTop: '4px',
  },
  separator: {
    transform: 'uppercase',
    background: '#666666',
    color: '#878787',
    height: '1px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '600',
    portraitMargin: '24px 0 28px 0',
  },
  tab: {
    fontSize: '18px',
    desktop: {focusBorder: 'solid 4px #000000', bottomMargin: '-4px', border: 'solid 4px #DCDCDC', height: '80px'},
    mobile: {icon: {border: 'solid 2px #ACACAC', width: '14px', height: '14px', color: '#000000'}},
  },
  shell: {
    header: {
      hideRingier: false,
      font: {color: '#FFFFFF', size: '12px', height: '14px', family: NORMAL_FONT},
      height: {mobile: '105px', default: '55px', portrait: '80px'},
      padding: '16px 20px 15px 20px',
      color: '#1A1A1A',
      border: {
        width: '0',
      },
      ringierLogo: {
        img: 'logo-poweredby-white.svg',
        lineHeight: '12px',
        default: {height: '38px', width: '81px'},
        portrait: {height: '38px', width: '81px', marginTop: '7px', paddingTop: '4px'},
        marginLeft: 'auto',
        paddingTop: '1px',
      },
      user: {
        font: {default: {size: '14px', height: '18px'}, portrait: {size: '14px', height: '14px'}},
        showLoginAs: false,
      },
      icon: {
        image: 'brands/sportal/logo-sportal.svg',
        height: '24px',
        portrait: {image: 'brands/sportal/logo-sportal.svg', height: '32px'},
      },
      logoMarginRight: {default: '15px', portrait: '32px'},
    },
    footer: {
      copyright: {
        fontColor: '#FFFFFF',
        darkFontColor: '#FFFFFF',
        backgroundColor: '#262626',
        hover: '#FFFFFF',
        darkHover: '#FFFFFF',
        darkPadding: '12px 20px 12px 20px',
      },
      backgroundColor: '#1A1A1A',
      color: '#FFFFFF',
      family: NORMAL_FONT,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      logoHeight: '22px',
      brandsInnerViewPadding: '20px 40px 24px',
      logoPadding: '16px',
      logoPortraitPadding: '0 0 4px 0',
      link: {
        fontSize: '12px',
      },
    },
  },
  consent: {checkbox: {marginBottom: '20px', paddingBottom: ''}},
  mobileNumber: {
    code: {
      letterSpacing: '8px',
      paddingLeft: {
        mobile: {
          normal: 'calc(50% - 58px)',
          error: 'calc(50% - 59px)',
        },
        desktop: {
          normal: 'calc(25% - 58px)',
          error: 'calc(25% - 59px)',
        },
      },
      underline: {
        left: 'calc(50% - 28px)',
        top: 'calc(50% - 7px)',
        desktopLeft: 'calc(25% - 28px)',
        desktopTop: 'calc(50% - 9px)',
      },
    },
    questionLabel: {
      family: BOLD_FONT,
      weight: '300',
    },
  },
  address: {
    padding: '20px 20px 0px 20px',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '0px 0px 20px 0px',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  webauthn: {
    dropdown: {
      color: '#FF8400',
    },
  },
  mfa: {
    backArrowIcon: 'dark',
  },
  avatar: {
    backgroundColor: '#262626',
    border: '1px solid #666666',
    errorBorder: '1px solid #E01B2F',
    borderRadius: '5px',
    linkButtonColor: '#FFFFFF',
    linkButtonHoverColor: '#FFFFFF',
    defaultAvatarImage: {
      padding: '0px',
      selectedPadding: '0px',
    },
    uploadedAvatarImage: {
      border: '1px solid #666666',
      hoverBorder: '1px solid #666666',
      selectedBorder: '1px solid #666666',
      hoverSelectedBorder: '1px solid #666666',
      padding: '0px',
      selectedPadding: '0px',
    },
    modal: {
      overlayBackgroundColor: 'rgba(85, 85, 85, 0.8)',
      contentBackgroundColor: '#000000',
      headerBackgroundColor: '#262626',
      actionsBackgroundColor: '#262626',
      borderRadius: '5px',
      closeIconImage: 'icon-close.svg',
      zoomInThumbBackground: '#006939',
      previewContainerBorder: '1px solid #000000',
      previewImageBorder: '1px solid #666666',
    },
  },
}
