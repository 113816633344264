import get from 'lodash/get'

/**
 * These are used in agreements (AGB/DSB) footers due to inability to run i18n engine properly
 * IMPORTANT: when editing/adding new texts make sure to add them to each language,
 * otherwise empty string will be shown instead of translation
 **/

const staticTexts = {
  de: {
    'footer-brands': 'Online-Portale mit Ringier Connect',
    'footer-info-button': 'Weitere Portale und Informationen',
    copyright: {
      copyright: 'Ringier AG, Schweiz',
      policy: {
        1: 'Impressum',
        2: 'Datenschutz',
        3: 'AGB',
        4: 'Support',
        5: 'Über Ringier Connect',
      },
    },
  },
  it: {
    'footer-brands': 'Portali con Ringier Connect',
    'footer-info-button': 'Ulteriori portali e informazioni',
    copyright: {
      copyright: 'Ringier SA, Svizzera',
      policy: {
        1: 'Impressum',
        2: 'Informativa sulla privacy',
        3: 'CGC',
        4: 'Support',
        5: 'Su Ringier Connect',
      },
    },
  },
  fr: {
    'footer-brands': 'Portails en ligne avec Ringier Connect',
    'footer-info-button': 'Autres portails et informations',
    copyright: {
      copyright: 'Ringier SA, Suisse',
      policy: {
        1: 'Mentions légales',
        2: 'Politique de confidentialité',
        3: 'CG',
        4: 'Assistance',
        5: 'A propos de Ringier Connect',
      },
    },
  },
  bg: {
    'footer-brands': 'Сайтове, използващи Ringier Connect-Login',
    'footer-info-button': 'Повече за тази услуга',
    copyright: {
      copyright: 'Ringier Ltd., Switzerland',
      policy: {
        1: 'Правна информация',
        2: 'Политика за поверителност',
        3: 'Общи условия',
        4: 'Поддръжка',
        5: 'За Ringier Connect',
      },
    },
  },
  hu: {
    'footer-brands': 'A Ringier Connect-Logint használó oldalak',
    'footer-info-button': 'További információ erről a rendszerről',
    'cmp-cookie-settings': 'Sütik beállítása',
    copyright: {
      copyright: 'Ringier Ltd., Svájc',
      policy: {
        1: 'Jogi információk',
        2: 'Adatvédelmi szabályzat',
        3: 'Felhasználási feltételek',
        4: 'Kapcsolatfelvétel',
        5: 'A Ringier Connectről',
      },
    },
  },
  sr: {
    'footer-brands': 'Portals using Ringier Connect-Login',
    'footer-info-button': 'More about this Login',
    copyright: {
      copyright: 'Ringier Ltd., Switzerland',
      policy: {
        1: 'Legal info',
        2: 'Privacy policy',
        3: 'T&Cs',
        4: 'Support',
        5: 'About Ringier Connect',
      },
    },
  },
  sk: {
    'footer-brands': 'Portály používajúce Ringier Connect-Login',
    'footer-info-button': 'Viac o tomto prihlásení',
    copyright: {
      copyright: 'Ringier Ltd., Švajčiarsko',
      policy: {
        1: 'Právne info',
        2: 'Zásady ochrany osobných údajov',
        3: 'Všeobecné obchodné podmienky',
        4: 'Podpora',
        5: 'O Ringier Connect',
      },
    },
  },
  pt: {
    'footer-brands': 'Portais que usam o Login Ringier Connect',
    'footer-info-button': 'Mais sobre este Login',
    copyright: {
      copyright: 'Ringier Ltd., Suíça',
      policy: {
        1: 'Informação legal',
        2: 'Política de privacidade',
        3: 'T&Cs',
        4: 'Apoio',
        5: 'Sobre o Ringier Connect',
      },
    },
  },
  gr: {
    'footer-brands': 'Πύλες που χρησιμοποιούν Ringier Connect - σύνδεση',
    'footer-info-button': 'Περισσότερα για αυτή τη σύνδεση',
    copyright: {
      copyright: 'Ringier Ltd., Ελβετία',
      policy: {
        1: 'Νομικές πληροφορίες',
        2: 'Πολιτική απορρήτου',
        3: 'Όροι και προϋποθέσεις',
        4: 'Υποστήριξη',
        5: 'Σχετικά με το Ringier Connect',
      },
    },
  },
  ro: {
    'footer-brands': 'Site-uri care folosesc autentificarea prin Ringier Connect',
    'footer-info-button': 'Mai multe despre acest sistem de login',
    copyright: {
      copyright: 'Ringier Ltd., Elveția',
      policy: {
        1: 'Legal info',
        2: 'Politica de confidențialitate',
        3: 'Termeni și condiții',
        4: 'Suport',
        5: 'Despre Ringier Connect',
      },
    },
  },
}

export const staticT = (lang) => (key) => get(staticTexts, `${lang}.${key}`, '')
