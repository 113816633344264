import React from 'react'
import {translate} from 'react-i18next'
import Error from 'components/error'

const UnexpectedError = ({t}) => {
  return <Error error={t('errors.unexpected.error')} description={t('errors.unexpected.description')} />
}

export {UnexpectedError as PureUnexpectedError}

export default translate()(UnexpectedError)
