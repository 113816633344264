import styled from 'styled-components'
import get from 'lodash/get'

const getBackgroundImage = (props) => get(props, 'theme.shell.footer.copyright.backgroundImage')
const getBackgroundColor = (props) => props.dark && get(props, 'theme.shell.footer.copyright.backgroundColor')
const getTextDecoration = () => 'underline'
const getHoverTextDecoration = (props) => get(props, 'theme.link.textDecorationHover', getTextDecoration(props))
const getBorderTop = (props) => get(props, 'theme.shell.footer.copyright.borderTop')
const getFontFamily = (props) => get(props, 'theme.shell.footer.fontFamily')
const getFontSize = (props) => get(props, 'theme.shell.footer.fontSize') || '14px'
const getTitleFontWeight = (props) => get(props, 'theme.shell.footer.copyright.fontWeightTitle') || 'inherit'
const getLineHeight = (props) => get(props, 'theme.shell.footer.lineHeight') || '20px'
const getTitleFontFamily = (props) => get(props, 'theme.shell.footer.copyright.fontFamily', getFontFamily(props))

export const CopyrightView = styled.div`
  padding: ${(props) => (props.dark ? props.theme.shell.footer.copyright.darkPadding : '40px 0')};
  color: ${(props) =>
    props.dark ? props.theme.shell.footer.copyright.darkFontColor : props.theme.shell.footer.copyright.fontColor};
  line-height: ${getLineHeight};
  font-size: ${getFontSize};
  font-family: ${getFontFamily};
  text-align: center;
  background-color: ${getBackgroundColor};
  background-image: ${getBackgroundImage};
  border-top: ${getBorderTop};

  & a {
    color: ${(props) =>
      props.dark ? props.theme.shell.footer.copyright.darkFontColor : props.theme.shell.footer.copyright.fontColor};
    text-decoration: ${getTextDecoration};
    cursor: pointer;
  }

  & a:hover {
    color: ${(props) =>
      props.dark ? props.theme.shell.footer.copyright.darkHover : props.theme.shell.footer.copyright.hover};
    text-decoration: ${getHoverTextDecoration};
  }
`
export const CopyrightTitle = styled.div`
  font-weight: ${getTitleFontWeight};
  font-family: ${getTitleFontFamily};
`
