import styled from 'styled-components'

import CheckBox from 'components/checkbox'

const MainFormCheckBox = styled(CheckBox)`
  margin-bottom: ${(props) => props.theme.consent.checkbox.marginBottom};
  background-color: ${(props) => props.theme.form.background};
  padding: ${(props) => props.theme.checkbox.padding};
  padding-bottom: ${(props) => props.theme.consent.checkbox.paddingBottom};
  display: flex;
  flex-direction: column;
`

export default MainFormCheckBox
