import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import i18n from 'i18n-client'

import config from 'config'
import * as asyncUtils from 'utils/asyncUtils'
import * as stringUtils from 'utils/stringUtils'
import {getDeviceType} from 'utils/browserUtils'

class ConsentService {
  constructor(token) {
    if (token) {
      this.token = `Bearer ${token}`
    }
    this.post = this.post.bind(this)
  }

  update = (originalData, newData, requiresMigration, gameConfig) => {
    const loginCase = stringUtils.getLoginCase(window.location.search)
    const preparedData = this.prepareData(originalData, newData, requiresMigration)
    const dataWithAddress = this.updateAddressField(preparedData)
    const data = this.addAgreements(dataWithAddress, originalData, gameConfig)

    if (loginCase) {
      data.loginCase = loginCase
    }

    const source = stringUtils.getSource(window.location.search)
    if (source) {
      data.source = source
    }

    data.registrationChannel = getDeviceType()
    data.lang = stringUtils.getLang()

    // trackLogin(payload.email, data) should be enabled after we remove PII from URL

    return asyncUtils.fetchRest(`${config.backendUrl}/user-update`, 'POST', data, {
      Authorization: this.token,
    })
  }

  // TODO: this method is not self descriptive anymore. Also all display information should come from the backend
  getUsefulPayload = async () => {
    const baseUrl = this.getOidcBaseUrl()
    const interactionId = stringUtils.getInteractionUid(window.location.search)
    const response = await asyncUtils.fetchRest(
      `${baseUrl}/interaction/${interactionId}/payload`,
      'GET',
      undefined,
      {},
      [404],
      {credentials: 'include'}
    )

    const data = response.payload ? stringUtils.parseJWT(response.payload) : stringUtils.parseJWT(this.token)

    const agreements = this.getAgreements(get(data, 'userData.toSign'))
    // TODO: maybe merge it with gameConfig?
    const gameInfo = this.getGameInfo(data)
    const connection = stringUtils.getConnection(window.location.search)
    const linking = stringUtils.getLinking(window.location.search)

    return {
      firstName: this.showField(data, 'firstName'),
      lastName: this.showField(data, 'lastName'),
      username: this.showField(data, 'username'),
      nickname: this.showField(data, 'nickname'),
      nicknameZena: this.showField(data, 'nicknameZena'),
      nicknamePulsonline: this.showField(data, 'nicknamePulsonline'),
      nicknameSportalsr: this.showField(data, 'nicknameSportalsr'),
      nicknameSportsk: this.showField(data, 'nicknameSportsk'),
      nicknameSportalgr: this.showField(data, 'nicknameSportalgr'),
      nicknameAna: this.showField(data, 'nicknameAna'),
      zipCode: this.showField(data, 'zipCode'),
      city: this.showField(data, 'city'),
      birthdate: this.showField(data, 'birthdate'),
      country: this.showField(data, 'country'),
      nationality: this.showField(data, 'nationality'),
      addressLine1: this.showField(data, 'addressLine1'),
      addressLine2: this.showField(data, 'addressLine2'),
      gender: this.showField(data, 'gender'),
      birthYear: this.showField(data, 'birthYear'),
      mobileNumber: this.showField(data, 'mobileNumber'),
      publishingCustomerNumber: this.showField(data, 'publishingCustomerNumber'),
      newsletter: this.showNewsletter(data),
      tos: agreements.global.show,
      tosClient: agreements.client.show,
      marketing: this.showField(data, 'marketing'),
      marketing_blikk: this.showField(data, 'marketing_blikk'),
      marketing_egeszsegkalauz: this.showField(data, 'marketing_egeszsegkalauz'),
      marketing_glamour: this.showField(data, 'marketing_glamour'),
      marketing_kiskegyed: this.showField(data, 'marketing_kiskegyed'),
      marketing_noizz: this.showField(data, 'marketing_noizz'),
      marketing_ruzs: this.showField(data, 'marketing_ruzs'),
      marketing_sportalhu: this.showField(data, 'marketing_sportalhu'),
      sportalAvatar: this.showField(data, 'sportalAvatar'),
      sportalAvatarPublicUrl: this.showField(data, 'sportalAvatarPublicUrl'),
      sportskAvatar: this.showField(data, 'sportskAvatar'),
      sportskAvatarPublicUrl: this.showField(data, 'sportskAvatarPublicUrl'),
      sportalgrAvatar: this.showField(data, 'sportalgrAvatar'),
      sportalgrAvatarPublicUrl: this.showField(data, 'sportalgrAvatarPublicUrl'),
      abolaAvatar: this.showField(data, 'abolaAvatar'),
      abolaAvatarPublicUrl: this.showField(data, 'abolaAvatarPublicUrl'),
      migratedAddress: data.userData && data.userData.migratedAddress,
      agreements: data.requiresAgreements && agreements,
      requiresData: data.requiresData,
      requiresAgreements: data.requiresAgreements,
      requiresAdmeiraOptIn: data.requiresAdmeiraOptIn,
      requiresMigration: data.requiresMigration,
      requiresEmailVerification: data.requiresEmailVerification,
      iss: data.iss,
      userData: data.userData,
      email: data.email,
      sub: data.sub,
      connection: this.getConnection(connection),
      linking,
      gameConfig: data.gameConfig,
      gameInfo,
      abolaFavoriteSport: this.showField(data, 'abolaFavoriteSport'),
      abolaFavoriteClub: this.showField(data, 'abolaFavoriteClub'),
      phoneNumberLapot: this.showField(data, 'phoneNumberLapot'),
      anaLocation: this.showField(data, 'anaLocation'),
      anaOccupation: this.showField(data, 'anaOccupation'),
      anaUrl: this.showField(data, 'anaUrl'),
      anaUrlTitle: this.showField(data, 'anaUrlTitle'),
      gspAvatar: this.showField(data, 'gspAvatar'),
      gspAvatarPublicUrl: this.showField(data, 'gspAvatarPublicUrl'),
      libertateaAvatar: this.showField(data, 'libertateaAvatar'),
      libertateaAvatarPublicUrl: this.showField(data, 'libertateaAvatarPublicUrl'),
    }
  }

  showField = (data, field) => get(data, `userData.${field}.display`, false)

  showNewsletter = (data) => {
    return (
      (data.requiresData || data.requiresAgreements || data.requiresMigration) &&
      get(data, 'userData.newsletter.display', false)
    )
  }

  getConnection = (connection) => i18n.t('linking.connection', {returnObjects: true})[connection]

  updateAgreementsToShowNewLabelsForOldUsers = (serverAgreements) => {
    const {signedAgreements, unsignedAgreements} = serverAgreements
    return unsignedAgreements.map((usa) => {
      const hasUpdatedUnsignedAgreements =
        signedAgreements.length &&
        signedAgreements.some(
          ({brandId, type, version}) => brandId === usa.brandId && type === usa.type && version !== usa.version
        )

      return {
        ...usa,
        ...(hasUpdatedUnsignedAgreements ? {showNewLabel: true} : {}),
      }
    })
  }

  getGameInfo = (data) => {
    if (isEmpty(data.gameConfig)) {
      return
    }
    const agreements = get(data, 'userData.toSign.unsignedAgreements')
    const targetGameAgreement = agreements.find((agreement) => agreement.id === data.gameConfig.agreementId) || {}
    return {
      urls: targetGameAgreement.urls || {},
      isAlreadyParticipated: data.gameConfig.withParticipation ? Object.keys(targetGameAgreement).length === 0 : false,
    }
  }

  getAgreements = (serverAgreements) => {
    const agreements = {
      global: {urls: {}, show: false, newLabels: {}},
      client: {urls: {}, show: false, newLabels: {}},
    }
    if (this.isAgreementsExist(serverAgreements)) {
      const signedAgreements = serverAgreements.signedAgreements.reduce(
        this.reduceAgreements({signed: true}),
        agreements
      )

      const unsignedAgreements = this.updateAgreementsToShowNewLabelsForOldUsers(serverAgreements)
      const nonGameAgreements = unsignedAgreements.filter((agreement) => agreement.type !== 'GAME')

      return nonGameAgreements.reduce(this.reduceAgreements({signed: false}), signedAgreements)
    }

    return agreements
  }

  isAgreementsExist = (serverAgreements) =>
    serverAgreements && serverAgreements.signedAgreements && serverAgreements.unsignedAgreements

  reduceAgreements =
    ({signed}) =>
    (agreements, agreement) => {
      const clientType = agreement.brandId ? 'client' : 'global'

      agreements[clientType].urls[agreement.type] = agreement.urls

      if (!signed) {
        agreements[clientType].show = true

        if (agreement.showNewLabel) {
          agreements[clientType].newLabels[agreement.type] = true
        }
      }

      return agreements
    }

  // TODO think about ways to improve
  prepareData = (originalData, newData, requiresMigration) => {
    const data = {
      ...mapValues(originalData, (obj) => {
        // return full objects if it agreements and `value` for all keys
        if (obj.unsignedAgreements || obj.brands) {
          return obj
        } else {
          return obj.value
        }
      }),
    }

    const DELETABLE_FIELDS = ['anaOccupation', 'anaLocation', 'anaUrl', 'anaUrlTitle']

    DELETABLE_FIELDS.forEach((field) => {
      if (originalData[field] && !newData[field]) {
        data.deletedFields = data.deletedFields ? [...data.deletedFields, field] : [field]
      }
    })

    this.setIfExists(data, 'mobileNumberIntegrityCheckValue', newData.mobileNumberIntegrityCheckValue)
    this.setIfExists(data, 'customerNumberIntegrityCheckValue', newData.customerNumberIntegrityCheckValue)

    this.setIfExists(data, 'usernameIntegrityCheckValue', newData.usernameIntegrityCheckValue)
    this.setIfExists(data, 'nicknameIntegrityCheckValue', newData.nicknameIntegrityCheckValue)
    this.setIfExists(data, 'nicknameZenaIntegrityCheckValue', newData.nicknameZenaIntegrityCheckValue)
    this.setIfExists(data, 'nicknamePulsonlineIntegrityCheckValue', newData.nicknamePulsonlineIntegrityCheckValue)
    this.setIfExists(data, 'nicknameSportalsrIntegrityCheckValue', newData.nicknameSportalsrIntegrityCheckValue)
    this.setIfExists(data, 'nicknameSportskIntegrityCheckValue', newData.nicknameSportskIntegrityCheckValue)
    this.setIfExists(data, 'nicknameSportalgrIntegrityCheckValue', newData.nicknameSportalgrIntegrityCheckValue)
    this.setIfExists(data, 'nicknameAnaIntegrityCheckValue', newData.nicknameAnaIntegrityCheckValue)
    this.setIfExists(data, 'abolaFavoriteSport', newData.abolaFavoriteSport)
    this.setIfExists(data, 'abolaFavoriteClub', newData.abolaFavoriteClub)

    this.setIfExists(data, '_userAddresses', newData._userAddresses)
    this.setIfExists(data, 'gameCheckbox', newData.gameCheckbox)

    Object.entries(originalData).forEach(([key, {value}]) => {
      if (newData[key] === false) {
        data[key] = newData[key]
      } else {
        data[key] = newData[key] || value
      }
    })

    // Remove GP number in case of lazy migration and user cancels verification
    if (originalData.publishingCustomerNumber && requiresMigration && !newData.publishingCustomerNumber) {
      data.publishingCustomerNumber = undefined
      data.customerNumberIntegrityCheckValue = undefined
    }

    this.unsetIfExists(data, 'userAddresses')
    this.unsetIfExists(data, 'claims')

    return data
  }

  setIfExists = (data, name, value) => {
    if (value) {
      data[name] = value
    }
  }

  unsetIfExists = (data, name) => {
    if (data[name]) {
      data[name] = undefined
    }
  }

  resendVerificationEmail = (options) => {
    const lang = stringUtils.getLang()
    return asyncUtils.fetchRest(
      `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/email-verification/resend`,
      'POST',
      {...options, lang},
      {
        Authorization: this.token,
      }
    )
  }

  isEmailVerified = (sub) => {
    // TODO get sub from token in IDS authentication middleware instead of manually sending it
    return asyncUtils.fetchRest(
      `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/email-verification/status/${encodeURIComponent(sub)}`,
      'GET',
      undefined,
      {
        Authorization: this.token,
      }
    )
  }

  updateEmail = (options) =>
    asyncUtils.fetchRest(`${config.backendUrl}/v2/user/email`, 'PATCH', options, {
      Authorization: this.token,
    })

  getOidcBaseUrl = () => {
    const urlFromLocation = stringUtils.getHeliosUrlBaseUrlOverride(window.location.search)
    return urlFromLocation || `https://${process.env.REACT_APP_AUTH0_DOMAIN}`
  }

  redirect = (uid) => {
    const path = this.getPathToRedirect(uid)
    const lang = stringUtils.getLang()

    this.post(path, {lang})
  }

  redirectIfSessionExist = async (uid) => {
    const path = this.getPathToRedirect(uid)
    const lang = stringUtils.getLang()

    if (await this.checkIfSessionExists(path)) {
      this.post(path, {lang})
    }
  }

  getPathToRedirect = (uid) => {
    const baseUrl = this.getOidcBaseUrl()
    const interactionId = uid || stringUtils.getInteractionUid(window.location.search)

    return `${baseUrl}/interaction/${interactionId}/`
  }

  checkIfSessionExists = async (path) => {
    try {
      await asyncUtils.fetchForm(path, 'HEAD', undefined, undefined, [404])
      return true
    } catch {
      return false
    }
  }

  // Post to the provided URL with the specified parameters.
  post = (path, params) => {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = path

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = encodeURIComponent(params[key])

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  }

  updateAddressField = (data) => {
    const ADDRESS_FIELDS = []
    ADDRESS_FIELDS.forEach((field) => {
      if (typeof data[field] === 'string') {
        data[field] = data[field].length > 0 ? {existing: Number(data[field])} : undefined
      }
    })
    return data
  }

  addAgreements = (data, originalData, gameConfig = {}) => {
    const unsignedAgreements = get(originalData, 'toSign.unsignedAgreements', [])
    const agreementIds = unsignedAgreements
      .filter((agreement) => ['TOS', 'PP'].includes(agreement.type))
      .map(({id}) => id)
    const hasUnsignedGameAgreement = unsignedAgreements.find(
      ({id, type}) => type === 'GAME' && id === gameConfig.agreementId
    )
    if (data.gameCheckbox && hasUnsignedGameAgreement) {
      agreementIds.push(gameConfig.agreementId)
    }
    this.unsetIfExists(data, 'gameCheckbox')
    return {...data, agreements: agreementIds}
  }
}

export default ConsentService
