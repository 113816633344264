import React from 'react'
import {translate} from 'react-i18next'
import Error from 'components/error'

const NotFound = ({t}) => {
  return <Error error={t('errors.not-found.error')} description={t('errors.not-found.description')} />
}

export {NotFound as PureNotFound}

export default translate()(NotFound)
