import React from 'react'

const NewMainForm = React.lazy(() => import(/* webpackChunkName: "new-main-form" */ './main-form/newMainForm'))
const EmailVerification = React.lazy(() => import(/* webpackChunkName: "email-verification" */ './email-verification'))
const EmailVerified = React.lazy(() => import(/* webpackChunkName: "email-verified" */ './email-verified'))
const Error = React.lazy(() => import(/* webpackChunkName: "error" */ './error'))
const Unblock = React.lazy(() => import(/* webpackChunkName: "unblock" */ './unblock'))
const About = React.lazy(() => import(/* webpackChunkName: "about" */ './about'))
const Support = React.lazy(() => import(/* webpackChunkName: "support" */ './support'))
const LegalNotice = React.lazy(() => import(/* webpackChunkName: "legal-notice" */ './legal-notice'))
const BlockedIp = React.lazy(() => import(/* webpackChunkName: "blocked-ip" */ './blocked-ip'))
const WebAuthnSetup = React.lazy(() => import(/* webpackChunkName: "web-authn-setup" */ './web-authn-setup'))
const TwoFactorAuthTotpStart = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-totp-start" */ './two-factor-setup/totp/start')
)
const TwoFactorAuthTotpSetup = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-totp-setup" */ './two-factor-setup/totp/setup')
)
const TwoFactorAuthTotpBackup = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-totp-backup" */ './two-factor-setup/totp/backup')
)
const TwoFactorAuthTotpSuccess = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-totp-success" */ './two-factor-setup/totp/success')
)
const TwoFactorAuthSmsSetup = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-sms-setup" */ './two-factor-setup/sms/setup')
)
const TwoFactorAuthSmsSuccess = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-sms-success" */ './two-factor-setup/sms/success')
)
const TwoFactorAuthVerify = React.lazy(() => import(/* webpackChunkName: "two-factor-verify" */ './two-factor-verify'))
const TwoFactorExpiredTokenError = React.lazy(() =>
  import(/* webpackChunkName: "two-factor-setup-expired-token-error" */ './two-factor-setup/expired-token-error')
)

export {
  NewMainForm,
  EmailVerification,
  EmailVerified,
  Error,
  Unblock,
  About,
  Support,
  LegalNotice,
  BlockedIp,
  WebAuthnSetup,
  TwoFactorAuthTotpSetup,
  TwoFactorAuthTotpStart,
  TwoFactorAuthTotpBackup,
  TwoFactorAuthTotpSuccess,
  TwoFactorAuthSmsSetup,
  TwoFactorAuthSmsSuccess,
  TwoFactorAuthVerify,
  TwoFactorExpiredTokenError,
}
