import React from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'

import View from 'components/View'
import Title from 'components/Title'
import Note from 'components/Note'
import theme from 'theme'
import * as stringUtils from 'utils/stringUtils'
import {DOMAIN_BY_ENV, BASE_DOMAIN_BY_PROJECT} from 'const'
import config from 'config'

// Button cannot be imported statically because of cyclic module references
let Button
import('components/button').then((module) => (Button = module.default))

const deployEnv = process.env.REACT_APP_ENV || 'dev'

const EmailVerifiedSubtitle = styled(Note)`
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 20px;
  line-height: 140%;
`

const EmailVerifiedMessage = ({t}) => {
  return (
    <View>
      <Title>{t('email-verified.title')}</Title>
      <EmailVerifiedSubtitle>{t('email-verified.subtitle')}</EmailVerifiedSubtitle>
      <Note light>{t('email-verified.login')}</Note>
      <Button
        {...theme.buttons.primary}
        onClick={() => {
          window.location.href = `https://profile.${DOMAIN_BY_ENV[deployEnv]}${
            BASE_DOMAIN_BY_PROJECT[config.project]
          }/global.profile?lang=${stringUtils.getLang()}`
        }}>
        {t('email-verified.homepagelink')}
      </Button>
    </View>
  )
}

export default translate()(EmailVerifiedMessage)
