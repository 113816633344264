import {CLIENTS} from '../brandUtils'
import ButtonView from '../commonComponents/button/ButtonView'
import SmallNote from '../commonComponents/SmallNote'
import EmailVerifiedSuccessMessage from '../sportal/components/EmailVerifiedSuccessMessage'
import EmailVerifiedErrorMessage from '../commonComponents/EmailVerifiedErrorMessage'
import LoginFormForgetLink from '../commonComponents/LoginFormForgetLink'
import MainFormCheckBox from '../commonComponents/MainFormCheckBox'
import BirthdateLabel from '../commonComponents/BirthdateLabel'
import {UserExistMessage} from 'utils/errorUtils'

export default {
  [CLIENTS.ZENA]: {
    SmallNote,
    ButtonView,
    EmailVerifiedSuccessMessage,
    EmailVerifiedErrorMessage,
    LoginFormForgetLink,
    MainFormCheckBox,
    BirthdateLabel,
    UserExistForConsent: UserExistMessage,
  },
}
