import config from 'config'
import {CLIENTS} from 'const'

const NORMAL_FONT = "'OpenSans-Light', Times New Roman, sans-serif"
const BOLD_FONT = "'OpenSans-Regular', Times New Roman, sans-serif"

export default {
  brand: {
    name: CLIENTS.BLIC,
    icon: {
      url: 'brands/blic/logo-blic.svg',
      height: '44px',
      portraitHeight: '60px',
      landscapeHeight: '86px',
      desktopHeight: '44px',
      href: 'https://blic.rs/',
      marginTop: '5px',
    },
    url: 'https://blic.rs/',
    favicon: `${config.url}/assets/brands/blic/favicon.ico`,
    fontColor: '#162D46',
  },
  fontConfig: {custom: {urls: [`${config.url}/fonts/blic/index.css`]}},
  font: {
    family: NORMAL_FONT,
    boldFamily: BOLD_FONT,
    size: '16px',
    height: '140%',
    mobile: {size: '16px', height: '140%'},
    portrait: {size: '16px', height: '140%'},
    color: {dark: '#000000', light: '#FFFFFF'},
    fontWeight: '300',
    boldFontWeight: '400',
  },
  secondaryFont: {
    size: '16px',
    height: '140%',
    mobile: {size: '16px', height: '140%'},
    portrait: {size: '16px', height: '24px'},
  },
  title: {
    family: BOLD_FONT,
    size: '32px',
    height: '120%',
    mobile: {size: '32px', height: '120%'},
    portrait: {size: '48px', height: '120%'},
    weight: '400',
  },
  ringierBanner: {
    borderRadius: '0px',
    benefitsIcon: `${config.url}/assets/SR-icon-check.svg`,
    linkColor: '#000000',
    linkHoverColor: '#000000',
    linkFontSize: '12px',
    benefitMessageColor: '#000000',
    backgroundColor: '#F8F8F8',
    titleFontWeight: 400,
    titleFontFamily: BOLD_FONT,
    fontSize: '14px',
    lineHeight: '20px',
    iconWidth: '24px',
    iconHeight: '24px',
    landscape: {
      fontSize: '16px',
      subtitleFontSize: '12px',
    },
  },
  otherBrandsLogos: [
    {
      icon: `${config.url}/assets/brands/blic/logo-blic-black.svg`,
      footerHeight: '20px',
      ringierSectionHeight: '20px',
    },
    {
      icon: `${config.url}/assets/brands/zena/logo-zena-black.svg`,
      footerHeight: '14px',
      ringierSectionHeight: '14px',
    },
    {
      icon: `${config.url}/assets/brands/sportalsr/logo-sportalrs-black.svg`,
      footerHeight: '14px',
      ringierSectionHeight: '14px',
    },
    {
      icon: `${config.url}/assets/brands/superrecepti/logo-superrecepti-black.svg`,
      footerHeight: '20px',
      ringierSectionHeight: '20px',
    },
  ],
  note: {
    family: BOLD_FONT,
    fontSize: '18px',
    fontWeight: '400',
    lightFontWeight: '400',
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  notification: {
    family: BOLD_FONT,
    success: '#D9F0E3',
    successBorder: '1px solid #009944',
    error: '#F8D9DB',
    errorBorder: '1px solid #CF000F',
    pageError: '#FF0000',
    warning: '#FDE5DD',
    warningBorder: '1px solid #F0541E',
    info: '#E8F6FA',
    infoBorder: '1px solid #63C0DF',
    icon: {
      width: '24px',
      height: '24px',
      mobile: {width: '32px', height: '32px'},
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `serbia-icon-${type}.svg`,
    },
    pageMessage: {
      size: '16px',
      height: '140%',
      sizeMobile: '16px',
      heightMobile: '140%',
      iconBorderRadius: '0px 0px 0px 0px',
      textBorderRadius: '0px 0px 0px 0px',
      family: NORMAL_FONT,
    },
  },
  buttons: {
    dotsColor: '#FFFFFF',
    height: '40px',
    border: {
      radius: '40px',
    },
    primary: {
      border: '2px',
      color: '#FFFFFF',
      background: '#CC0000',
      hoverBackground: '#BF0000',
      hoverColor: '#FFFFFF',
      focusBorder: '2px solid #000000',
      focusColor: '#FFFFFF',
      focusBackground: '#CC0000',
      activeBorder: 'none',
      activeColor: '#FFFFFF',
      activeBackground: '#BF0000',
      isAnimated: true,
    },
    secondary: {
      border: '1px solid #CC0000',
      color: '#CC0000',
      background: '#FFFFFF',
      hoverBorder: '1px solid #CC0000',
      hoverColor: '#BF0000',
      hoverBackground: '#FFFFFF',
      focusBorder: '2px solid #CC0000',
      focusColor: '#CC0000',
      focusBackground: '#FFFFFF',
      activeBorder: '1px solid #CC0000',
      activeColor: '#CC0000',
      activeBackground: '#FFFFFF',
    },
    disabled: {
      border: '#C1C1C1',
      color: '#FFFFFF',
      hoverColor: '#FFFFFF',
      background: '#C1C1C1',
      hoverBorder: null,
      hoverBackground: '#C1C1C1',
      activeBorder: null,
      activeBackground: '#C1C1C1',
    },
    apple: {
      defaultPadding: '1px',
      focusPadding: '0px',
      background: '#FFFFFF',
      border: '1px solid #878787',
      color: '#000000',
      hoverBorder: '1px solid #CC0000',
      hoverColor: '#CC0000',
      focusBorder: '2px solid #878787',
      focusColor: '#000000',
      activeBorder: '1px solid #CC0000',
      activeColor: '#CC0000',
      full: true,
      icon: 'logo-apple-black.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
      isAnimated: false,
    },
    facebook: {
      defaultPadding: '1px',
      focusPadding: '0px',
      background: '#FFFFFF',
      border: '1px solid #878787',
      color: '#000000',
      hoverBorder: '1px solid #CC0000',
      hoverColor: '#CC0000',
      focusBorder: '2px solid #878787',
      focusColor: '#000000',
      activeBorder: '1px solid #CC0000',
      activeColor: '#CC0000',
      full: true,
      icon: 'icon-facebook-24x24.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
      isAnimated: false,
    },
    google: {
      defaultPadding: '1px',
      focusPadding: '0px',
      background: '#FFFFFF',
      border: '1px solid #878787',
      color: '#000000',
      hoverBorder: '1px solid #CC0000',
      hoverColor: '#CC0000',
      focusBorder: '2px solid #878787',
      focusColor: '#000000',
      activeBorder: '1px solid #CC0000',
      activeColor: '#CC0000',
      full: true,
      icon: 'icon-google-24x24.svg',
      iconStyles: {padding: '0', marginLeft: '10px'},
      isAnimated: false,
    },
    touchId: {
      border: '2px solid #CC0000',
      color: '#FFFFFF',
      background: '#CC0000',
      hoverBackground: '#BF0000',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #BF0000',
      focusBorder: '2px solid #000000',
      focusBackground: '#CC0000',
      activeBorder: '2px solid #BF0000',
      activeBackground: '#BF0000',
      isAnimated: true,
      icon: 'icon-touchid.svg',
      hoverIcon: 'icon-touchid.svg',
      iconStyles: {padding: '6px 12px', marginLeft: '0'},
    },
    faceId: {
      border: '2px solid #CC0000',
      color: '#FFFFFF',
      background: '#CC0000',
      hoverBackground: '#BF0000',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #BF0000',
      focusBorder: '2px solid #000000',
      focusBackground: '#CC0000',
      activeBorder: '2px solid #BF0000',
      activeBackground: '#BF0000',
      isAnimated: true,
      icon: 'icon-faceid.svg',
      hoverIcon: 'icon-faceid.svg',
      iconStyles: {padding: '6px 12px', marginLeft: '0'},
    },
    anyId: {
      border: '2px solid #CC0000',
      color: '#FFFFFF',
      background: '#CC0000',
      hoverBackground: '#BF0000',
      hoverColor: '#FFFFFF',
      hoverBorder: '2px solid #BF0000',
      focusBorder: '2px solid #000000',
      focusBackground: '#CC0000',
      activeBorder: '2px solid #BF0000',
      activeBackground: '#BF0000',
      isAnimated: true,
    },
    icon: {height: '24px', width: '24px'},
    text: {
      family: BOLD_FONT,
      color: '#000000',
      size: '14px',
      weight: '700',
      transform: 'uppercase',
    },
    linkOnSecondLine: true,
  },
  link: {
    default: '#CC0000',
    hoverColor: '#BF0000',
    secondary: '#000000',
    footer: '#000000',
    footerHover: '#000000',
    secondaryHover: '#000000',
    isAgreementsLinkSecondary: true,
    align: 'start',
  },
  logisterSection: {
    registerFamily: BOLD_FONT,
    registerMarginTop: '20px',
    registerWeight: 400,
    subheaderBorderTop: '1px solid #d9d9d9',
    subheaderPadding: '16px 20px',
    subheaderFontSize: '14px',
    subheaderFamily: BOLD_FONT,
    subheaderLineHeight: '20px',
    subheaderTitleColor: '#000000',
    subheaderLinkColor: '#777777',
    subheaderLinkHoverColor: '#000000',
    subheaderBackgroundColor: '#f9f9f9',
    noteColor: '#777777',
    noteFontSize: {
      default: '12px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'mobile',
      fontSize: {
        smallMobile: '38px',
        mobile: '38px',
        portrait: '38px',
        landscape: '48px',
        desktop: '48px',
      },
      lineHeight: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '32px',
        landscape: '57px',
        desktop: '57px',
      },
    },
  },
  form: {
    border: '',
    background: '#F8F8F8',
    fieldBackground: '',
    padding: '0 0 12px 0',
    sectionViewBackground: '#f8f8f8',
    marginBottom: '8px',
    loginSubmitMarginTop: '0',
  },
  logisterForm: {border: '', background: '', padding: '', submitButton: 'primary', forgetLinkFloat: 'right'},
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        fontSize: '14px',
        marginTop: '8px',
      },
    },
  },
  registerPage: {
    backLinkMarginBottom: {default: '12px', portrait: '20px'},
    titleMargin: {default: '0 0 12px', portrait: '0 0 20px'},
    submitButtonMarginTop: 0,
  },
  emailVerifyPage: {
    smallNoteMarginTop: '8px',
    linkMarginBottom: '12px',
    pageMessageMarginBottom: '44px',
    noteMarginBottom: '8px',
    noteMarginTop: '40px',
    note: {
      fontFamily: BOLD_FONT,
    },
    subtitle: {
      marginTop: '24px',
      marginBottom: '24px',
      fontSize: '20px',
    },
  },
  field: {
    margin: '-20px -20px 20px -20px',
    mobileMargin: '-20px -20px 20px -20px',
    optional: {
      family: NORMAL_FONT,
      weight: '300',
    },
    error: {
      background: 'inherit',
      fontColor: '#CF000F',
      size: '14px',
      height: '20px',
      padding: '4px 20px 0',
      margin: '0 -20px',
    },
    label: {
      color: '#000000',
      errorColor: '#CF000F',
      disabledColor: 'rgba(31,31,31,0.6)',
      family: BOLD_FONT,
      fontSize: '16px',
      fontHeight: '24px',
      bottomMargin: '4px',
      fontWeight: '400',
    },
    edging: {
      backgroundColor: '#F8F8F8',
    },
  },
  input: {
    borderRadius: '8px',
    border: '1px solid #CCCCCC',
    focusBorder: '1px solid #000000',
    hoverBorder: '1px solid #CCCCCC',
    height: '40px',
    padding: '8px 12px',
    email: {
      backgroundColor: '#F8F8F8',
      border: '1px solid #CCCCCC',
      borderRadius: '8px',
      color: '#878787',
      button: {
        height: '28px',
        right: '6px',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        fontSize: '14px',
        fontFamily: BOLD_FONT,
        textDecoration: 'none',
        border: '1px solid #000000',
        borderRight: '1px solid #000000',
        borderRadius: '40px',
        hoverColor: '#BF0000',
        hoverBackgroundColor: '#FFFFFF',
        hoverBorder: '1px solid #BF0000',
        hoverBorderRight: '1px solid #BF0000',
      },
    },
    password: {color: '#162D46', hoverColor: '#000000', size: '14px', height: '20px', top: '9px'},
    loading: {top: '7px'},
    background: '#FFFFFF',
    errorBackground: '#FFFFFF',
    errorBorder: '1px solid #CF000F',
  },
  customerNumber: {
    warning: {},
    buttons: {},
    success: {},
  },
  checkbox: {
    borderRadius: '0px',
    icon: {
      height: '20px',
      width: '20px',
      top: '3px',
      right: '12px',
      image: 'serbia-form-checkbox.svg',
      checkedImage: 'serbia-form-checkbox-checked.svg',
      hoverImage: 'serbia-form-checkbox.svg',
      errorImage: 'brands/sportal/sportal-form-checkbox-error.svg',
      disabledImage: 'brands/beo/BEO-form-checkbox-inactive.svg',
    },
    errorMargin: '',
    padding: '16px',
    labelFontHeight: '24px',
    group: {
      label: {
        marginBottom: '4px',
        padding: '0',
        backgroundColor: '',
      },
      marginBottom: '20px',
      padding: '12px 16px 0px 16px',
      backgroundColor: '#F8F8F8',
      borderRadius: '8px',
      fontWeight: '400',
      family: BOLD_FONT,
      fontSize: '16px',
      note: {
        fontWeight: '300',
        fontSize: '16px',
        marginLeft: '32px',
        marginTop: '6px',
        color: '#000000',
        lineHeight: '140%',
      },
    },
    errorPadding: '4px 20px 0',
  },
  select: {
    icon: {
      width: '16px',
      height: '16px',
      image: 'HU-icon-arrow-down-gray.svg',
      focusImage: 'NRJ-from-arrow-down-hover.svg',
      alt_image: 'NRJ-icon-arrow-down-grey.svg',
    },
    menu: {
      border: '1px solid #BBBBBB',
      checkIcon: 'NRJ-check-form-grey.svg',
      option: {
        focusBorder: '0',
        focusBackground: '#FFFFFF',
        focusColor: '#414141',
      },
    },
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      image: 'serbia-form-radio.svg',
      checkedImage: 'serbia-form-radio-selected.svg',
      hoverImage: 'serbia-form-radio.svg',
      errorImage: 'brands/beo/BEO-form-radio-error.svg',
      disabledImage: 'brands/beo/BEO-form-radio-empty-inactive.svg',
    },
    errorMargin: '0 -20px 0px',
    labelFontHeight: '22px',
    labelMarginBottom: '8px',
  },
  errorPage: {font: {height: '18px', portrait: {height: '30px'}}},
  inputNote: {
    family: NORMAL_FONT,
    weight: '300',
    color: '#1F1F1F',
    size: '14px',
    height: '20px',
    marginTop: '4px',
  },
  separator: {
    transform: 'uppercase',
    background: '#CCCCCC',
    color: '#CCCCCC',
    height: '1px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16px',
    portraitMargin: '24px 0 28px 0',
  },
  tab: {
    fontSize: '18px',
    desktop: {focusBorder: 'solid 4px #000000', bottomMargin: '-4px', border: 'solid 4px #DCDCDC', height: '80px'},
    mobile: {icon: {border: 'solid 2px #ACACAC', width: '14px', height: '14px', color: '#000000'}},
  },
  shell: {
    header: {
      hideRingier: false,
      font: {color: '#FFFFFF', size: '12px', height: '14px', family: NORMAL_FONT},
      height: {mobile: '105px', default: '55px', portrait: '80px'},
      padding: '16px 20px 15px 20px',
      color: '#FFFFFF',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
      border: {
        width: '0',
      },
      ringierLogo: {
        img: 'logo-poweredby.svg',
        lineHeight: '12px',
        default: {height: '38px', width: '81px'},
        portrait: {height: '38px', width: '81px', marginTop: '7px', paddingTop: '4px'},
        marginLeft: 'auto',
        paddingTop: '1px',
      },
      user: {
        font: {default: {size: '14px', height: '140%'}, portrait: {size: '14px', height: '140%'}},
        showLoginAs: false,
      },
      icon: {
        image: 'brands/blic/logo-blic.svg',
        height: '40px',
        portrait: {image: 'brands/blic/logo-blic.svg', height: '52px'},
        href: 'https://blic.rs/',
      },
      logoMarginRight: {default: '15px', portrait: '32px'},
    },
    footer: {
      copyright: {
        fontColor: '#FFFFFF',
        darkFontColor: '#FFFFFF',
        backgroundColor: '#262626',
        hover: '#FFFFFF',
        darkHover: '#FFFFFF',
        darkPadding: '12px 20px 12px 20px',
      },
      backgroundColor: '#F9F9F9',
      color: '#1F1F1F',
      family: NORMAL_FONT,
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '20px',
      logoHeight: '22px',
      brandsInnerViewPadding: '20px 40px 24px',
      logoPadding: '16px',
      logoPortraitPadding: '0 0 4px 0',
    },
  },
  consent: {checkbox: {marginBottom: '20px', paddingBottom: ''}},
  mobileNumber: {
    code: {
      letterSpacing: '8px',
      paddingLeft: {
        mobile: {
          normal: 'calc(50% - 58px)',
          error: 'calc(50% - 59px)',
        },
        desktop: {
          normal: 'calc(25% - 58px)',
          error: 'calc(25% - 59px)',
        },
      },
      underline: {
        left: 'calc(50% - 28px)',
        top: 'calc(50% - 7px)',
        desktopLeft: 'calc(25% - 28px)',
        desktopTop: 'calc(50% - 9px)',
      },
    },
    questionLabel: {
      family: BOLD_FONT,
      weight: '300',
    },
  },
  address: {
    padding: '20px 20px 0px 20px',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '0px 0px 20px 0px',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  webauthn: {
    dropdown: {
      color: '#000000',
    },
  },
  mfa: {
    edging: {
      borderRadius: '0px',
    },
  },
}
