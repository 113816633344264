import React, {Component, Fragment} from 'react'
import {translate} from 'react-i18next'
import {withTheme} from 'styled-components'
import initial from 'lodash/initial'
import last from 'lodash/last'

import {ELEMENTS_IDS} from 'const'
import {createOrUpdateQueryParam} from 'utils/stringUtils'
import {compose} from 'utils/reactUtils'
import {LanguageSwitcherSeparator, LanguageSwitcherLink, LanguageSwitcherContainer} from './LanguageSwitcherStyles'

class LanguageSwitcher extends Component {
  changeLanguage = (lng) => async () => {
    await this.props.i18n.changeLanguage(lng)
    createOrUpdateQueryParam('lang', lng)
  }

  renderLanguageSwitcherLink = (language) => {
    return (
      <LanguageSwitcherLink
        isprofile={`${this.props.isProfile}`}
        isseparatepage={`${this.props.isSeparatePage}`}
        className={ELEMENTS_IDS.languageSwitcher}
        state={this.props.i18n.language === language ? 'active' : undefined}
        onClick={this.changeLanguage(language)}
        external>
        {language}
      </LanguageSwitcherLink>
    )
  }

  renderLanguageSwitcherSeparator = () => {
    return (
      <LanguageSwitcherSeparator isseparatepage={`${this.props.isSeparatePage}`} isprofile={`${this.props.isProfile}`}>
        |
      </LanguageSwitcherSeparator>
    )
  }

  render() {
    const {options, isSeparatePage, isProfile} = this.props

    if (options && options.length) {
      return (
        <LanguageSwitcherContainer isprofile={`${isProfile}`} isseparatepage={`${isSeparatePage}`}>
          {initial(options).map((language) => (
            <Fragment key={language}>
              {this.renderLanguageSwitcherLink(language)}
              {this.renderLanguageSwitcherSeparator()}
            </Fragment>
          ))}
          {this.renderLanguageSwitcherLink(last(options))}
        </LanguageSwitcherContainer>
      )
    }

    return null
  }
}

export default compose(translate(), withTheme)(LanguageSwitcher)
