import STATUS_CODES from './statusCodes'
import {ELEMENTS_IDS} from './elementIds'
import {SPORTS_PT, CLUBS_PT} from './abolaSportSelect'

const deployEnv = process.env.REACT_APP_ENV || 'dev'

const PAGE_TYPES = {
  CONSENT: 'consent',
  PROFILE: 'profile',
}

const BRANDS = {
  ringier: 'ringier',
  ringierelearning: 'ringierelearning',
  sportal: 'sportal',
  blikk: 'blikk',
  egeszsegkalauz: 'egeszsegkalauz',
  glamour: 'glamour',
  kiskegyed: 'kiskegyed',
  noizz: 'noizz',
  ruzs: 'ruzs',
  sportalhu: 'sportalhu',
  lapot: 'lapot',
  blic: 'blic',
  'super recepti': 'super recepti',
  sportalsr: 'sportalsr',
  zena: 'zena',
  'puls online': 'puls online',
  ana: 'ana',
  nekretnine: 'nekretnine',
  blictv: 'blictv',
  sportsk: 'sportsk',
  aktuality: 'aktuality',
  najmama: 'najmama',
  'herna zona': 'herna zona',
  zive: 'zive',
  recepty: 'recepty',
  kalendar: 'kalendar',
  'dobru chut': 'dobru chut',
  slovnik: 'slovnik',
  diva: 'diva',
  abola: 'abola',
  sportalgr: 'sportalgr',
  gsp: 'gsp',
  libertatea: 'libertatea',
}

const FEDERATED_PROVIDERS = {
  google: {
    name: 'google',
    formattedName: 'Google',
  },
  apple: {
    name: 'apple',
    formattedName: 'Apple',
  },
  swissid: {
    name: 'swissid',
    formattedName: 'SwissID',
  },
  facebook: {
    name: 'facebook',
    formattedName: 'Facebook',
  },
}

const LOGIN_CASES = {
  DEFAULT: 'default',
  GENERAL: 'general',
  AUTOCOMPLETE: 'autocomplete',
  EMAIL_ONLY: 'email_only',
  COMMENT: 'comment',
  TEXT_TO_SPEECH: 'text_to_speech',
  SPORTSK: {
    FANS_UNITED: 'fans_united',
  },
  ANA: {
    USERNAME_ONLY: 'username_only',
  },
  SPORTALSR: {
    GAMIFICATION: 'gamification',
  },
  SPORTALGR: {
    SPORTALGR_CASE: 'sportalgr_case',
  },
}

const CLIENTS = {
  RINGIER: BRANDS.ringier,
  RINGIERELEARNING: BRANDS.ringierelearning,
  SPORTAL: BRANDS.sportal,
  BLIKK: BRANDS.blikk,
  EGESZSEGKALAUZ: BRANDS.egeszsegkalauz,
  GLAMOUR: BRANDS.glamour,
  KISKEGYED: BRANDS.kiskegyed,
  NOIZZ: BRANDS.noizz,
  RUZS: BRANDS.ruzs,
  SPORTALHU: BRANDS.sportalhu,
  LAPOT: BRANDS.lapot,
  BLIC: BRANDS.blic,
  SUPERRECEPTI: BRANDS['super recepti'],
  SPORTALSR: BRANDS.sportalsr,
  PULSONLINE: BRANDS['puls online'],
  ZENA: BRANDS.zena,
  ANA: BRANDS.ana,
  NEKRETNINE: BRANDS.nekretnine,
  BLICTV: BRANDS.blictv,
  SPORTSK: BRANDS.sportsk,
  AKTUALITY: BRANDS.aktuality,
  NAJMAMA: BRANDS.najmama,
  HERNAZONA: BRANDS['herna zona'],
  ZIVE: BRANDS.zive,
  RECEPTY: BRANDS.recepty,
  KALENDAR: BRANDS.kalendar,
  DOBRUCHUT: BRANDS['dobru chut'],
  SLOVNIK: BRANDS.slovnik,
  DIVA: BRANDS.diva,
  ABOLA: BRANDS.abola,
  SPORTALGR: BRANDS.sportalgr,
  GSP: BRANDS.gsp,
  LIBERTATEA: BRANDS.libertatea,
}

const PROFILE_TABS = {
  global: 'global.profile',
  globalAddress: 'global.address',
  globalSecurity: 'global.security',
  sportal: BRANDS.sportal,
  blikk: BRANDS.blikk,
  egeszsegkalauz: BRANDS.egeszsegkalauz,
  glamour: BRANDS.glamour,
  kiskegyed: BRANDS.kiskegyed,
  noizz: BRANDS.noizz,
  ruzs: BRANDS.ruzs,
  sportalhu: BRANDS.sportalhu,
  lapot: BRANDS.lapot,
  blic: BRANDS.blic,
  superrecepti: BRANDS['super recepti'],
  sportalsr: BRANDS.sportalsr,
  pulsonline: BRANDS['puls online'],
  zena: BRANDS.zena,
  ana: BRANDS.ana,
  nekretnine: BRANDS.nekretnine,
  blictv: BRANDS.blictv,
  sportsk: BRANDS.sportsk,
  abola: BRANDS.abola,
  sportalgr: BRANDS.sportalgr,
  gsp: BRANDS.gsp,
  libertatea: BRANDS.libertatea,
}

const GP_NUMBER_SUPPORT = {}

const CLIENT_MAP = {
  // Ringier eLearning
  // dev
  '1d6d38962f627b402d1c8a4867d9972e': CLIENTS.RINGIERELEARNING,
  // stage
  '37556e435d026e931d5f1e032ff489a8': CLIENTS.RINGIERELEARNING,
  '64998f44fdeb4343afe2e5fab3f2e959': CLIENTS.RINGIERELEARNING, // hexagon
  // prod
  '3dfe252120de51cedad41ec73e1501bf': CLIENTS.RINGIERELEARNING,
  '799339e981c74a5685eda7b97d3164e0': CLIENTS.RINGIERELEARNING, // Hexagon

  // RC Bulgaria
  // Sportal
  // stage
  ecb83e3ac7e8419f820d3020f9b2b6a8: CLIENTS.SPORTAL, // web
  a2483474f9f8dfe3b3b7fd2e7eaeb5a2: CLIENTS.SPORTAL, // native
  // prod
  '76c07931dc3842f697d9d832b9223271': CLIENTS.SPORTAL, // web
  '32d715bb565a7c75eed29d3a0a1d3353': CLIENTS.SPORTAL, // native

  // RC Hungary
  // Blikk
  // stage
  '673d49d28fed470bb4b71c3a2aef10bd': CLIENTS.BLIKK, // web
  // prod
  '7488389554fd47a9b3473b9b66371a62': CLIENTS.BLIKK, // web

  // Egeszsegkalauz
  // stage
  b558a43de58645a285f338e67022546d: CLIENTS.EGESZSEGKALAUZ, // web
  // prod
  '5efcd670f29b4a17bc12bd382a87b6b2': CLIENTS.EGESZSEGKALAUZ, // web

  // Glamour
  // stage
  '0d28225ec30646f4b3b076b680bd2652': CLIENTS.GLAMOUR, // web
  '4f0f7ade5ccc08942c465c7e560c3a34': CLIENTS.GLAMOUR, // glamour universe native
  '8e73336fd33fce0738ff97bec1eb3b21': CLIENTS.GLAMOUR, // glamour honeycommb
  // prod
  '31e156d21fb7456e8780b227ae8d7a15': CLIENTS.GLAMOUR, // web
  aa36e97eb849e3e4ab5f1f092c6fec71: CLIENTS.GLAMOUR, // glamour universe native
  '2be7b5f78a118bca2ca7f4900de443b4': CLIENTS.GLAMOUR, // glamour honeycommb

  // Kiskegyed
  // stage
  '0dc7ad673e8e48e99eada13e1c9a162c': CLIENTS.KISKEGYED, // web
  // prod
  '4221e5a22c9f4b06bc56089ec3cfcfee': CLIENTS.KISKEGYED, // web

  // Noizz
  // tage
  '68e9031f49a54b7f8a1787ded6f45309': CLIENTS.NOIZZ, // web
  // prod
  '32b06f03f7a94f4398ec391440f105d6': CLIENTS.NOIZZ, // web

  // Ruzs
  // stage
  d4d8a7ce276c42208d7aeae1240d1ce5: CLIENTS.RUZS, // web
  // prod
  '1acb661d83c44feb847e93bf48c7ce3a': CLIENTS.RUZS, // web

  // Sportalhu
  // stage
  de0525ae70b280333bf481e82164d8e8: CLIENTS.SPORTALHU, // web
  // prod
  '8858d76f25af360cbdfeff979376f36d': CLIENTS.SPORTALHU, // web

  // Lapot
  // stage
  '12268b309da400d3b9ce01b34b456905': CLIENTS.LAPOT, // web
  // prod
  ea77e901e733e8a2c6e54f599e66b784: CLIENTS.LAPOT, // web

  // RC Serbia
  // Blic
  // stage
  '17ce52bf2d29419f9d85ca082614d8c8': CLIENTS.BLIC, // web
  '046f0dcf2e2f882e08f6f14c764f84d7': CLIENTS.BLIC, // native
  ea9ccf04ebf34b44b91d22459e8dca82: CLIENTS.BLICTV, // tv
  '1aa97431188d4874c02d79508bb60902': CLIENTS.BLICTV, // tv (web)
  // prod
  cdc1bae0dcbd45af83c4b2143e50e9ea: CLIENTS.BLIC, // web
  de84e7695671c821d6c1f7384a994570: CLIENTS.BLIC, // native
  '3b5e5f248e0f41ff99deae1e3ec43a30': CLIENTS.BLICTV, // tv
  db9ae035b48c32f85b282cb9b1631139: CLIENTS.BLICTV, // tv (web)

  // Super Recepti
  // stage
  f73066bd91b140698339a56dd6686d0d: CLIENTS.SUPERRECEPTI, // web
  // prod
  '42cd817808b54de09caaf91bb97e877a': CLIENTS.SUPERRECEPTI, // web

  // Sportal SR
  // stage
  '6d3353c35f2e45ca8b72e41211fd09d7': CLIENTS.SPORTALSR, // web
  '08216a947cf649a330ef6117e006243b': CLIENTS.SPORTALSR, // native
  // prod
  '73c9b9dd04124bad944de77e7aab2a8c': CLIENTS.SPORTALSR, // web
  dc1b266bcb2a39c12d2c9cb6ead384e6: CLIENTS.SPORTALSR, // native

  // Zena
  // stage
  '5053247f7484436dab5bae713651c586': CLIENTS.ZENA, // web
  // prod
  e974f399d2574d0caf58383b5993ad4d: CLIENTS.ZENA, // web

  // Puls Online
  // stage
  '52f49f5a0efb4804a0c09184486cb8e0': CLIENTS.PULSONLINE, // web
  // prod
  '851104cf038649bb8e0a053e3c7c145d': CLIENTS.PULSONLINE, // web

  // Ana
  // stage
  f2f45244b8294f018053f95fd176291e: CLIENTS.ANA, // web
  // prod
  '2ecfddbdbb1449cf919d2beb53f23e32': CLIENTS.ANA, // web

  // Nekretnine
  // stage
  bc2f9cfca724432f81b2cb13a11e0772: CLIENTS.NEKRETNINE, // web
  // prod
  b890e11ae4324d319e2383658eab7623: CLIENTS.NEKRETNINE, // web

  // Sport.sk
  // stage
  '76bad67a5d494dc3b5ae6b7aa7480fc3': CLIENTS.SPORTSK, // web
  ed63c76c8d184d0894ce8434b80dfa3c: CLIENTS.SPORTSK, // web
  // prod

  // Aktuality
  // stage
  '460b885e23ac4b418ef35a2186d2c9d7': CLIENTS.AKTUALITY, // web
  // prod
  '0e700d8d8e7c44379fede5a9454863be': CLIENTS.AKTUALITY, // web

  // Najmama
  // stage
  '0bb7dc5671a64956806ba655ad2f5bcf': CLIENTS.NAJMAMA, // web
  // prod
  '68cfd3d82dd747e394b167031e16446d': CLIENTS.NAJMAMA, // web

  // Herna Zona
  // stage
  '32ebde079160452a8d72ca657597fa34': CLIENTS.HERNAZONA, // web
  // prod
  '760d9fd7c288442e8d0f17e3ba47f34c': CLIENTS.HERNAZONA, // web

  // Zive
  // stage
  '5182425480bd495fa7f691cb490a1245': CLIENTS.ZIVE, // web
  // prod
  e98912d2544b4562bbfd5365d66f781a: CLIENTS.ZIVE, // web

  // Recepty
  // stage
  '5ac6ebd10c244a59922b3083a7ef9880': CLIENTS.RECEPTY, // web
  // prod
  b81c8c7975fb4c2daceaffd9a1ab3152: CLIENTS.RECEPTY, // web

  // Kalendar
  // stage
  ae7fb37959a640a4a908214c8f4ff7f4: CLIENTS.KALENDAR, // web
  // prod
  '00308461c51d450fb3bf04331e3bf398': CLIENTS.KALENDAR, // web

  // Dobru Chut
  // stage
  d102c1ababc34ab0af8e0a30ada40c6f: CLIENTS.DOBRUCHUT, // web
  // prod
  '328a2120bd704268be4785d28d0b100a': CLIENTS.DOBRUCHUT, // web

  // Slovnik
  // stage
  f5fbc56cf0f74d529e6b8e244ad9aefb: CLIENTS.SLOVNIK, // web
  // prod
  fc4c94442746405f982d0136718c3ff5: CLIENTS.SLOVNIK, // web

  // Diva
  // stage
  fff37d551f1a4a3687469e3dca2366cc: CLIENTS.DIVA, // web
  // prod
  f726f884cd4c4b189d6681e59445b434: CLIENTS.DIVA, // web

  // Abola
  // stage
  '2715165c389540aea8bfceb0984c6f0b': CLIENTS.ABOLA, // web
  // prod
  '04c89fc16c9a4360b51386d6d692b47b': CLIENTS.ABOLA, // web

  // RC Greece
  // Sportal.gr
  // stage
  f2bfadd26e1e4da5a8a2d419a27780c0: CLIENTS.SPORTALGR, // web
  // prod
  f5a37bfb013b4d80a878668b2b3834b7: CLIENTS.SPORTALGR, // web

  // RC Romania
  // GSP
  // stage
  b011f2460b58410281a1bac2d460d352: CLIENTS.GSP,
  // prod
  '83131823a2d24a52903c5bde95bbe4ef': CLIENTS.GSP,
  // Libertatea
  // stage
  '6027fbc2ac7d4ca6be97ab85e6795aae': CLIENTS.LIBERTATEA,
  // prod
  '9424277899464761a4d04dac5c0cd1b6': CLIENTS.LIBERTATEA,

  ringier: CLIENTS.RINGIER,
  ringierelearning: CLIENTS.RINGIERELEARNING,

  // RC Bulgaria
  sportal: CLIENTS.SPORTAL,
  // RC Hungary
  blikk: CLIENTS.BLIKK,
  egeszsegkalauz: CLIENTS.EGESZSEGKALAUZ,
  glamour: CLIENTS.GLAMOUR,
  kiskegyed: CLIENTS.KISKEGYED,
  noizz: CLIENTS.NOIZZ,
  ruzs: CLIENTS.RUZS,
  sportalhu: CLIENTS.SPORTALHU,
  lapot: CLIENTS.LAPOT,
  // RC Serbia
  blic: CLIENTS.BLIC,
  'super recepti': CLIENTS.SUPERRECEPTI,
  sportalsr: CLIENTS.SPORTALSR,
  zena: CLIENTS.ZENA,
  'puls online': CLIENTS.PULSONLINE,
  ana: CLIENTS.ANA,
  nekretnine: CLIENTS.NEKRETNINE,
  blictv: CLIENTS.BLICTV,
  // RC Slovakia
  sportsk: CLIENTS.SPORTSK,
  aktuality: CLIENTS.AKTUALITY,
  najmama: CLIENTS.NAJMAMA,
  'herna zona': CLIENTS.HERNAZONA,
  zive: CLIENTS.ZIVE,
  recepty: CLIENTS.RECEPTY,
  kalendar: CLIENTS.KALENDAR,
  'dobru chut': CLIENTS.DOBRUCHUT,
  slovnik: CLIENTS.SLOVNIK,
  diva: CLIENTS.DIVA,
  abola: CLIENTS.ABOLA,
  sportalgr: CLIENTS.SPORTALGR,
  gsp: CLIENTS.GSP,
  libertatea: CLIENTS.LIBERTATEA,
}

// TODO merge ID_LIST and CLIENT_MAP from theme/brandUtils
const ID_LIST = (() => {
  return {
    stg: {
      [PROFILE_TABS.sportal]: 'ecb83e3ac7e8419f820d3020f9b2b6a8',
      [PROFILE_TABS.blikk]: '673d49d28fed470bb4b71c3a2aef10bd',
      [PROFILE_TABS.egeszsegkalauz]: 'b558a43de58645a285f338e67022546d',
      [PROFILE_TABS.glamour]: '0d28225ec30646f4b3b076b680bd2652',
      [PROFILE_TABS.kiskegyed]: '0dc7ad673e8e48e99eada13e1c9a162c',
      [PROFILE_TABS.noizz]: '68e9031f49a54b7f8a1787ded6f45309',
      [PROFILE_TABS.ruzs]: 'd4d8a7ce276c42208d7aeae1240d1ce5',
      [PROFILE_TABS.sportalhu]: 'de0525ae70b280333bf481e82164d8e8',
      [PROFILE_TABS.blic]: '17ce52bf2d29419f9d85ca082614d8c8',
      [PROFILE_TABS.superrecepti]: 'f73066bd91b140698339a56dd6686d0d',
      [PROFILE_TABS.sportalsr]: '6d3353c35f2e45ca8b72e41211fd09d7',
      [PROFILE_TABS.zena]: '5053247f7484436dab5bae713651c586',
      [PROFILE_TABS.pulsonline]: '52f49f5a0efb4804a0c09184486cb8e0',
      [PROFILE_TABS.ana]: 'f2f45244b8294f018053f95fd176291e',
      [PROFILE_TABS.nekretnine]: 'bc2f9cfca724432f81b2cb13a11e0772',
      [PROFILE_TABS.abola]: '2715165c389540aea8bfceb0984c6f0b',
      [PROFILE_TABS.lapot]: '12268b309da400d3b9ce01b34b456905',
      [PROFILE_TABS.sportalgr]: 'f2bfadd26e1e4da5a8a2d419a27780c0',
      [PROFILE_TABS.gsp]: '',
      [PROFILE_TABS.libertatea]: '',
    },
    prod: {
      [PROFILE_TABS.sportal]: '76c07931dc3842f697d9d832b9223271',
      [PROFILE_TABS.blikk]: '7488389554fd47a9b3473b9b66371a62',
      [PROFILE_TABS.egeszsegkalauz]: '5efcd670f29b4a17bc12bd382a87b6b2',
      [PROFILE_TABS.glamour]: '31e156d21fb7456e8780b227ae8d7a15',
      [PROFILE_TABS.kiskegyed]: '4221e5a22c9f4b06bc56089ec3cfcfee',
      [PROFILE_TABS.noizz]: '32b06f03f7a94f4398ec391440f105d6',
      [PROFILE_TABS.ruzs]: '1acb661d83c44feb847e93bf48c7ce3a',
      [PROFILE_TABS.sportalhu]: '8858d76f25af360cbdfeff979376f36d',
      [PROFILE_TABS.blic]: 'cdc1bae0dcbd45af83c4b2143e50e9ea',
      [PROFILE_TABS.superrecepti]: '42cd817808b54de09caaf91bb97e877a',
      [PROFILE_TABS.sportalsr]: '73c9b9dd04124bad944de77e7aab2a8c',
      [PROFILE_TABS.zena]: 'e974f399d2574d0caf58383b5993ad4d',
      [PROFILE_TABS.pulsonline]: '851104cf038649bb8e0a053e3c7c145d',
      [PROFILE_TABS.ana]: '2ecfddbdbb1449cf919d2beb53f23e32',
      [PROFILE_TABS.nekretnine]: 'b890e11ae4324d319e2383658eab7623',
      [PROFILE_TABS.abola]: '04c89fc16c9a4360b51386d6d692b47b',
      [PROFILE_TABS.lapot]: 'ea77e901e733e8a2c6e54f599e66b784',
      [PROFILE_TABS.sportalgr]: 'f5a37bfb013b4d80a878668b2b3834b7',
      [PROFILE_TABS.gsp]: '',
      [PROFILE_TABS.libertatea]: '',
    },
  }[deployEnv]
})()

const UID_STORAGE_KEY = 'uid_storage_key'

const NATIVE_IDENTITY_PREFIX = 'native'

const PROFILE_SUPPORT_BY_BRAND = {}

const WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY = 'web_authn_credentials'
const WEB_AUTHN_ACCOUNTS_COOKIE_NAME = 'web_authn_accounts'

const FACE_ID = 'faceId'
const TOUCH_ID = 'touchId'
const ANY = 'anyId'

const RINGIER_DOCEBO_ELEARNING_POLICY_ERROR = 'ringier_docebo_elearning_policy'
const LOGIN_HINT_CHECK_ERROR = 'login_hint_check'

const BIOMETRIC_TYPES = [FACE_ID, TOUCH_ID, ANY]

const POST_REDIRECT_ACTION = 'post_redirect_action'

const TOTP_SETUP_VIEWS = {
  QR_CODE: 'QR_CODE',
  LINKS: 'LINKS',
  CODE: 'CODE',
}

const EMAIL_CHANGE_VERIFICATION_STATUS = {
  NOT_PURE_NATIVE: 'not_pure_native',
  EMAIL_EXISTS: 'email_exists',
  INVALID_TICKET: 'invalid_ticket',
  SUCCESS: 'email_verify_success',
}

const PROJECTS = {
  RC: 'rc',
  RCBG: 'rcbg',
  RCHU: 'rchu',
  RCSR: 'rcsr',
  RCSK: 'rcsk',
  RCPT: 'rcpt',
  RCGR: 'rcgr',
  RCRO: 'rcro',
}

const AUTHENTICATOR_NAMES = {
  [PROJECTS.RC]: 'Ringier Connect',
  [PROJECTS.RCBG]: 'Ringier Connect Bulgaria',
  [PROJECTS.RCHU]: 'Ringier Connect Hungary',
  [PROJECTS.RCSR]: 'Ringier Connect Serbia',
  [PROJECTS.RCSK]: 'Ringier Connect Slovakia',
  [PROJECTS.RCPT]: 'Ringier Connect Portugal',
  [PROJECTS.RCRO]: 'Ringier Connect Romania',
  default: 'Ringier Connect',
}

const DOMAIN_BY_ENV = {
  dev: 'd.',
  stg: 's.',
  prod: '',
}

const BASE_DOMAIN_BY_PROJECT = {
  [PROJECTS.RC]: 'rc.ringier.ch',
  [PROJECTS.RCBG]: 'accounts.sportal.bg',
  [PROJECTS.RCHU]: 'login.ringier.hu',
  [PROJECTS.RCSR]: 'sso.ringier.rs',
  [PROJECTS.RCSK]: 'rc.aktuality.sk',
  [PROJECTS.RCPT]: 'sso.abola.pt',
  [PROJECTS.RCGR]: 'account.sportal.gr',
  [PROJECTS.RCRO]: 'login-romania.rc.ringier.com',
}

export {
  PAGE_TYPES,
  BRANDS,
  CLIENTS,
  CLIENT_MAP,
  FEDERATED_PROVIDERS,
  PROFILE_TABS,
  ID_LIST,
  ELEMENTS_IDS,
  UID_STORAGE_KEY,
  GP_NUMBER_SUPPORT,
  LOGIN_CASES,
  NATIVE_IDENTITY_PREFIX,
  STATUS_CODES,
  PROFILE_SUPPORT_BY_BRAND,
  WEB_AUTHN_ACCOUNTS_COOKIE_NAME,
  WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY,
  BIOMETRIC_TYPES,
  RINGIER_DOCEBO_ELEARNING_POLICY_ERROR,
  LOGIN_HINT_CHECK_ERROR,
  POST_REDIRECT_ACTION,
  TOTP_SETUP_VIEWS,
  EMAIL_CHANGE_VERIFICATION_STATUS,
  PROJECTS,
  AUTHENTICATOR_NAMES,
  DOMAIN_BY_ENV,
  BASE_DOMAIN_BY_PROJECT,
  SPORTS_PT,
  CLUBS_PT,
}
