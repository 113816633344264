import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import View from 'components/View'

const getPadding = (props) =>
  props.theme.shell.footer.brandsInnerViewPaddingMobile
    ? `padding: ${props.theme.shell.footer.brandsInnerViewPaddingMobile}`
    : null

const FooterBrandsView = styled(View)`
  background-color: ${(props) => props.theme.shell.footer.backgroundColor};
  ${getPadding}

  ${breakpoint('portrait')`
      padding: ${(props) => props.theme.shell.footer.brandsInnerViewPadding};
  `};
`

export default FooterBrandsView
