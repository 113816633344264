import qs from 'qs'
import * as asyncUtils from 'utils/asyncUtils'
import {getLang} from 'utils/stringUtils'
import config from 'config'

class NewslettersService {
  constructor(token, brand) {
    if (token) {
      this.token = `Bearer ${token}`
    }
    if (brand) {
      this.brand = brand
    }
    this.lang = getLang()
  }

  fetchNewsletters = () => {
    const query = qs.stringify({lang: this.lang})
    const url = `${config.backendUrl}/v2/newsletters?${query}`
    return asyncUtils.fetchRest(url, 'GET', undefined, {
      Authorization: this.token,
    })
  }

  fetchOnePagerNewsletters = (email) => {
    const query = qs.stringify({email, lang: this.lang})
    const url = `${config.backendUrl}/v2/newsletters/${this.brand}?${query}`
    return asyncUtils.fetchRest(url, 'GET', null, {})
  }
}

export default NewslettersService
