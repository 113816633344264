import styled from 'styled-components'
import get from 'lodash/get'

const getBorderRadius = (props) => get(props.theme, 'checkbox.borderRadius', '0px')

const CheckBoxWrapper = styled.div.attrs({
  className: ({error, className}) => `${className} ${error ? 'error' : ''}`,
})`
  display: flex;
  flex-direction: column;
  border-radius: ${getBorderRadius};
`

export default CheckBoxWrapper
