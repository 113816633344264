import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import get from 'lodash/get'

import CheckBoxIcon from './CheckBoxIcon'

const getPortraitFont = (props) => {
  return props.groupItem ? get(props.theme.checkbox.group, 'portrait.fontSize', '') : ''
}

const getFontSize = (props) => (props.groupItem ? props.theme.checkbox.group.fontSize : props.theme.font.mobile.size)
const getFontWeight = (props) => (props.groupItem ? props.theme.checkbox.group.fontWeight : '')
const getFontFamily = (props) => (props.groupItem ? props.theme.checkbox.group.family : '')
const getLineHeight = (props) => props.theme.checkbox.labelFontHeight
const getColor = (props) => props.color || props.theme.checkbox.labelColor || props.theme.field.label.color

const CheckBoxLabelView = styled.label`
  font-size: ${getFontSize};
  font-weight: ${getFontWeight};
  font-family: ${getFontFamily};
  line-height: ${getLineHeight};

  color: ${getColor};
  display: inline-flex;
  width: 100%;

  ${breakpoint('portrait')`
    font-size: ${getPortraitFont};
  `};
`

const CheckBoxLabel = ({name, label, styles = {}, disabled, error, groupItem}) => {
  return (
    <CheckBoxLabelView htmlFor={name} groupItem={groupItem} {...styles}>
      <CheckBoxIcon disabled={disabled} error={error} />
      {label}
    </CheckBoxLabelView>
  )
}

export {CheckBoxLabelView}

export default CheckBoxLabel
