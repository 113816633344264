import React from 'react'
import styled from 'styled-components'

// TODO should be discussed and refactored
const ErrorText = styled(
  ({radio, padding, margin, radioMargin, fontColor, backgroundColor, fontSize, lineHeight, ...props}) => (
    <div {...props} />
  )
).attrs({
  padding: ({theme}) => theme.field.error.padding || '8px 20px',
  margin: ({theme}) => theme.field.error.margin || '0 -20px -16px',
  radioMargin: ({theme}) => theme.radio.errorMargin,
  fontColor: ({theme}) => theme.field.error.fontColor,
  backgroundColor: ({theme}) => theme.field.error.background,
  fontSize: ({theme}) => theme.field.error.size,
  lineHeight: ({theme}) => theme.field.error.height,
})`
  color: ${({fontColor}) => fontColor};
  padding: ${({padding}) => padding};
  margin: ${({radio, margin, radioMargin}) => (radio ? radioMargin : margin)};
  box-sizing: border-box;
  background-color: ${({backgroundColor}) => backgroundColor};
  font-size: ${({fontSize}) => fontSize};
  line-height: ${({lineHeight}) => lineHeight};
`

export default ErrorText
