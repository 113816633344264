import styled from 'styled-components'
import get from 'lodash/get'

const getColor = (props) => props.theme.link.footer
const getHoverColor = (props) => props.theme.link.footerHover || props.theme.link.footer
const getFontSize = (props) =>
  get(props, 'theme.shell.footer.link.fontSize') || props.theme.shell.footer.fontSize || props.theme.font.mobile.size
const getLineHeight = (props) => props.theme.shell.footer.lineHeight || props.theme.font.mobile.height
const getTextDecoration = () => 'underline'
const getHoverTextDecoration = (props) => get(props, 'theme.link.textDecorationHover', getTextDecoration(props))

const FooterLink = styled.a`
  display: block;
  text-decoration: ${getTextDecoration};
  cursor: pointer;
  color: ${getColor};
  font-size: ${getFontSize};
  line-height: ${getLineHeight};
  text-align: center;

  &:hover {
    color: ${getHoverColor};
    text-decoration: ${getHoverTextDecoration};
  }
`

export default FooterLink
