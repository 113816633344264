import React, {Fragment} from 'react'

import CheckBox from 'apps/consent-app/main-form/components/MainFormCheckBox'
import Optional from 'components/Optional'
import CheckBoxGroupLabel from 'components/checkbox/CheckBoxGroup/CheckBoxGroupLabel'

const MainFormCheckBox = ({label, t, ...props}) => {
  return (
    <Fragment>
      <CheckBoxGroupLabel display="block">
        {t('inputs.marketing')}
        {!props.required && <Optional withMargin />}
      </CheckBoxGroupLabel>
      <CheckBox {...props} label={<span>{t('checkboxes.marketing')}</span>} />
    </Fragment>
  )
}

export default MainFormCheckBox
