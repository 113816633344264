import Cookies from 'js-cookie'
import {Buffer} from 'buffer/'
import {
  getHeliosUrlBaseUrlOverride,
  getInteractionUid,
  getSource,
  getLoginCase,
  getLang,
  getClientIdFromGACookie,
} from 'utils/stringUtils'
import {fetchRest} from 'utils/asyncUtils'
import {getTheme} from 'theme/brands/brandUtils'
import {getDeviceType} from 'utils/browserUtils'
import {isDeviceTypeAllowed} from 'utils/deviceUtils'
import {sendGAEventsOnSignUp} from 'utils/signUpUtils'
import {GoogleAnalyticsService} from 'services/GoogleAnalyticsService'
import {
  NATIVE_IDENTITY_PREFIX,
  WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY,
  WEB_AUTHN_ACCOUNTS_COOKIE_NAME,
  DOMAIN_BY_ENV,
  BASE_DOMAIN_BY_PROJECT,
} from 'const'
import config from 'config'

const deployEnv = process.env.REACT_APP_ENV || 'dev'
class HostedLoginService {
  constructor() {
    this.brand = getTheme(window).brand.name.toLowerCase()
  }

  loginWithMicrosoft = async () => {
    return this.loginWithIdentityProvider('microsoft')
  }

  loginWithFacebook = async () => {
    return this.loginWithIdentityProvider('facebook')
  }

  loginWithGoogle = async () => {
    return this.loginWithIdentityProvider('google')
  }

  loginWithApple = async () => {
    return this.loginWithIdentityProvider('apple')
  }

  loginWithSwissID = async () => {
    return this.loginWithIdentityProvider('swissid')
  }

  checkIfBiometricLoginAvailable = async () => {
    const isPlatformAuthenticatorAvailable = await this.hasWebAuthnSupport()
    if (isPlatformAuthenticatorAvailable) {
      // check cookies
      const credentialIds = JSON.parse(Cookies.get(WEB_AUTHN_CREDENTIALS_LOCAL_STORAGE_KEY) || '[]')
      const accounts = JSON.parse(Cookies.get(WEB_AUTHN_ACCOUNTS_COOKIE_NAME) || '[]')
      if (accounts.length > 0 && credentialIds.length > 0) {
        // request IDS for a challenge
        const challengeUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/webauthn/challenge`
        const {validCredentialIds, challenge} = await fetchRest(challengeUrl, 'POST', {credentialIds})
        if (Object.keys(validCredentialIds).length > 0) {
          return {platformAuthenticatorAvailable: true, validCredentials: validCredentialIds, challenge}
        }
      }
    }
    return {platformAuthenticatorAvailable: false, validCredentials: {}}
  }

  loginWithBiometrics = async (allowedCredentials, challenge) => {
    const publicKey = {
      rpId: `${DOMAIN_BY_ENV[deployEnv]}${BASE_DOMAIN_BY_PROJECT[config.project]}`,
      userVerification: 'required',
      allowCredentials: allowedCredentials.map((credentialId) => ({
        transports: ['internal'],
        type: 'public-key',
        id: new Uint8Array(new Buffer(credentialId, 'hex')),
      })),
      challenge: new TextEncoder().encode(challenge),
    }

    const {rawId, response} = await navigator.credentials.get({publicKey})
    const credentialVerifyUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/webauthn/verify`
    const {ticket, bid} = await fetchRest(credentialVerifyUrl, 'POST', {
      id: Buffer.from(rawId).toString('hex'),
      credentialUserId: {
        type: 'Buffer',
        data: Array.from(new Uint8Array(response.userHandle)),
      },
      signature: {
        type: 'Buffer',
        data: Array.from(new Uint8Array(response.signature)),
      },
      authenticatorData: {
        type: 'Buffer',
        data: Array.from(new Uint8Array(response.authenticatorData)),
      },
      clientDataJSON: {
        type: 'Buffer',
        data: Array.from(new Uint8Array(response.clientDataJSON)),
      },
      brand: this.brand,
    })

    GoogleAnalyticsService.trackLoginSuccess(bid, 'webauthn')

    const lang = getLang()
    const interactionUrl = this._getInteractionUrl()
    this.post(interactionUrl, {ticket, lang, connection: 'webauthn'})
  }

  loginWithIdentityProvider = async (provider, extraParams = {}) => {
    const interaction = getInteractionUid(window.location.search)
    const registrationChannel = getDeviceType()
    const lang = getLang()
    const clientId = getClientIdFromGACookie(Cookies.get('_ga'))

    const webAuthnSupport = await this.hasWebAuthnSupport()
    const params = {
      ...extraParams,
      ...(clientId && {clientId}),
      provider,
      registrationChannel,
      brand: this.brand,
      interaction,
      lang,
      webAuthnSupport,
    }

    this.post(`${process.env.REACT_APP_IDENTITY_SERVICE_URL}/federated/begin`, params)
  }

  signin = async ({email, password}) => {
    const loginUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/login`
    const interactionUrl = this._getInteractionUrl()
    const registrationChannel = getDeviceType()
    const lang = getLang()

    const {bid, ticket, isLinked} = await fetchRest(loginUrl, 'POST', {
      email,
      password,
      registrationChannel,
      lang,
      brand: this.brand,
    })

    GoogleAnalyticsService.trackLoginSuccess(bid)

    const webAuthnSupport = await this.hasWebAuthnSupport()
    this.post(interactionUrl, {
      ticket,
      isLinked,
      connection: NATIVE_IDENTITY_PREFIX,
      registrationChannel,
      lang,
      webAuthnSupport,
    })
  }

  register = async (data, brand, shouldShowNewsletters, isNameOptional) => {
    const {
      email,
      password,
      firstName,
      lastName,
      gender,
      marketing,
      agreements,
      fullAgreements,
      admeiraOptIn,
      captchaToken,
      newsletter,
    } = data
    const hasNewsletterData = Object.isObject(newsletter) && Object.keys(newsletter).length > 0 && shouldShowNewsletters
    const signupUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/signup`
    const interactionUrl = this._getInteractionUrl()
    const registrationChannel = getDeviceType()
    const source = getSource(window.location.search)
    const loginCase = getLoginCase(window.location.search)
    const lang = getLang()

    const {ticket, isLinked} = await fetchRest(
      signupUrl,
      'POST',
      {
        email,
        password,
        ...(firstName !== '' ? {firstName} : {}),
        ...(lastName !== '' ? {lastName} : {}),
        ...(gender !== '' ? {gender} : {}),
        brand,
        registrationChannel,
        marketing,
        agreements,
        source,
        loginCase,
        admeiraOptIn,
        captchaToken,
        lang,
        ...(hasNewsletterData ? {newsletter} : {}),
        ...(firstName ? {firstName} : isNameOptional ? {} : firstName),
        ...(lastName ? {lastName} : isNameOptional ? {} : lastName),
      },
      {},
      [409]
    )
    localStorage.removeItem('agreements')
    const webAuthnSupport = await this.hasWebAuthnSupport()

    GoogleAnalyticsService.checkSubscriptionsForGA(brand, newsletter)
    sendGAEventsOnSignUp(fullAgreements, true, 'native')
    await new Promise((res) => setTimeout(res, 20)) // ensure GA4 is sent

    this.post(interactionUrl, {
      ticket,
      isLinked,
      connection: NATIVE_IDENTITY_PREFIX,
      registrationChannel,
      lang,
      webAuthnSupport,
    })
  }

  forgetPassword = ({email, captchaToken}) => {
    const registrationChannel = getDeviceType()
    const loginCase = getLoginCase(window.location.search)
    const lang = getLang()
    return fetchRest(`${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/password-reset/request`, 'POST', {
      email,
      registrationChannel,
      brand: this.brand,
      loginCase,
      lang,
      captchaToken,
    })
  }

  userExists = async (email) => {
    const userExistsUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/emails/${email}?brand=${this.brand}`
    try {
      const {existsInNative, existsInLegacy, hasLegacyFieldsToMigrate} = await fetchRest(userExistsUrl, 'GET')
      return {existsInNative, existsInLegacy, hasLegacyFieldsToMigrate}
    } catch (err) {
      if (err.status === 404) {
        return {
          existsInNative: false,
          existsInLegacy: false,
          hasLegacyFieldsToMigrate: false,
        }
      } else {
        throw err
      }
    }
  }

  hasWebAuthnSupport = async () => {
    if (!window.PublicKeyCredential || !window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
      return false
    }
    // temporarily limit webauthn feature to safari/chrome on macs/iphones/ipads
    if (!isDeviceTypeAllowed()) {
      return false
    }

    return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
  }

  getBrandAgreements = async (brand) => {
    const brandAgreementsUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/native/agreements/${brand}`
    try {
      const agreements = await fetchRest(brandAgreementsUrl, 'GET', null, {})
      return agreements
    } catch (e) {
      return {}
    }
  }

  getLinkingCandidateAccountInfo = async (email) => {
    const url = `${
      process.env.REACT_APP_IDENTITY_SERVICE_URL
    }/native/linking-candidate-account-info/${encodeURIComponent(email)}`
    try {
      const response = await fetchRest(url, 'GET', null, {})
      return response
    } catch (e) {
      return {}
    }
  }

  // Post to the provided URL with the specified parameters.
  post = (path, params) => {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = path

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = encodeURIComponent(params[key])

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  }

  _getInteractionUrl = () => {
    const baseUrl =
      getHeliosUrlBaseUrlOverride(window.location.search) || `https://${process.env.REACT_APP_AUTH0_DOMAIN}`
    const interactionId = getInteractionUid(window.location.search)
    return `${baseUrl}/interaction/${interactionId}`
  }
}

export default HostedLoginService
