import React from 'react'
import styled from 'styled-components'
import config from 'config'

const LoadingView = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.nomargin ? '0px' : '20vh')};
`

const Loading = ({className, nomargin}) => {
  return (
    <LoadingView className={className} nomargin={nomargin}>
      <img src={`${config.url}/assets/loading.svg`} alt="loading" />
    </LoadingView>
  )
}

export default Loading
