import {CLIENTS} from 'const'
import merge from 'lodash/merge'
import blic from '../blic'

const theme = merge({}, blic, {
  brand: {
    name: CLIENTS.BLICTV,
  },
})

export default theme
