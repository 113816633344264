import styled from 'styled-components'

const getWidth = (props) => {
  switch (props.logosCount) {
    case 2:
      return '150px'
    default:
      return '272px'
  }
}

const FooterLogosView = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.logosCount === 1 ? 'center' : 'space-between')};
  align-items: center;
  max-width: ${getWidth};
  padding-bottom: ${(props) => props.theme.shell.footer.logoPadding || '16px'};
`

export default FooterLogosView
