import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getViewSmallPadding = (props) => (props.theme.view && props.theme.view.smallPadding) || '16px 20px 20px 20px'
const getViewPadding = (props) => (props.theme.view && props.theme.view.padding) || '20px'

const StyledView = styled.div`
  padding: ${(props) => (props.smallpadding ? getViewSmallPadding : getViewPadding)};

  ${breakpoint('portrait')`
      padding: ${(props) => (props.smallpadding ? '20px 40px 40px 40px' : '40px')};
  `};
`

const getWidth = (props) => !props.wide && '48.7%'
const getMaxWidth = (props) => props.wide && '624px'

const InnerView = styled.div`
  margin: auto;

  ${breakpoint('mobile')`
      width: 82.5%
  `};

  ${breakpoint('portrait')`
      width: 65.7%
  `};

  ${breakpoint('landscape')`
      width: ${getWidth};
      max-width: ${getMaxWidth};
  `};
`

const getWideWidth = (props) => !props.wide && '68.7%'

const WideInnerView = styled.div`
  margin: auto;

  ${breakpoint('mobile')`
      width: 82.5%
  `};

  ${breakpoint('portrait')`
      width: 84.5%
  `};

  ${breakpoint('landscape')`
      width: ${getWideWidth};
      max-width: ${getMaxWidth};
  `};
`

const View = ({children, className, wide, smallpadding}) => (
  <StyledView className={className} smallpadding={smallpadding}>
    <InnerView wide={wide}>{children}</InnerView>
  </StyledView>
)

const WiderView = ({children, className, wide}) => (
  <StyledView className={className}>
    <WideInnerView wide={wide}>{children}</WideInnerView>
  </StyledView>
)

export {InnerView, WiderView, WideInnerView}
export default View
