import config from 'config'
import {PROJECTS} from 'const'

const PUBLIC_URL = process.env.PUBLIC_URL || ''

const assetsUrl = `${PUBLIC_URL}/assets/`

export function mapLogos(logos) {
  return logos.map((logo) => Object({src: `${assetsUrl}${logo}`}))
}

export function getIcon(icon) {
  return {
    height: '20px',
    portraitHeight: '30px',
    landscapeHeight: '30px',
    desktopHeight: '32px',
    ...icon,
  }
}

export function getShouldShouwMoreSoonByProject() {
  switch (config.project) {
    case PROJECTS.RCBG:
      return true
    case PROJECTS.RCSK:
      return true
    default:
      return false
  }
}

export function getBannerFooterLogosByProject() {
  if (config.project === PROJECTS.RCBG) {
    return [
      {
        icon: `${config.url}/assets/brands/sportal/logo-sportal-dark-theme.svg`,
        footerHeight: '20px',
        ringierSectionHeight: '20px',
      },
    ]
  }
  if (config.project === PROJECTS.RCHU) {
    return [
      {
        icon: `${config.url}/assets/brands/blikk/logo-blikk-gray.svg`,
        footerHeight: '16px',
        ringierSectionHeight: '16px',
      },
      {
        icon: `${config.url}/assets/brands/glamour/logo-glamour-gray.svg`,
        footerHeight: '12px',
        ringierSectionHeight: '12px',
      },
      {
        icon: `${config.url}/assets/brands/kiskegyed/logo-kiskegyed-gray.svg`,
        footerHeight: '16px',
        ringierSectionHeight: '16px',
      },
      {
        icon: `${config.url}/assets/brands/ruzs/logo-ruzs-gray.svg`,
        footerHeight: '18px',
        ringierSectionHeight: '18px',
      },
    ]
  }
  if (config.project === PROJECTS.RCSR) {
    return [
      {
        icon: `${config.url}/assets/brands/blic/logo-blic-gray.svg`,
        footerHeight: '20px',
        ringierSectionHeight: '20px',
      },
      {
        icon: `${config.url}/assets/brands/sportalsr/logo-sportalsr-gray.svg`,
        footerHeight: '14px',
        ringierSectionHeight: '14px',
      },
      {
        icon: `${config.url}/assets/brands/nekretnine/logo-nekretnine-gray.svg`,
        footerHeight: '10px',
        ringierSectionHeight: '10px',
      },
      {
        icon: `${config.url}/assets/brands/zena/logo-zena-gray.svg`,
        footerHeight: '14px',
        ringierSectionHeight: '14px',
      },
    ]
  }
  if (config.project === PROJECTS.RCSK) {
    return [
      {
        icon: `${config.url}/assets/brands/sportsk/logo-sportsk-gray.svg`,
        footerHeight: '20px',
        ringierSectionHeight: '20px',
      },
    ]
  }
  if (config.project === PROJECTS.RCPT) {
    return [
      {icon: `${config.url}/assets/brands/abola/logo-abola.svg`, footerHeight: '20px', ringierSectionHeight: '20px'},
    ]
  }
  if (config.project === PROJECTS.RCGR) {
    return [
      {
        icon: `${config.url}/assets/brands/sportalgr/logo-sportalgr-grey.svg`,
        footerHeight: '20px',
        ringierSectionHeight: '20px',
      },
    ]
  }
  if (config.project === PROJECTS.RCRO) {
    return [
      {
        icon: `${config.url}/assets/logos-ro-footer.svg`,
        footerHeight: '28px',
        ringierSectionHeight: '24px',
      },
    ]
  }
  return []
}

export const getHeaderLogo = (brandName) => `brands/${brandName}/logo.svg`

export const getPortraitLogo = (brandName) => `brands/${brandName}/logo-portrait.svg`
