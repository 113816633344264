import React, {Fragment} from 'react'

import Bold from 'components/Bold'

const ErrorText = ({error, description}) => {
  return (
    <Fragment>
      <Bold>{error}</Bold>
      <br />
      {description}
    </Fragment>
  )
}

export default ErrorText
