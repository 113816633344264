import merge from 'lodash/merge'
import config from 'config'
import {CLIENTS} from 'const'
import blikk from '../blikk'

const theme = merge({}, blikk, {
  brand: {
    name: CLIENTS.KISKEGYED,
    icon: {
      url: 'brands/kiskegyed/logo-kiskegyed.svg',
      href: 'https://www.kiskegyed.hu/',
    },
    url: 'https://www.kiskegyed.hu/',
    favicon: `${config.url}/assets/brands/kiskegyed/favicon.ico`,
  },
  shell: {
    header: {
      icon: {
        image: 'brands/kiskegyed/logo-kiskegyed.svg',
        height: '32px',
        portrait: {image: 'brands/kiskegyed/logo-kiskegyed.svg', height: '40px'},
        href: 'https://www.kiskegyed.hu/',
      },
    },
  },
  otherBrandsLogos: [
    {
      icon: `${config.url}/assets/brands/blikk/logo-blikk-black.svg`,
      footerHeight: '16px',
      ringierSectionHeight: '16px',
    },
    {
      icon: `${config.url}/assets/brands/glamour/logo-glamour-black.svg`,
      footerHeight: '12px',
      ringierSectionHeight: '12px',
    },
    {
      icon: `${config.url}/assets/brands/kiskegyed/logo-kiskegyed-black.svg`,
      footerHeight: '16px',
      ringierSectionHeight: '16px',
    },
    {
      icon: `${config.url}/assets/brands/egeszsegkalauz/logo-ek-black.svg`,
      footerHeight: '18px',
      ringierSectionHeight: '18px',
    },
  ],
})

export default theme
