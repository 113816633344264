import styled from 'styled-components'

const getHeight = (props) => props.height

const FooterLogo = styled.img`
  object-fit: contain;
  height: ${getHeight};
`

export default FooterLogo
