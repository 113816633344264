import config from 'config'
import {CLIENTS} from 'const'
import {getIcon} from '../commonHelper'

const NORMAL_FONT = "'Ringier-Light', Times New Roman, sans-serif"
const BOLD_FONT = "'Ringier-Bold', Times New Roman, sans-serif"
const BOOK_FONT = "'Ringier-Book', Times New Roman, sans-serif"

export default {
  brand: {
    name: CLIENTS.RINGIERELEARNING,
    url: (config.links || {}).about,
    icon: {
      url: 'logo_Ringier.svg',
      height: '20px',
      portraitHeight: '40px',
      landscapeHeight: '46px',
    },
    favicon: `${config.url}/assets/favicon.ico`,
    fontColor: '#000000',
  },
  fontConfig: {
    custom: {
      urls: [`${config.url}/fonts/ringier/index.css`],
    },
  },
  font: {
    family: NORMAL_FONT,
    boldFamily: BOLD_FONT,
    boldFontWeight: '700',
    size: '14px',
    height: '16px',
    mobile: {
      size: '16px',
      height: '22px',
    },
    portrait: {
      size: '18px',
      height: '24px',
    },
    color: {
      dark: '#000000',
      light: '#ffffff',
    },
  },
  ringierBanner: {
    landscape: {
      fontSize: '16px',
    },
    titleFontWeight: 'bold',
    titleFontFamily: BOLD_FONT,
    titleFontSize: '18px',
    linkFontSize: '18px',
    linkColor: '#000000',
  },
  otherBrandsLogos: [],
  notification: {
    family: '',
    success: '#009164',
    error: '#D35052',
    warning: '#FFB120',
    pageError: '#D35052',
    info: '#009FE3',
    icon: {
      width: '24px',
      height: '24px',
      mobile: {
        width: '32px',
        height: '32px',
      },
      closeImage: 'icon-close.svg',
      imageFunc: (type) => `icon-${type}.svg`,
    },
    pageMessage: {
      size: '18px',
      height: '24px',
      sizeMobile: '14px',
      heightMobile: '20px',
    },
  },
  customerNumber: {
    marginBottom: '20px',
    padding: '',
    backgroundColor: '',
    helpIcon: {
      img: 'RC-icon-hint.svg',
      hoverImg: 'RC-icon-hint-hover.svg',
      top: '10px',
      right: '14px',
    },
    closeIcon: {
      color: '#878787',
      margin: '2px 1px 0 10px',
    },
    warning: {},
    buttons: {},
    success: {},
  },
  shell: {
    header: {
      hideRingier: true,
      hideRingierOnMobile: true,
      color: '#323232',
      padding: '18px 20px',
      boxShadow: '0 2px 4px -2px rgba(0, 0, 0, 0.5)',
      logoMarginRight: {
        default: '0',
        portrait: '0',
      },
      icon: {
        image: 'logo-ringierconnect-white.svg',
        height: '32px',
        portrait: {
          height: '40px',
        },
      },
      font: {
        color: '#000000',
        size: '12px',
        height: '12px',
        family: NORMAL_FONT,
      },
      height: {
        mobile: '110px',
        portrait: '80px',
        default: '60px',
      },
      ringierLogo: {
        img: 'logo-ringier-black.svg',
        lineHeight: '12px',
        default: {
          height: '18px',
          width: '90px',
        },
        portrait: {
          height: '17px',
          width: '88px',
          marginTop: '12px',
        },
      },
      user: {
        font: {
          default: {
            size: '14px',
            height: '18px',
          },
          portrait: {
            size: '14px',
            height: '14px',
          },
        },
        showLoginAs: true,
      },
    },
    footer: {
      copyright: {
        fontColor: '#DCDCDC',
        darkFontColor: '#F1F1F1',
        backgroundColor: '#323232',
        hover: '#000000',
        darkHover: '#FFFFFF',
        darkPadding: '12px 0',
      },
      backgroundColor: '#F0F0F0',
      color: '#323232',
      family: BOLD_FONT,
      brandsInnerViewPadding: '20px 40px 24px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      logoHeight: '22px',
    },
  },
  title: {
    family: NORMAL_FONT,
    size: '32px',
    height: '30px',
    mobile: {
      size: '52px',
      height: '54px',
    },
    portrait: {
      size: '52px',
      height: '54px',
    },
  },
  background: {
    light: '#ffffff',
    dark: ' #47484a',
  },
  note: {
    lightFamily: NORMAL_FONT,
    family: BOLD_FONT,
    termsTitle: {
      fontWeight: 'bold',
      fontFamily: BOLD_FONT,
    },
  },
  buttons: {
    height: '40px',
    primary: {
      border: '1px solid #323232',
      background: '#323232',
      color: '#ffffff',
      hoverBorder: '1px solid #000000',
      hoverBackground: '#000000',
      hoverColor: '#ffffff',
      activeBorder: '1px solid #009FE3',
      activeBackground: '1px solid #009FE3',
    },
    secondary: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverColor: '#000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2p solid #009FE3',
      activeColor: '#009FE3',
      activeBackground: '#FFFFFF',
    },
    disabled: {
      color: '#878787',
      hoverColor: '#878787',
      border: '1px solid #DCDCDC',
      background: '#DCDCDC',
      hoverBorder: null,
      hoverBackground: '#DCDCDC',
      activeBorder: null,
      activeBackground: '#DCDCDC',
    },
    icon: {
      height: '36px',
      width: '38px',
    },
    text: {
      family: BOLD_FONT,
      color: '#000000',
      size: '18px',
    },
    border: {
      size: '2px',
      radius: '0',
    },
    swiss: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-SwissID-24x24.svg',
      iconStyles: {
        marginLeft: '0',
        background: '#E30613',
        hoverBackground: '#E30613',
      },
      textStyles: {
        weight: 300,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    facebook: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-facebook-24x24.svg',
      iconStyles: {
        marginLeft: '0',
      },
      textStyles: {
        weight: 300,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    apple: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'logo-apple-black.svg',
      iconStyles: {
        marginLeft: '0',
      },
      textStyles: {
        weight: 300,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    google: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-google-24x24.svg',
      iconStyles: {
        marginLeft: '0',
      },
      textStyles: {
        weight: 300,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    microsoft: {
      border: '2px solid #323232',
      color: '#323232',
      background: '#FFFFFF',
      hoverBorder: '2px solid #000000',
      hoverBackground: '#FFFFFF',
      activeBorder: '2px solid #000000',
      activeBackground: '#FFFFFF',
      icon: 'icon-microsoft-24x24.svg',
      iconStyles: {
        marginLeft: '0',
      },
      textStyles: {
        weight: 300,
      },
      hoverColor: '#000000',
      activeColor: '#000000',
      isAnimated: false,
      full: true,
    },
    touchId: {
      border: '1px solid #E20000',
      color: '#ffffff',
      background: '#E20000',
      hoverBorder: '1px solid #FF0000',
      hoverBackground: '#FF0000',
      activeBorder: '1px solid #000000',
      activeBackground: '#000000',
      icon: 'icon-touchid.svg',
      iconStyles: {padding: '12px', marginLeft: '0'},
    },
    faceId: {
      border: '1px solid #E20000',
      color: '#ffffff',
      background: '#E20000',
      hoverBorder: '1px solid #FF0000',
      hoverBackground: '#FF0000',
      activeBorder: '1px solid #000000',
      activeBackground: '#000000',
      icon: 'icon-faceid.svg',
      iconStyles: {padding: '12px', marginLeft: '5px'},
    },
    anyId: {
      border: '1px solid #E20000',
      color: '#ffffff',
      background: '#E20000',
      hoverBorder: '1px solid #FF0000',
      hoverBackground: '#FF0000',
      activeBorder: '1px solid #000000',
      activeBackground: '#000000',
    },
  },
  logisterForm: {
    border: '',
    background: '',
    padding: '20px 20px 20px 0px',
    landscapePadding: '40px',
    submitButton: 'primary',
    forgetLinkFloat: '',
  },
  registerPage: {
    backLinkMarginBottom: {
      default: '20px',
      portrait: '20px',
    },
    titleMargin: {
      default: '0 0 20px',
      portrait: '0 0 20px',
    },
    submitButtonMarginTop: 20,
  },
  secondaryFont: {
    size: '14px',
    height: '16px',
    mobile: {
      size: '16px',
      height: '22px',
    },
    portrait: {
      size: '18px',
      height: '24px',
      backlink: '16px',
    },
  },
  separator: {
    background: ' #acacac',
    color: '#919191',
    height: '1px',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '0 20px 0 0',
    portraitMargin: '40px',
    overrideMargin: '24px 0 40px 0',
  },
  tab: {
    fontSize: '18px',
    desktop: {
      focusBorder: 'solid 4px #000000',
      bottomMargin: '-4px',
      border: 'solid 4px #DCDCDC',
      height: '80px',
    },
    mobile: {
      icon: {
        border: 'solid 2px #ACACAC',
        width: '14px',
        height: '14px',
        color: '#000000',
      },
    },
  },
  logisterSection: {
    subheaderPadding: '12px 20px',
    subheaderFontSize: '16px',
    subheaderLineHeight: '24px',
    subheaderTitleColor: '#000000',
    subheaderLinkColor: '#545454',
    subheaderLinkHoverColor: '#FF0000',
    subheaderBackgroundColor: '#F8F8F8',
    noteFontSize: {
      default: '12px',
      portrait: '14px',
    },
    title: {
      textChangeBreakpoint: 'landscape',
      fontSize: {
        smallMobile: '30px',
        mobile: '30px',
        portrait: '36px',
        landscape: '40px',
        desktop: '40px',
      },
      lineHeight: {
        smallMobile: '32px',
        mobile: '32px',
        portrait: '44px',
        landscape: '44px',
        desktop: '44px',
      },
    },
    registerMarginTop: '20px',
    socialButtonWidth: 'calc(100% - 20px)',
  },
  emailVerifyPage: {
    note: {
      fontFamily: BOLD_FONT,
    },
  },
  loginForm: {
    forgetLink: {
      wrapper: {
        defaultMarginBottom: '20px',
        errorMarginBottom: '22px',
      },
      link: {
        fontSize: '14px',
        marginTop: '6px',
      },
    },
  },
  form: {
    sectionViewBackground: '#f8f8f8',
    loginSubmitMarginTop: '20',
    marginBottom: '8px',
    note: {
      fontSize: '18px',
    },
    border: '',
    background: '',
    padding: '0 0 16px 0',
    fieldBackground: '',
  },
  field: {
    error: {
      background: '',
      fontColor: '#D35052',
      size: '16px',
      height: '22px',
    },
    label: {
      color: '',
      disabledColor: '#000000',
      family: BOLD_FONT,
      fontSize: '18px',
      fontHeight: '24px',
      bottomMargin: '8px',
    },
    edging: {
      backgroundColor: '#f0f0f0',
    },
    optional: {
      family: NORMAL_FONT,
    },
  },
  input: {
    border: '2px solid #878787',
    focusBorder: '2px solid #323232',
    errorBorder: '2px solid #D35052',
    errorBackground: '#FFF0F0',
    hoverBorder: '2px solid #000000',
    height: '40px',
    email: {
      backgroundColor: '#FFFFFF',
      color: '#878787',
      fontSize: '18px',
      border: '2px solid #EFEFEF',
      overrideBackground: '#FFFFFF',
      button: {
        height: '32px',
        right: '12px',
        color: '#323232',
        fontSize: '16px',
        padding: '0',
        textDecoration: 'underline',
        border: '0',
        borderRight: '0',
        hoverColor: '#000000',
        hoverBorder: '0',
        hoverBorderRight: '0',
        backgroundColor: '#FFFFFF',
      },
    },
    password: {
      color: '#323232',
      hoverColor: '#000000',
      size: '16px',
      height: '18px',
      top: '10px',
    },
    loading: {
      top: '3px',
    },
  },
  inputNote: {
    family: BOOK_FONT,
    color: '#545454',
    size: '14px',
    height: '18px',
    marginTop: '4px',
  },
  textarea: {
    height: '80px',
  },
  link: {
    default: '#000000',
    hoverColor: '#323232',
    secondary: '#545454',
    secondaryHover: '#FF0000',
    footer: '#323232',
    align: 'start',
    agreement: '#000000',
    isAgreementsLink: true,
  },
  radio: {
    icon: {
      width: '20px',
      height: '20px',
      image: 'RC-form-radio.svg',
      checkedImage: 'RC-form-radio-active.svg',
      hoverImage: 'RC-form-radio-hover.svg',
      errorImage: 'RC-form-radio-error.svg',
      disabledImage: 'NB-form-radio.svg',
    },
    errorMargin: '20px -20px -16px',
    labelFontHeight: '25px',
  },
  errorPage: {
    font: {
      height: '18px',
      portrait: {
        height: '30px',
      },
    },
  },
  checkbox: {
    errorMargin: '20px -20px -16px',
    padding: '20px',
    errorPadding: '4px 20px 0',
    icon: {
      height: '20px',
      width: '20px',
      top: '3px',
      right: '12px',
      image: 'RC-form-checkbox.svg',
      checkedImage: 'RC-form-checkbox-checked.svg',
      hoverImage: 'RC-form-checkbox-hover.svg',
      errorImage: 'RC-form-checkbox-error.svg',
      disabledImage: 'NB-form-checkbox.svg',
    },
    labelFontHeight: '25px',
    group: {
      marginBottom: '',
      padding: '',
      backgroundColor: '',
      fontWeight: '500',
      fontSize: '16px',
      family: BOLD_FONT,
      note: {
        fontWeight: '300',
        fontSize: '16px',
        marginLeft: '32px',
        marginTop: '4px',
        lineHeight: '22px',
      },
      portrait: {
        fontSize: '18px',
      },
      label: {
        marginBottom: '0',
        padding: '20px 0 0 20px',
        backgroundColor: '#f6f6f6',
      },
    },
  },
  select: {
    icon: {
      width: '16px',
      height: '16px',
      image: 'from-arrow-down.svg',
      alt_image: 'from-arrow-down.svg',
    },
    menu: {
      border: '1px solid #545454',
      option: {
        focusBorder: '0',
        focusBackground: '#F1F1F1',
      },
    },
  },
  footerLogos: [
    getIcon({
      url: `${config.url}/assets/logo-hz-grey.svg`,
      height: '18px',
    }),
    getIcon({
      url: `${config.url}/assets/brands/blic/logo-blick-footer.svg`,
      height: '24px',
    }),
    getIcon({
      url: `${config.url}/assets/logo-bilanz-grey.svg`,
      height: '14px',
    }),
    getIcon({
      url: `${config.url}/assets/brands/beo/logo-beobachter-grey.svg`,
      height: '14px',
    }),
  ],
  mobileNumber: {
    code: {
      letterSpacing: '8px',
      paddingLeft: {
        mobile: {
          normal: 'calc(50% - 50px)',
          error: 'calc(50% - 51px)',
        },
        desktop: {
          normal: 'calc(25% - 50px)',
          error: 'calc(25% - 51px)',
        },
      },
      underline: {
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 9px)',
        desktopLeft: 'calc(25% - 19px)',
        desktopTop: 'calc(50% - 12px)',
      },
    },
  },
  consent: {
    checkbox: {
      marginBottom: '8px',
      paddingBottom: '16px',
    },
  },
  address: {
    padding: '',
    inputBackgroundColor: '',
    formFieldMarginBottom: '',
    checkboxPadding: '',
    link: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  languageSwitcher: {
    color: '#323232',
    separatorColor: 'rgba(50, 50, 50, 0.6)',
  },
}
