import 'core-js/fn/array/keys'
import 'core-js/fn/array/from'
import 'core-js/fn/object'
import 'core-js/fn/array/includes'
import 'core-js/fn/array/find'
import 'core-js/fn/array/find-index'
import 'core-js/fn/string/pad-start'
import 'core-js/fn/string/includes'
import 'core-js/fn/string/starts-with'
import 'react-app-polyfill/ie11'
import 'fast-text-encoding'

import React from 'react'
import ReactDOM from 'react-dom'

import Root from 'Root'

ReactDOM.render(<Root />, document.getElementById('root'))
