import React from 'react'

import theme from 'theme'
import * as envUtils from 'utils/envUtils'

const isMobile = () => window.innerWidth < theme.breakpoints.portrait
const isLandscape = () => window.innerWidth > theme.breakpoints.landscape

class DisplaySize extends React.Component {
  state = {mobile: envUtils.isBrowser() && isMobile(), landscape: envUtils.isBrowser() && isLandscape()}

  componentDidMount() {
    window.addEventListener('resize', this.resizeThrottler, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeThrottler, false)
  }

  resizeTimeout
  resizeThrottler = () => {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        this.actualResizeHandler()
      }, 200)
    }
  }

  actualResizeHandler = () => {
    const mobile = isMobile(window.innerWidth)
    const landscape = isLandscape(window.innerWidth)

    if (mobile !== this.state.mobile || landscape !== this.state.landscape) {
      this.setState({mobile, landscape})
    }
  }

  render() {
    return React.cloneElement(this.props.children, {
      mobile: this.state.mobile,
      landscape: this.state.landscape,
    })
  }
}

export const withDisplaySize = (WrappedComponent) => (props) => {
  return (
    <DisplaySize>
      <WrappedComponent {...props} />
    </DisplaySize>
  )
}

export default DisplaySize
