import React from 'react'

import Logo from './Logo'
import LogosView from './LogosView'

const Logos = ({logos}) => {
  return (
    <LogosView>
      <Logo src={logos[0].icon} height={logos[0].ringierSectionHeight} />
    </LogosView>
  )
}

export default Logos
