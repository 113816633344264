import React, {Fragment} from 'react'
import {translate} from 'react-i18next'
import styled from 'styled-components'
import breakpoint, {map} from 'styled-components-breakpoint'
import Title from 'components/Title'

const getFontSize = (props) => map(props.theme.logisterSection.title.fontSize, (val) => `font-size: ${val};`)
const getLineHeight = (props) => map(props.theme.logisterSection.title.lineHeight, (val) => `line-height: ${val};`)
const getMargin = (props) => props.theme.registerPage.titleMargin.default
const getPortraitMargin = (props) => props.theme.registerPage.titleMargin.portrait

const StyledTitle = styled(Title)`
  ${getFontSize};
  ${getLineHeight};

  margin: ${getMargin};

  ${breakpoint('portrait')`
    margin: ${getPortraitMargin};
  `};
`

const getTextChangeBreakpoint = (props) => props.theme.logisterSection.title.textChangeBreakpoint

const LogisterPageTitleShort = styled(StyledTitle)`
  display: block;
  max-width: 460px;

  ${(props) => breakpoint(getTextChangeBreakpoint(props))`
    display: none;
  `};
`

const LogisterPageTitleLong = styled(StyledTitle)`
  display: none;

  ${(props) => breakpoint(getTextChangeBreakpoint(props))`
    display: block;
    max-width: 460px;
  `};
`

const LogisterPageTitle = ({t}) => {
  const titles = ['logister-header', 'logister-header-long']
  return (
    <Fragment>
      <LogisterPageTitleShort>{t(titles[0])}</LogisterPageTitleShort>
      <LogisterPageTitleLong>{t(titles[1])}</LogisterPageTitleLong>
    </Fragment>
  )
}

export default translate()(LogisterPageTitle)
