import React from 'react'

import {renderMessage} from 'utils/reactUtils'

import NotificationBarView from './NotificationBarView'
import NotificationBarInnerView from './NotificationBarInnerView'
import NotificationBarMobileView from './NotificationBarMobileView'
import NotificationBarMessage from './NotificationBarMessage'
import NotificationBarIcon from './NotificationBarIcon'
import NotificationBarCloseIcon from './NotificationBarCloseIcon'
import types from './notificationBarTypes'

class NotificationBar extends React.Component {
  render() {
    let {notification: {message = '', show = false, type = types.error} = {}, hideNotification, style, id} = this.props

    message = renderMessage(message, {onClose: hideNotification})

    return (
      <div style={{...style, zIndex: 1}}>
        <NotificationBarView type={type} isOpen={show}>
          <NotificationBarInnerView id={id}>
            <NotificationBarIcon type={type} />
            <NotificationBarMobileView>
              <NotificationBarMessage>{message}</NotificationBarMessage>
            </NotificationBarMobileView>
            <NotificationBarCloseIcon type={type} onClick={hideNotification} />
          </NotificationBarInnerView>
        </NotificationBarView>
      </div>
    )
  }
}

export {NotificationBar as PureNotificationBar}

export default NotificationBar
