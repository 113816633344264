import React from 'react'
import {translate} from 'react-i18next'
import {
  RingierBannerView,
  RingierBannerTitle,
  RingierBannerBenefit,
  RingierBannerBenefitIcon,
  RingierBannerBenefitMessage,
  RingierBannerLink,
} from './RingierBannerStyles'
import {ELEMENTS_IDS} from 'const'
import {getAboutLink} from 'utils/languageUtils'

const RingierBanner = ({loginCase, t}) => {
  const benefits = t(`ringier-banner.benefits-list`, {returnObjects: true})
  return (
    <RingierBannerView>
      <RingierBannerTitle>{t('ringier-banner.title-new')}</RingierBannerTitle>
      <div>
        {Array.isArray(benefits) &&
          benefits.map((line, i) => (
            <RingierBannerBenefit key={i}>
              <RingierBannerBenefitIcon />
              <RingierBannerBenefitMessage>{line}</RingierBannerBenefitMessage>
            </RingierBannerBenefit>
          ))}
      </div>
      <RingierBannerLink className={ELEMENTS_IDS.logisterPage.moreInfo} to={getAboutLink()} external secondary>
        {t('ringier-banner.more-info')}
      </RingierBannerLink>
    </RingierBannerView>
  )
}

export default translate()(RingierBanner)
