import keyBy from 'lodash/keyBy'
import qs from 'qs'
import {PROFILE_TABS} from 'const'

import config from 'config'
import {fetchRest} from 'utils/asyncUtils'
import {getLang, parseJWT} from 'utils/stringUtils'

const addressKeys = {}

class ProfileUpdateService {
  constructor() {
    this.lang = getLang()
  }

  getAuthHeader = (token) => `Bearer ${token}`

  prepareData = (originalTabs, newTab) => {
    originalTabs = Object.values(originalTabs)
    const tabs = [{...originalTabs.find((tab) => tab.tabName === newTab.tabName), ...newTab}]

    if (newTab.tabName === PROFILE_TABS.global) {
      tabs.push(...originalTabs.filter((tab) => tab.tabName && tab.tabName !== PROFILE_TABS.global))
    } else {
      tabs.push(originalTabs.find((tab) => tab.tabName === PROFILE_TABS.global))
    }

    return {tabs}
  }

  getUserProfile = async (token) => {
    const url = `${config.backendUrl}/user-profile`
    const response = await fetchRest(url, 'GET', undefined, {
      Authorization: this.getAuthHeader(token),
    })

    const tabs = keyBy(response.tabs, 'tabName')
    return {
      tabs,
      linkedIdentities: response.linkedIdentities,
    }
  }

  getUserProfileThirdPartiesData = async (token) => {
    const query = qs.stringify({lang: this.lang})
    const url = `${config.backendUrl}/user-profile-third-parties-data?${query}`
    const response = await fetchRest(url, 'GET', undefined, {
      Authorization: this.getAuthHeader(token),
    })

    return keyBy(response, 'tabName')
  }

  updateUserProfile = async (token, originalData, newData) => {
    const query = qs.stringify({lang: this.lang})
    const url = `${config.backendUrl}/user-profile?${query}`
    const data = this.prepareData(originalData, newData)
    await fetchRest(url, 'POST', data, {
      Authorization: this.getAuthHeader(token),
    })
  }

  getUserAddresses = async (token, auth0Id) => {
    return fetchRest(`${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/addresses`, 'GET', undefined, {
      Authorization: this.getAuthHeader(token),
    })
  }

  getUserAddress = async (token, auth0Id, addressId) => {
    return fetchRest(
      `${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/addresses/${addressId}`,
      'GET',
      undefined,
      {
        Authorization: this.getAuthHeader(token),
      }
    )
  }

  setAddressToBrand = async (token, auth0Id, addressId, brandName) => {
    const data = {
      addressId,
      addressKey: addressKeys[brandName],
    }
    return fetchRest(`${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/brandAddress`, 'PATCH', data, {
      Authorization: this.getAuthHeader(token),
    })
  }

  deleteUserAddress = async (token, auth0Id, addressId) => {
    return fetchRest(
      `${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/addresses/${addressId}`,
      'DELETE',
      undefined,
      {
        Authorization: this.getAuthHeader(token),
      }
    )
  }

  createUserAddress = async (token, auth0Id, address) => {
    return fetchRest(`${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/addresses/`, 'POST', address, {
      Authorization: this.getAuthHeader(token),
    })
  }

  updateUserAddress = async (token, auth0Id, id, address) => {
    return fetchRest(`${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/addresses/${id}`, 'PUT', address, {
      Authorization: this.getAuthHeader(token),
    })
  }

  setUserDefaultAddress = async (token, auth0Id, data) => {
    return fetchRest(`${config.backendUrl}/v2/users/${encodeURIComponent(auth0Id)}/defaultAddress`, 'PATCH', data, {
      Authorization: this.getAuthHeader(token),
    })
  }

  getUserCredentials = async (token) => {
    return fetchRest(`${process.env.REACT_APP_IDENTITY_SERVICE_URL}/webauthn/credentials`, 'GET', undefined, {
      Authorization: this.getAuthHeader(token),
    })
  }

  removeUserCredential = async (token, credentialId) => {
    return fetchRest(
      `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/webauthn/credentials/${credentialId}`,
      'DELETE',
      undefined,
      {
        Authorization: this.getAuthHeader(token),
      }
    )
  }

  deleteEmptyNicknameField = async (jwt, brand) => {
    const {sub} = parseJWT(jwt)

    return fetchRest(
      `${config.backendUrl}/users/${encodeURIComponent(sub)}/fields?keys=nickname`,
      'DELETE',
      {brand},
      {Authorization: `Bearer ${jwt}`}
    )
  }

  deleteEmptyField = async (jwt, field, brand = 'ringier connect') => {
    const {sub} = parseJWT(jwt)
    return fetchRest(
      `${config.backendUrl}/users/${encodeURIComponent(sub)}/fields?keys=${field}`,
      'DELETE',
      {brand},
      {
        Authorization: `Bearer ${jwt}`,
      }
    )
  }
}

export default ProfileUpdateService
