import styled from 'styled-components'
import FieldLabel from 'components/field/FieldLabel'

const getMarginBottom = (props) => {
  return props.theme.checkbox.group.label.marginBottom
}
const getPadding = (props) => props.theme.checkbox.group.label.padding
const getBackgroundColor = (props) => props.theme.checkbox.group.label.backgroundColor

const CheckBoxGroupLabel = styled(FieldLabel)`
  margin-bottom: ${getMarginBottom};
  padding: ${getPadding};
  background-color: ${getBackgroundColor};
`

export default CheckBoxGroupLabel
