import React, {Fragment} from 'react'
import styled from 'styled-components'

import CheckBox from 'components/checkbox'
import CheckBoxGroupLabel from 'components/checkbox/CheckBoxGroup/CheckBoxGroupLabel'
import Optional from 'components/Optional'

const MarketingCheckBox = styled(CheckBox)`
  background-color: ${(props) => props.theme.form.background};
  padding: ${(props) => props.theme.checkbox.padding};
  display: flex;
  flex-direction: column;
`

const RegisterFormMarketingCheckBox = ({label, t, ...props}) => {
  const labelStyles = {color: '#1A1A1A'}
  return (
    <Fragment>
      <CheckBoxGroupLabel display="block">
        {t('inputs.marketing')}
        {<Optional withMargin />}
      </CheckBoxGroupLabel>
      <MarketingCheckBox {...props} label={<span>{t('checkboxes.marketing')}</span>} labelStyles={labelStyles} />
    </Fragment>
  )
}

export default RegisterFormMarketingCheckBox
