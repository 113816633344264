import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

import config from 'config'

const NotificationBarIcon = styled.div`
  align-self: flex-start;
  min-width: ${(props) => props.theme.notification.icon.width};
  height: ${(props) => props.theme.notification.icon.height};
  background-size: 100% 100%;
  background-image: ${(props) => `url(${config.url}/assets/${props.theme.notification.icon.imageFunc(props.type)})`};

  ${breakpoint('mobile')`
    min-width: ${(props) => props.theme.notification.icon.mobile.width};
    height: ${(props) => props.theme.notification.icon.mobile.height};
  `};
`

export default NotificationBarIcon
