import React, {Fragment} from 'react'
import {withTheme} from 'styled-components'
import {translate} from 'react-i18next'
import config from 'config'
import {compose} from 'utils/reactUtils'
import {isMobileClient, getClient} from 'theme/brands/brandUtils'
import {ELEMENTS_IDS, BRANDS} from 'const'
import {isProfileApp} from 'utils/envUtils'
import {getBrandComponents} from 'theme/brands/componentsConfig'

import HeaderView from './HeaderView'
import HeaderInnerView from './HeaderInnerView'
import HeaderLogo from './HeaderLogo'
import HeaderRingierView from './HeaderRingierView'
import HeaderRingierLogo from './HeaderRingierLogo'
import HeaderUserView from './HeaderUserView'
import HeaderEmailView, {HeaderEmailViewLabel} from './HeaderEmailView'
import LanguageSwitcher from 'components/language-switcher'

const LANGUAGE_SWITCHER_OPTIONS_BY_BRAND = {
  default: [],
}

const getLanguageSwitcherOptions = () => {
  const brand = getClient(window)
  return LANGUAGE_SWITCHER_OPTIONS_BY_BRAND[brand] || LANGUAGE_SWITCHER_OPTIONS_BY_BRAND.default
}

const Header = ({t, theme, mobile, email, logout}) => {
  const BrandHeaderView = getBrandComponents(theme.brand.name).HeaderView || HeaderView
  const brand = getClient(window)
  const {href} = window.location

  // separate pages are pages that are not accessible through navigation, only through link in email, for example data download and user deletion pages
  const isProfile = isProfileApp()
  const isMfaPage = href.includes('mfa') && !isProfile && [BRANDS.ringier, BRANDS.ringierelearning].includes(brand)

  const isSeparatePage = href.includes('delete-user') || href.includes('download-data') || isMfaPage

  const withLanguageSwitcher = false

  const languageSwitcherOptions = getLanguageSwitcherOptions(isProfile)

  return (
    <BrandHeaderView isProfile={isProfile}>
      <HeaderInnerView email={email}>
        <HeaderLogo theme={theme} mobile={mobile} />
        {withLanguageSwitcher && (
          <LanguageSwitcher options={languageSwitcherOptions} isSeparatePage={isSeparatePage} isProfile={isProfile} />
        )}
        {!theme.shell.header.hideRingier && !email && (
          <HeaderRingierView withLanguageSwitcher={withLanguageSwitcher}>
            <HeaderRingierLogo src={`${config.url}/assets/${theme.shell.header.ringierLogo.img}`} />
          </HeaderRingierView>
        )}
        {email && (
          <HeaderUserView>
            <HeaderEmailView>
              <LanguageSwitcher
                options={languageSwitcherOptions}
                isSeparatePage={isSeparatePage}
                isProfile={isProfile}
              />
              <HeaderEmailViewLabel>
                {t('header.login-as')}
                <br />
              </HeaderEmailViewLabel>
              {email}{' '}
              {!isMobileClient(window) && (
                <Fragment>
                  |{' '}
                  <button id={ELEMENTS_IDS.profilePage.logout} onClick={logout}>
                    {t('header.logout')}
                  </button>
                </Fragment>
              )}
            </HeaderEmailView>
          </HeaderUserView>
        )}
      </HeaderInnerView>
    </BrandHeaderView>
  )
}

export {Header as PureHeader}

export default compose(translate(), withTheme)(Header)
