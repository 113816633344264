import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getDesktopDisplay = (props) => (props.hideLogosOnPortrait ? 'none' : 'block')

const FooterBrandsInnerView = styled.div`
  max-width: 1024px;
  margin: auto;

  ${breakpoint('portrait')`
    display: ${getDesktopDisplay}
  `};
`

export default FooterBrandsInnerView
