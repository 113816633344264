import styled from 'styled-components'
import Note from 'components/Note'

const SmallNote = styled(Note)`
  font-size: 16px;
  margin-top: ${(props) => props.theme.emailVerifyPage.smallNoteMarginTop || '0'};

  & a {
    font-size: 16px;
  }
`

export default SmallNote
