import qs from 'qs'
import {getHeliosUrlBaseUrlOverride, getInteractionUid, getLang, parseJWT, getParamFromQuery} from 'utils/stringUtils'
import {fetchRest} from 'utils/asyncUtils'
import {getClient} from 'theme/brands/brandUtils'

const TYPE_BY_VIEW = {
  TOTP: 'totp',
  SMS: 'sms',
  BACKUP: 'recoverycode',
}

const ACCOUNTS_ENDPOINT = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/accounts`

class MfaService {
  constructor(rawToken) {
    if (rawToken) {
      this.token = rawToken
      const {sub} = parseJWT(rawToken)
      this.sub = sub
    }
  }

  requestVerificationSms = () => {
    const lang = getLang()
    const brand = getClient()
    const requestSmsUrl = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/accounts/${encodeURIComponent(
      this.sub
    )}/mfa/sms/request`
    return fetchRest(
      requestSmsUrl,
      'POST',
      {
        lang,
        brand,
      },
      {
        Authorization: `Bearer ${this.token}`,
      }
    )
  }

  verifyCode = ({view, code}) => {
    const interactionUrl = this._getInteractionUrl()
    const lang = getLang()
    this.post(interactionUrl, {
      type: TYPE_BY_VIEW[view],
      payload: code,
      lang,
    })
  }

  getAvailableVerificationMethods = () => {
    const hasSmsMobileNumber = getParamFromQuery('hasSmsMobileNumber', window.location.search)
    const hasTotpSecret = getParamFromQuery('hasTotpSecret', window.location.search)
    return {
      TOTP: hasTotpSecret === 'true',
      SMS: hasSmsMobileNumber === 'true',
    }
  }

  getIncomingErrors = () => {
    const mfaVerificationInvalid = getParamFromQuery('mfaVerificationInvalid', window.location.search)
    return {
      mfaVerificationInvalid: mfaVerificationInvalid === 'true',
    }
  }

  getUserData = async () => {
    return fetchRest(`${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa`, 'GET', undefined, {
      Authorization: `Bearer ${this.token}`,
    })
  }

  deactivate = async (type) => {
    const lang = getLang()
    return fetchRest(
      `${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa/${type}?${qs.stringify({lang})}`,
      'DELETE',
      undefined,
      {
        Authorization: `Bearer ${this.token}`,
      }
    )
  }

  startTotpConfiguration = () => {
    const getTotpStartUrl = `${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa/totp/begin`
    return fetchRest(getTotpStartUrl, 'POST', {}, {Authorization: `Bearer ${this.token}`})
  }

  finishTotpConfiguration = ({code}) => {
    const lang = getLang()
    const getTotpFinishUrl = `${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa/totp/finish`
    return fetchRest(getTotpFinishUrl, 'POST', {code, lang}, {Authorization: `Bearer ${this.token}`})
  }

  startSmsConfiguration = ({mobileNumber}) => {
    const lang = getLang()
    const getSmsStartUrl = `${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa/sms/begin`
    return fetchRest(
      getSmsStartUrl,
      'POST',
      {
        mobileNumber,
        lang,
      },
      {Authorization: `Bearer ${this.token}`}
    )
  }

  finishSmsConfiguration = ({code}) => {
    const lang = getLang()
    const getSmsFinishUrl = `${ACCOUNTS_ENDPOINT}/${encodeURIComponent(this.sub)}/mfa/sms/finish`
    return fetchRest(getSmsFinishUrl, 'POST', {code, lang}, {Authorization: `Bearer ${this.token}`})
  }

  generateRecoveryCodes = () => {
    const url = `${process.env.REACT_APP_IDENTITY_SERVICE_URL}/accounts/${encodeURIComponent(
      this.sub
    )}/mfa/recoverycode/generate`
    return fetchRest(
      url,
      'POST',
      {},
      {
        Authorization: `Bearer ${this.token}`,
      }
    )
  }

  returnToProfile = () => {
    const interactionUrl = this._getInteractionUrl()
    const lang = getLang()
    this.post(interactionUrl, {lang})
  }

  cancelSetup = () => {
    const interactionUrl = this._getInteractionUrl(true)
    window.location.assign(interactionUrl)
  }

  _getInteractionUrl = (cancel) => {
    const baseUrl =
      getHeliosUrlBaseUrlOverride(window.location.search) || `https://${process.env.REACT_APP_AUTH0_DOMAIN}`
    const interactionId = getInteractionUid(window.location.search)
    return `${baseUrl}/interaction/${interactionId}${cancel ? '/cancel' : ''}`
  }

  post = (path, params) => {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = path

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input')
        hiddenField.type = 'hidden'
        hiddenField.name = key
        hiddenField.value = encodeURIComponent(params[key])

        form.appendChild(hiddenField)
      }
    }

    document.body.appendChild(form)
    form.submit()
  }
}

export default MfaService
