import {CLIENTS} from '../brandUtils'
import ButtonView from '../commonComponents/button/ButtonView'
import SmallNote from '../commonComponents/SmallNote'
import RegisterFormMarketingCheckBox from '../commonComponents/RegisterFormMarketingCheckBox'
import MainFormCheckBox from '../commonComponents/MainFormCheckBox'
import Bold from '../commonComponents/Bold'

export default {
  [CLIENTS.LIBERTATEA]: {
    ButtonView,
    RegisterFormMarketingCheckBox,
    SmallNote,
    Bold,
    MainFormCheckBox,
  },
}
