import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import get from 'lodash/get'
import Link from 'components/Link'

const ACTIVE = 'active'

const isProfileAndNotSeparatePage = ({isprofile, isseparatepage}) => isprofile === 'true' && isseparatepage !== 'true'

const LanguageSwitcherContainer = styled.div`
  width: auto;
  position: ${(props) => (props.isprofile === 'true' ? 'absolute' : 'relative')};
  right: ${(props) => props.isprofile === 'true' && '20px'};
  top: ${(props) => props.isprofile === 'true' && '21px'};
  margin-left: ${(props) => !(props.isprofile === 'true') && 'auto'};
  margin-bottom: ${(props) => (isProfileAndNotSeparatePage(props) ? '15px' : '0px')};
  ${breakpoint('portrait')`
    margin-left: auto;
    position: relative;
    right: auto;
    top: auto;
    display: block;
  `};
`

const LanguageSwitcherLink = styled(Link)`
  text-decoration: none;
  font-weight: ${(props) => (props.state === ACTIVE ? 'bold' : 'normal')};
  font-size: 14px;
  width: 17px;
  color: ${(props) =>
    isProfileAndNotSeparatePage(props) ? 'inherit' : get(props, 'theme.languageSwitcher.color', 'white')};
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
    color: ${(props) =>
      isProfileAndNotSeparatePage(props) ? 'inherit' : get(props, 'theme.languageSwitcher.color', 'white')};
  }
  ${breakpoint('portrait')`
    font-size: 16px;
`};
`

const LanguageSwitcherSeparator = styled.p`
  display: inline;
  margin: ${(props) => (props.staticMarkup ? '0 6px' : '0 8px')};
  color: ${(props) =>
    isProfileAndNotSeparatePage(props) ? 'inherit' : get(props, 'theme.languageSwitcher.separatorColor', 'white')};
  opacity: 0.6;
  font-size: 20px;
  font-weight: 100;
`

export {LanguageSwitcherSeparator, LanguageSwitcherContainer, LanguageSwitcherLink}
