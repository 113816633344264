import styled from 'styled-components'
import get from 'lodash/get'
import i18n from 'i18n-client'

const getChildren = (props) => props.children || i18n.t('optional')
const getFontFamily = (props) => get(props, 'theme.field.optional.family', 'inherit')
const getFontWeight = (props) => get(props, 'theme.field.optional.weight', 'inherit')
const getColor = (props) => get(props, 'theme.field.optional.color', 'inherit')

const Optional = styled.span.attrs({children: getChildren})`
  margin-left: ${(props) => (props.withMargin ? '8px' : '0px')}
  font-size: 14px;
  font-family: ${getFontFamily};
  font-weight: ${getFontWeight};
  color: ${getColor};
`

export default Optional
