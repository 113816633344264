import {fetchRest} from 'utils/asyncUtils'
import config from 'config'

class CaptchaService {
  getAuthHeader = (token) => `Bearer ${token}`

  shouldShowCaptcha = async () => {
    const response = await fetchRest(`${config.backendUrl}/v2/email/quota`, 'GET')
    return response.captchaRequired
  }
}

export default new CaptchaService()
