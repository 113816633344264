import qs from 'qs'
import config from 'config'
import {CLIENTS, CLIENT_MAP, PROJECTS} from 'const'
import {getWebview} from 'utils/stringUtils'
import ringier from './ringier'
import ringierDark from './ringier/index_dark'
import ringierelearning from './ringierelearning'
import sportal from './sportal'
import blikk from './blikk'
import egeszsegkalauz from './egeszsegkalauz'
import glamour from './glamour'
import kiskegyed from './kiskegyed'
import noizz from './noizz'
import ruzs from './ruzs'
import sportalhu from './sportalhu'
import lapot from './lapot'
import blic from './blic'
import superrecepti from './superrecepti'
import sportalsr from './sportalsr'
import zena from './zena'
import pulsonline from './pulsonline'
import ana from './ana'
import nekretnine from './nekretnine'
import blictv from './blictv'
import sportsk from './sportsk'
import abola from './abola'
import sportalgr from './sportalgr'
import gsp from './gsp'
import libertatea from './libertatea'

const getRingierTheme = () => {
  if (config.project === PROJECTS.RCBG) {
    return ringierDark
  }
  return ringier
}

const CLIENT_THEME = {
  [CLIENTS.RINGIER]: getRingierTheme(),
  [CLIENTS.RINGIERELEARNING]: ringierelearning,
  [CLIENTS.SPORTAL]: sportal,
  [CLIENTS.BLIKK]: blikk,
  [CLIENTS.EGESZSEGKALAUZ]: egeszsegkalauz,
  [CLIENTS.GLAMOUR]: glamour,
  [CLIENTS.KISKEGYED]: kiskegyed,
  [CLIENTS.NOIZZ]: noizz,
  [CLIENTS.RUZS]: ruzs,
  [CLIENTS.SPORTALHU]: sportalhu,
  [CLIENTS.LAPOT]: lapot,
  [CLIENTS.BLIC]: blic,
  [CLIENTS.SUPERRECEPTI]: superrecepti,
  [CLIENTS.SPORTALSR]: sportalsr,
  [CLIENTS.ZENA]: zena,
  [CLIENTS.PULSONLINE]: pulsonline,
  [CLIENTS.ANA]: ana,
  [CLIENTS.NEKRETNINE]: nekretnine,
  [CLIENTS.BLICTV]: blictv,
  [CLIENTS.SPORTSK]: sportsk,
  [CLIENTS.ABOLA]: abola,
  [CLIENTS.SPORTALGR]: sportalgr,
  [CLIENTS.GSP]: gsp,
  [CLIENTS.LIBERTATEA]: libertatea,
}

const getBaseBrand = () => {
  const baseBrandFromEnv = process.env.REACT_APP_BASE_BRAND
  if (baseBrandFromEnv) {
    return baseBrandFromEnv
  }

  return CLIENTS.RINGIER
}

export const getClient = (windowObject = window) => {
  if (!windowObject) {
    return getBaseBrand()
  }
  const params = qs.parse(windowObject.location.search.slice(1))
  return CLIENT_MAP[params.brand || params.client || params.client_id] || getBaseBrand()
}

export const isMobileClient = (windowObject = window) => {
  const webview = getWebview(windowObject.location.search)
  const hashWebview = getWebview(windowObject.location.hash.replace(/[/|#]/g, ''))

  return (webview && webview === 'true') || (hashWebview && hashWebview === 'true')
}

const getThemeOverride = (windowObject = window) => {
  if (!windowObject) {
    return null
  }
  const params = qs.parse(windowObject.location.search.slice(1))
  return CLIENT_THEME[params.brand_theme]
}

export const getTheme = (windowObject = window) => {
  return (
    getThemeOverride(windowObject) ||
    CLIENT_THEME[getClient(windowObject)] ||
    CLIENT_THEME[getBaseBrand()] ||
    CLIENT_THEME['ringier']
  )
}

export {CLIENTS, CLIENT_THEME}
