import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const getBackgroundColor = (props) => props.theme.shell.footer.backgroundColor
const getMarginTop = (props) => props.margin || '20px'
const getLandscapeTop = (props) => props.margin || '40px'

const FooterView = styled.footer`
  margin-top: ${getMarginTop};
  background-color: ${getBackgroundColor};

  ${breakpoint('mobile')`
    margin-top: ${getLandscapeTop};
  `};
`

export default FooterView
