import _ from 'lodash'
import styled, {css} from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Title = styled.div`
  font-family: ${(props) => props.theme.title.family};
  font-weight: ${(props) => props.theme.title.weight};
  font-size: ${(props) => props.theme.title.size};
  line-height: ${(props) => props.theme.title.height};
  margin: 0 0 20px;

  ${breakpoint('mobile')`
      font-size: ${(props) => props.theme.title.mobile.size};
      line-height: ${(props) => props.theme.title.mobile.height};
  `};

  ${breakpoint('portrait')`
      font-size: ${(props) => props.theme.title.portrait.size};
      line-height: ${(props) => props.theme.title.portrait.height};
  `};

  ${(props) =>
    _.get(props, 'theme.title.landscape.size') &&
    _.get(props, 'theme.title.landscape.height') &&
    css`
      ${breakpoint('landscape')`
        font-size: ${(props) => props.theme.title.landscape.size};
        line-height: ${(props) => props.theme.title.landscape.height};
      `};
    `}

  ${(props) =>
    _.get(props, 'theme.title.desktop.size') &&
    _.get(props, 'theme.title.desktop.height') &&
    css`
      ${breakpoint('desktop')`
        font-size: ${(props) => props.theme.title.desktop.size};
        line-height: ${(props) => props.theme.title.desktop.height};
      `};
    `}
`

export default Title
