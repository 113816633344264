import styled from 'styled-components'

const HeaderEmailView = styled.div`
  & > button {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    display: inline;
    margin: 0;
    padding: 0;
  }

  & > button:hover,
  & > button:focus {
    text-decoration: none;
  }

  ${(props) => props.theme.font.profileSmall}
`

export const HeaderEmailViewLabel = styled.span``

export default HeaderEmailView
